/** @jsxImportSource @emotion/react */
import React from 'react';
import { PSBoxStyle } from './PSBox.css';
import clsx from 'clsx';
import { SerializedStyles } from '@emotion/react';

type IProps = {
    children?: React.ReactNode;
    padding?: string | number;
    borderRadius?: string | number;
    isBoxShadow?: boolean;
    boxCss?: SerializedStyles;
};

const PSBox: React.FC<IProps> = (props) => {
    const { children, padding = 10, borderRadius = 30, isBoxShadow = false, boxCss } = props;

    return (
        <div css={[PSBoxStyle.self, boxCss]} style={{ padding, borderRadius }} className={clsx({ 'box-shadow': isBoxShadow })}>
            {children}
        </div>
    )
}

export default PSBox;