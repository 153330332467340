/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { PSBox, Text, PSError, PieChart } from '../../../ui-kit';
import { ComputedDatum } from '@nivo/pie';
import { LLMByUsageStyle } from './LLMByUsage.css';
import LLMByUsageTooltip from './LLMByUsageTooltip';
import LLMByUsageOthersModal from './LLMByUsageOthersModal';
import { graphql, graphqlEndpoint } from '../../../gql';
import { useQuery } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import { PercentageBy } from '../../../gql/generated/graphql';
import { useDateContext } from '../../../contexts';


const queryGenAiApplication = graphql(`
    query QueryGenAiApplication($input: queryGenAiApplicationWidgetPieInput!) {
        queryGenAiApplicationWidgetPie(input: $input) {
            blockType
            genAiApplication {
                description
                domain
                faviconUrl
                isCustom
                id
                logo
                name
                risk
                type
                vendor
            }
            percentage
            usersCount
            requestsCount
        }
    }
`)

export interface DataItem {
    id: string;
    label: string;
    color?: string;
    value: number;
    percentage?: number;
    isBlocked?: boolean;
    usersCount?: number;
    imageUrl?: string;
    url?: string;
    details?: DataItem[];
}


type IProps = {

};

const blockPattern = {
    id: "blocked-pattern",
    type: "patternLines",
    spacing: 10,
    rotation: -45,
    lineWidth: 9,
    background: "rgba(255,255, 255, 0.1)",
    color: "inherit"
}

const LLMByUsage: React.FC<IProps> = (props) => {
    const { } = props;

    const [othersData, setOthersData] = useState<DataItem[]>([]);
    const customColors = ['#8AC6FF', '#8CE5C3', '#FACD8C', '#BC8CFA', '#FF9A9A'];

    const openOthersModal = othersData.length > 0;

    const onPieChartSliceClick = (datum: ComputedDatum<DataItem>) => {
        if (datum.id === '_others_') {
            setOthersData(datum.data.details || []);
        }
    }

    const { date } = useDateContext();
    const { data, isLoading, isError } = useQuery({
        queryKey: ['llm-by-usage', date.dates.map(d => d.toISOString())],
        queryFn: async ({ signal }) => {
            const client = new GraphQLClient(graphqlEndpoint, { signal });
            const { queryGenAiApplicationWidgetPie } = await client.request(queryGenAiApplication, {
                input: {
                    percentageBy: PercentageBy.Requests,
                    time: {
                        from: date.dates[0].toISOString(),
                        to: date.dates[1].toISOString(),
                    }
                }
            });


            const data: DataItem[] = queryGenAiApplicationWidgetPie.map((item, index) => ({
                id: item.genAiApplication?.id || index.toString(),
                label: item.genAiApplication?.name || 'Unknown',
                value: item.requestsCount,
                percentage: item.percentage,
                isBlocked: item.blockType !== null,
                usersCount: item.usersCount,
                imageUrl: item.genAiApplication?.logo || item.genAiApplication?.faviconUrl || '',
                url: item.genAiApplication?.domain,
            }));

            return data;
        }
    })


    return (
        <>
            <PSBox padding={'30px 30px 35px 40px'}>
                <Text variant='monoSmall'>LLMS BY USAGE</Text>
                {isError && (
                    <div css={LLMByUsageStyle.errorContainer}>
                        <PSError />
                    </div>
                )}
                {!isError && (
                    <PieChart
                        containerCss={LLMByUsageStyle.pieChartContainer}
                        size={310}
                        data={data || []}
                        customColors={customColors}
                        isLoading={isLoading}
                        innerRadius={0.65}
                        padAngle={0.5}
                        activeOuterRadiusOffset={8}
                        colors={{ datum: 'data.color' }}
                        enableArcLinkLabels={false}
                        enableArcLabels={false}
                        tooltip={LLMByUsageTooltip}
                        onClick={(datum) => onPieChartSliceClick(datum)}
                        maxPieSlices={5}
                        sortByValue
                        defs={[blockPattern]}
                        arcLabelsComponent={({ datum }) => (
                            <Text variant='small' color='black-50'>HELLO</Text>
                        )}
                        fill={[{
                            match: datum => datum.data.isBlocked,
                            id: 'blocked-pattern',
                        }]}
                    />
                )}
            </PSBox>
            <LLMByUsageOthersModal open={openOthersModal} onClose={() => setOthersData([])} data={othersData} />
        </>
    )
}

export default LLMByUsage;