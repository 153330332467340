/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const RiskyUsersOrGroupsStyle = {
  stateContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 200px;
  `,
};
