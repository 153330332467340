/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { DataItem } from './LLMByUsage';
import { Dialog } from '@mui/material';
import { LLMDetails } from './LLMByUsageTooltip';
import { LLMByUsageOthersModalStyle } from './LLMByUsageOthersModal.css';
import { PSTab, PSTabs } from '../../../ui-kit';


type IProps = {
    open: boolean;
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined;
    data: Array<Omit<DataItem, 'details'>>;
};

const LLMByUsageOthersModal: React.FC<IProps> = (props) => {
    const { open, onClose, data } = props;

    const [value, setValue] = React.useState<'active' | 'blocked'>('active');

    const handleChange = (event: React.SyntheticEvent, newValue: 'active' | 'blocked') => {
        setValue(newValue);
    }

    const activeDataItems = data.filter(item => !item.isBlocked);
    const blockedDataItems = data.filter(item => item.isBlocked);

    useEffect(() => {
        if (activeDataItems.length > 0)
            setValue('active');
        else if (blockedDataItems.length > 0)
            setValue('blocked');
    }, [data])

    const renderDataItems = (dataItems: Array<DataItem>) => (
        <div css={LLMByUsageOthersModalStyle.dataItemsContainer}>
            {dataItems.map(item => (
                <div key={item.id}>
                    <LLMDetails {...item} />
                </div>
            ))}
        </div>
    );

    return (
        <Dialog fullWidth open={open} onClose={onClose}
            PaperProps={{
                style: {
                    borderRadius: 6,
                }
            }}>
            <PSTabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
            >
                <PSTab value='active' disabled={activeDataItems.length === 0} number={activeDataItems.length} label={`Active`} />
                <PSTab value='blocked' disabled={blockedDataItems.length === 0} number={blockedDataItems.length} label={`Blocked`} />
            </PSTabs>

            {value === 'active' && renderDataItems(activeDataItems)}
            {value === 'blocked' && renderDataItems(blockedDataItems)}
        </Dialog>
    )
}

export default LLMByUsageOthersModal;