/** @jsxImportSource @emotion/react */
import React from 'react';
import Icon, { IconProps } from '../Icon/Icon';
import clsx from 'clsx';
import { PSIconButtonStyle } from './PSIconButton.css';
import { CircularProgress } from '@mui/material';
import Tooltip from '../Tooltip/Tooltip';

export type TPSIconButtonVariant = 'square' | 'circle';
export type TPSIconButtonVariantType = 'primary' | 'secondary';

type IProps = {
    active?: boolean;
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    className?: string;
    isLoading?: boolean;
    tooltipLabel?: string;
    variant: TPSIconButtonVariant;
    variantType: TPSIconButtonVariantType;
} & Omit<IconProps, 'color'>;

const PSIconButton: React.FC<IProps> = (props) => {
    const { active, disabled, className, onClick, isLoading, variant, variantType, ...IconProps } = props;

    return (
        <Tooltip disableInteractive title={isLoading || disabled ? '' : props.tooltipLabel} placement="top">
            <button
                css={PSIconButtonStyle(variant, variantType).self}
                onClick={disabled ? undefined : onClick}
                className={clsx({
                    'ps-icon-button': true,
                    'active': active,
                    'disabled': disabled,
                    'loading': isLoading,
                }, className ? className : '')}
            >
                {isLoading ?
                    <CircularProgress style={{ color: 'var(--color-black-70)' }} size={IconProps.iconSize || 15} />
                    : <Icon iconSize={IconProps.iconSize || 15} {...IconProps} className='ps-icon-button-icon' />
                }
            </button>
        </Tooltip>
    )
}

export default PSIconButton;