/** @jsxImportSource @emotion/react */
import { diff_match_patch } from 'diff-match-patch';
import React, { useMemo } from 'react';
import Text from '../Text/Text';
import { PSTextDiffStyle } from './PSTextDiff.css';

export type TTextDiffMode = 'both' | 'modified' | 'original';
type IProps = {
    text: string;
    modifiedText: string;
    textMode?: TTextDiffMode;
    includeColor?: boolean;
};

const diff = new diff_match_patch();
diff.Diff_EditCost = 10;

const PSTextDiff: React.FC<IProps> = (props) => {
    const { text, modifiedText, textMode = 'both', includeColor = true } = props;

    const textDiff = useMemo(() => {
        if (!text) return [];
        if (!modifiedText) return [];

        const different = diff.diff_main(text, modifiedText);
        diff.diff_cleanupEfficiency(different);
        return different;

    }, [text, modifiedText])

    return (
        <Text pre>
            {textDiff.map((part, index) => {
                const [value, text] = part;

                const key = `${index}-${text}`
                switch (value) {
                    case 0:
                        return <span key={key} css={PSTextDiffStyle.regularText}>{text}</span>
                    case 1:
                        if (textMode === 'original') break;
                        return <span key={key} css={includeColor ? PSTextDiffStyle.addedText : PSTextDiffStyle.regularText}>{text}</span>
                    case -1:
                        if (textMode === 'modified') break;
                        return <span key={key} css={includeColor ? PSTextDiffStyle.removedText(textMode === 'both') : PSTextDiffStyle.regularText}>{text}</span>
                }
            })}
        </Text>
    )
}

export default PSTextDiff;