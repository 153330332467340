import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isNumber } from '../utils';

export const useStateWithParams = <T extends string | number | boolean | Date | Array<string | number | Date> | null | undefined>(paramsKey: string, initialValue: T) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const deserializeValue = () => {
        const paramValue = searchParams.get(paramsKey);

        //if T is number
        if (typeof initialValue === 'number') {
            return (parseInt(paramValue || initialValue.toString()) as unknown) as T;
        }

        // if T is string
        if (typeof initialValue === 'string') {
            return (paramValue || initialValue) as T;
        }

        // if T is boolean
        if (typeof initialValue === 'boolean') {
            return (paramValue === 'true' || initialValue) as T;
        }

        // if T is Date
        if (initialValue instanceof Date) {
            return (paramValue ? new Date(paramValue) : initialValue) as T;
        }

        //if T is Array
        if (Array.isArray(initialValue)) {
            const values = paramValue?.split(',').map((value) => {
                if (isNumber(value)) {
                    return parseInt(value || initialValue.toString());
                }
                if (Date.parse(value)) {
                    return new Date(value || initialValue.toString());
                }
                return value;
            })

            return (values || initialValue) as T;
        }

        return initialValue;
    }

    const [value, setValue] = useState<T>(deserializeValue);

    useEffect(() => {
        const serializeValue = (value: T): string | null => {
            if (!value) return null;
            if (Array.isArray(value) && value.length === 0) return null;

            if (Array.isArray(value)) {
                const valuesArray = value.map(x => {
                    if (x instanceof Date) {
                        return x.toISOString();
                    }
                    return x.toString();
                })

                return valuesArray.join(',');
            }

            if (value instanceof Date) {
                return value.toISOString();
            }

            if (typeof value === 'number' || typeof value === 'string' || typeof value === 'boolean') {
                return value.toString();
            }

            return null;
        };

        const currentParams = new URLSearchParams(window.location.search);
        const serializedValue = serializeValue(value);

        if (serializedValue !== null) {
            currentParams.set(paramsKey, serializedValue);
        } else {
            currentParams.delete(paramsKey);
        }

        setSearchParams(currentParams, { replace: true });


    }, [value, paramsKey, setSearchParams, setValue]);

    return [value, setValue] as [T, React.Dispatch<React.SetStateAction<T>>];
};