/** @jsxImportSource @emotion/react */
import { Paper, PaperProps } from '@mui/material';
import styled from '@emotion/styled';

const PSPaper = styled(({ ...props }: PaperProps) => (
    <Paper {...props} />
))`
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    overflow: clip;
`;

export default PSPaper;