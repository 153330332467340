import { Grid, Tooltip, FormLabel, TextField, Box } from "@mui/material";

type TextInputProps = {
    value: string | undefined | null;
    title: string;
    help: string | null;
    multiline: boolean;
    disabled: boolean;
    maxLength: number | null;
    handleOnChange: (value: string) => void;
}
  
export const TextInput = ({ value, title, help, multiline, disabled, maxLength, handleOnChange }: TextInputProps) => {
    if (value === undefined || value === null) {
        value = '';
    }
    const maxLengthProp = maxLength !== null ? maxLength : -1;
    return (
        <Grid container alignItems="center" sx={{ my: -1, width: '100%' }}>
            <Grid item xs={3}>
                <Tooltip key={title} title={help} placement='top-start'>
                    <FormLabel component="legend"  sx={{ paddingTop: 1 }}>{<span style={{ color: 'black' }}>{title}</span>}</FormLabel>
                </Tooltip>
            </Grid>
            <Grid item xs={7} sx={{ mx: 13, width: '100%', marginRight: 0 }}>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '97%' },
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={(event) => event.preventDefault()} 
                    >
                    <TextField
                        id={title}
                        variant="standard"
                        value={value}
                        multiline={multiline}
                        onChange={(event) => handleOnChange(event.target.value)}
                        disabled={disabled}
                        inputProps={{ maxLength: maxLengthProp }}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}