/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const ActivityMonitorTextStyle = {
    self: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
`,
    textKey: css`
    margin-bottom: 10px;
`,
}