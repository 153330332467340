/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import SentimentForm from './SentimentForm';

const SentimentProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode } = props;

    return (
        <ProtectionWrapper
            control={control}
            label="Sentiment"
            getValues={getValues}
            description="Prevent negative sentiment prompts or responses"
            formName="Sentiment"
            Component={isAdvancedMode ? SentimentForm : undefined}
            isAdvancedMode={isAdvancedMode}
            summary={`The Sentiment Protection uses AI models to prevent negative sentiments from appearing in your prompts or responses.

This is beneficial both for preventing users from sending negative prompts to your AI application, and for blocking your AI application from potentially responding with unwanted negative feedback.`}
        />
    )
}

export default SentimentProtection;