/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const GenAiMgmtRulesSettingsStyle = {
  self: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
  label: css`
    margin-bottom: 5px;
  `,
};
