import { useState, useEffect } from 'react';
import { breakpoints } from '../styles';

const useBreakpoints = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isSm = screenWidth >= breakpoints.sm && screenWidth < breakpoints.md;
    const isMd = screenWidth >= breakpoints.md && screenWidth < breakpoints.lg;
    const isLg = screenWidth >= breakpoints.lg && screenWidth < breakpoints.xl;
    const isXl = screenWidth >= breakpoints.xl;

    return { screenWidth, isSm, isMd, isLg, isXl, breakpoints };
};

export default useBreakpoints;
