import { Box } from '@mui/material';
import { IntegrationViewer } from './IntegrationViewer';

export const ManageIntegration = () => {
    return (
        <Box sx={{ width: '75%', display: 'flex', flexDirection: 'column', rowGap: '5vh' }}>
            <IntegrationViewer />
        </Box>
    )

}