/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Menu, MenuProps } from "@mui/material";

export const PSMenu = styled((props: MenuProps) => (
    <Menu {...props} />
))`

    & .MuiMenu-paper {
        background-color: var(--color-white);
        border-radius: 10px;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
        margin: 0;
        padding: 0;
    }

    & .MuiList-root {
        padding: 0;
    }
`

export default PSMenu;