import { useState, useEffect } from 'react';
import KibanaWrapper from '../KibannaWrapper/KibanaWrapper';
import { useLocation } from 'react-router-dom';
import { PSLoadingScreen } from '../ui-kit';

type DashboardProps = {
  dashboardType: string;
}

export const Dashboard = ({ dashboardType }: DashboardProps) => {
  const [dashboardId, setDashboardId] = useState<string>();
  const location = useLocation();

  useEffect(() => {
    // Function to fetch the dashboard ID from the API
    const fetchDashboardId = () => {
      fetch(`/api/be/get-dashboard-id?dashboardType=${dashboardType}`)
        .then((response) => {
          // Only proceed if http response code is in 200-299 range
          if (!response.ok) {
            throw new Error(`Failure response from the get-dashboard-id API: got response code ${response.status}`);
          }
          return response.text();
        })
        .then((data) => {
          setDashboardId(data);
          localStorage.setItem(`dashboardId_${dashboardType}`, data); // Cache the dashboard ID
        })
        .catch((error) => {
          console.error('kibanaWrapper: Error fetching data:', error);
        });
    };

    // Check if the dashboard ID is cached
    const cachedDashboardId = localStorage.getItem(`dashboardId_${dashboardType}`);
    if (cachedDashboardId) {
      setDashboardId(cachedDashboardId);
    } else {
      fetchDashboardId(); // Fetch the dashboard ID if not cached
    }
  }, [dashboardId, dashboardType]);

  if (!dashboardId) {
    return <PSLoadingScreen />;
  }

  let srcUrl: string = `/kibana/app/dashboards#/view/${dashboardId}?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-7d%2Cto%3Anow))&show-query-input=true&show-time-filter=true`;

  if (dashboardType === 'ps-alerts') {
    const searchParams = new URLSearchParams(location.search);

    const promptResponseId = searchParams.get("promptResponseId");
    const textType = searchParams.get("textType");

    if (promptResponseId !== null && textType !== null) {
      const promptResponseIdFilter = `(meta:(alias:!n,disabled:!f,key:log.prompt_response_id,negate:!f,params:(query:'${promptResponseId}'),type:phrase),query:(match:(log.prompt_response_id:(query:'${promptResponseId}',type:phrase))))`;
      const textTypeFilter = `(meta:(alias:!n,disabled:!f,key:log.text_type,negate:!f,params:(query:'${textType}'),type:phrase),query:(match:(log.text_type:(query:'${textType}',type:phrase))))`;
      srcUrl = `/kibana/app/dashboards#/view/${dashboardId}?embed=true&_g=(filters:!(${promptResponseIdFilter},${textTypeFilter}),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))&show-query-input=true&show-time-filter=true`;
    }
  }

  return (
    <KibanaWrapper
      src={srcUrl}
      title="Prompt Security Dashboard"
      style={{ flex: '1 1 auto' }}
      frameBorder="0"
      dashboardType={dashboardType}
      allowFullScreen
    />
  )
}
