import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, TextField } from "@mui/material"

type PopupMessageInputProps = {
    open: boolean;
    title: string;
    text: string;
    value: string;
    handleTextInputOnChange: (value: string) => void;
    handlePopupMessageClose: () => void;
    handlePopupMessageSubmit: () => void;
}

export const PopupMessageInput = ({ open, title, text, value, handleTextInputOnChange, handlePopupMessageClose, handlePopupMessageSubmit }: PopupMessageInputProps) => {
    return (
        <Dialog maxWidth="sm" fullWidth={true}
            open={open}
            onClose={handlePopupMessageClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {text}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    fullWidth
                    variant="standard"
                    value={value}
                    onChange={(event) => handleTextInputOnChange(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handlePopupMessageClose}>Cancel</Button>
                <Button onClick={handlePopupMessageSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>
    )
}