import { Grid, FormLabel, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";

type StatusRadioProps = {
    value: boolean;
    handleOnChange: (value: boolean) => void;
    text: string;
  };
  
export const StatusRadio = ({ value, handleOnChange, text }: StatusRadioProps) => {
    return (
        <Grid container alignItems="center">
            <Grid item xs={4} sx={{ mx: 3 }}>
                <FormLabel component="legend">{<span style={{ color: 'black' }}>{text}</span>}</FormLabel>
            </Grid>
            <Grid item xs sx={{ mx: 3 }}>
                <FormControl>
                    <RadioGroup
                    row
                    value={value}
                    >
                        <FormControlLabel
                            key='enable'
                            value={true}
                            control={<Radio size='small' />}
                            label={<span>Enable</span>}
                            onChange={() => handleOnChange(true)}
                        />
                        <FormControlLabel
                            key='disable'
                            value={false}
                            control={<Radio size='small' />}
                            label={<span>Disable</span>}
                            onChange={() => handleOnChange(false)}
                    />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    )
}