import { Grid, Tooltip, FormLabel, Box } from "@mui/material";
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
import styled from 'styled-components';

// Hide buttons (they are unstyled in anyway)
const StyledNumberInput = styled(NumberInput)`
  & .base-NumberInput-incrementButton,
  & .base-NumberInput-decrementButton {
    display: none;
  }
`;

type IntInputProps = {
    value: number | undefined | null;
    title: string;
    help: string | null;
    disabled: boolean;
    handleOnChange: (value: number) => void;
}
  
export const IntInput = ({ value, title, help, disabled, handleOnChange }: IntInputProps) => {
    if (value === undefined || value === null) {
        value = 0;
    }
    return (
        <Grid container alignItems="center">
            <Grid item xs={4} sx={{ mx: 3, my: 2 }}>
                <Tooltip key={title} title={help} placement='top-start'>
                    <FormLabel component="legend">{<span style={{ color: 'black' }}>{title}</span>}</FormLabel>
                </Tooltip>
            </Grid>
            <Grid item xs sx={{ mx: 3 }}>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { width: '97%' },
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={(event) => event.preventDefault()} 
                    >
                    <StyledNumberInput
                        id={title}
                        value={value}
                        onChange={(event, value) => handleOnChange(/^\d+$/.test(String(value)) ? Number(value) : 0)}
                        disabled={disabled}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}