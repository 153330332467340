import { IconButton, MenuItem, Select, SelectChangeEvent, TextField, Tooltip, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { useQuery } from '@tanstack/react-query';
import { request } from 'graphql-request';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { ApplicationTypes, InspectionAction, LogAction, Policy, PolicyTypes, Rule, RuleActions, RuleContentKeys } from '../../../gql/generated/graphql';
import { graphql, graphqlEndpoint } from '../../../gql';
import { SingleRuleContentRegular } from '../SingleRuleContent/singleRuleContentRegular';
import { round } from 'lodash-es';
import { RoundedCorner } from '@mui/icons-material';

type SingleRuleProps = {
    rule: Rule;
    updateRule: (updatedRule: Rule) => void;
    deleteRule?: () => void;
    isDefault?: boolean;
    applicationType: ApplicationTypes;
}

const queryPolices = graphql(`
    query PoliciesType($policyTypes: [String!]!) {
        policies(policyTypes: $policyTypes) {
            id
            name
            policyType
        }
    }
`);

export const SingleRuleRegular = ({ rule, updateRule, deleteRule, isDefault, applicationType }: SingleRuleProps) => {
    const { inspectionAction, logAction } = rule.subActions;
    const policyId = rule.policy?.id || '';
    const action = rule.action;

    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        const updatedRule: Rule = { ...rule, description: event.target.value }; 
        updateRule(updatedRule);
    };

    const onActionChange = (event: SelectChangeEvent) => {
        const updatedRule: Rule = {
            ...rule,
            action: event.target.value as RuleActions
        };
        updateRule(updatedRule);
    };

    const onInspectionActionChange = (event: SelectChangeEvent) => {
        const updatedRule: Rule = {
            ...rule,
            subActions: {
                ...rule.subActions,
                inspectionAction: event.target.value
            }
        };
        updateRule(updatedRule);
    };

    const onPolicyChange = (event: SelectChangeEvent) => {
        const rulePolicy = rule.policy || { id: '', name: '' } as Policy;

        const updatedRule: Rule = {
            ...rule,
            policy: {
                ...rulePolicy,
                id: event.target.value
            }
        };
        updateRule(updatedRule);
    };

    const onLogActionChange = (event: SelectChangeEvent) => {
        const updatedRule: Rule = {
            ...rule,
            subActions: {
                ...rule.subActions,
                logAction: event.target.value
            }
        };
        updateRule(updatedRule);
    };

    const onContentChange = (content: any) => {
        const updatedRule: Rule = {
            ...rule, content
        };
        updateRule(updatedRule);
    };

    const InspectionActionToDisplay = (inspectionAction: InspectionAction) => {
        switch (inspectionAction) {
            case InspectionAction.Detect:
                return 'Yes';
            case InspectionAction.Prevent:
                return 'No';
        }
    }

    const { data: policies } = useQuery(
        {
            queryKey: ["policies", PolicyTypes, applicationType],
            queryFn: async () => {
                const { policies } = await request(graphqlEndpoint, queryPolices, { policyTypes: applicationType === ApplicationTypes.Regular ? [PolicyTypes.Regular] : [PolicyTypes.CodeAssistant]} );
                return policies;
            },
            select: data => Object.fromEntries(data.map(x => [x.name, x.id]))
        }
    );

    function addSpacesToCamelCase(str: string) {
        return str.replace(/(?<!^)([A-Z])/g, ' $1');
    }

    if (policies === undefined) {
        return <></>
    }

    return (
        <div style={{ display: 'flex', paddingLeft: '10px', paddingRight: '20px', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', paddingLeft: '10px', paddingRight: '20px', width: '100%' }}>
                <div style={{ display: 'flex', gap: '30px', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex', flexGrow: 5, width: 'min(100%, 300px)', alignItems: 'center', gap: '15px' }}>
                        <Typography>Description</Typography>
                        <TextField
                            value={rule.description}
                            onChange={onDescriptionChange}
                            size='small'
                            fullWidth
                        />
                    </div>
                    <div style={{ display: 'flex', flexGrow: 1, width: 'min(100%, 300px)', alignItems: 'center', gap: '15px' }}>
                        <Typography>Action</Typography>
                        <Select
                            value={action ? action : ''}
                            onChange={onActionChange}
                            size='small'
                            fullWidth
                        >
                            {Object.values(RuleActions).filter(value => value === RuleActions.Inspect).map((value, i) =>
                                <MenuItem key={i} value={value}>{addSpacesToCamelCase(value)}</MenuItem>
                            )}
                        </Select>
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '30px', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex', flexGrow: 1, width: 'min(100%, 200px)', alignItems: 'center', gap: '15px' }}>
                        <Typography style={{ width: 'min(100%, 150px)' }}>Monitor Only</Typography>
                        <Select
                            value={inspectionAction}
                            onChange={onInspectionActionChange}
                            size='small'
                            fullWidth
                            >
                                {Object.values(InspectionAction).sort((a, b) => a.localeCompare(b)).map((value, i) =>
                                    <MenuItem key={i} value={value}>{InspectionActionToDisplay(value)}</MenuItem>
                                )}
                        </Select>
                    </div>
                    <div style={{ display: 'flex', flexGrow: 1, width: 'min(100%, 200px)', alignItems: 'center', gap: '15px' }}>
                        <Tooltip title="Determine the type of logs that are being sent to the SIEM integrations">
                            <Typography>Log</Typography>
                        </Tooltip>
                        <Select
                            value={logAction}
                            onChange={onLogActionChange}
                            size='small'
                            fullWidth
                        >
                            {Object.values(LogAction).sort((a, b) => a.localeCompare(b)).filter(value => addSpacesToCamelCase(value) !== 'None').map((value, i) =>
                                <MenuItem key={i} value={value}>{addSpacesToCamelCase(value)}</MenuItem>
                            )}
                        </Select>
                    </div>
                    <div style={{ display: 'flex', flexGrow: 4, width: 'min(100%, 200px)', alignItems: 'center', gap: '15px' }}>
                        <Typography style={{ width: 'min(100%, 100px)' }}>Use Policy</Typography>
                        <Select
                            value={policyId}
                            onChange={onPolicyChange}
                            size='small'
                            fullWidth
                        >
                            {Object.entries(policies).map(([name, id]) =>
                                <MenuItem key={id} value={id}>{name}</MenuItem>
                            )}
                        </Select>
                    </div>
                </div>
                {isDefault ? null : <div>
                    <div style={{ display: 'flex', flexGrow: 1, minWidth: '300px', alignItems: 'center', gap: '20px' }}>
                        <>
                            <Typography>Conditions</Typography>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px', width: '100%' }}>
                                <SingleRuleContentRegular content={rule.content} onChange={onContentChange} validContentKeys={[RuleContentKeys.UserEmail, RuleContentKeys.UserGroups]} />
                            </div>
                        </>
                    </div>
                </div>}
            </div>
            {
                isDefault ? null :
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                    <IconButton aria-label="delete" onClick={deleteRule} style={{ borderRadius: '0' }}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            }
        </div>
    )
}