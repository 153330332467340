/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionFormProps, TSentimentProtection } from '../Common';
import { SentimentFormStyle } from './SentimentForm.css';
import { Controller } from 'react-hook-form';
import { Slider } from '@mui/material';
import { Text } from '../../../../ui-kit';

const SentimentForm: React.FC<IProtectionFormProps<TSentimentProtection>> = (props) => {
    const { control, formName, protection, isAdvancedMode } = props;

    if (!isAdvancedMode) return null;

    return (
        <div css={SentimentFormStyle.self}>
            <Controller
                name={`${formName}.threshold`}
                defaultValue={protection.threshold}
                control={control}
                rules={{
                    min: -1,
                    max: 1,
                }}
                render={({ field }) => (
                    <div>
                        <Text>Threshold</Text>
                        <Slider {...field} min={-1} max={1} step={0.1} valueLabelDisplay="auto"
                            marks={[
                                { value: -1, label: '-1' },
                                { value: -0.5, label: '-0.5' },
                                { value: 0, label: '0' },
                                { value: 0.5, label: '0.5' },
                                { value: 1, label: '1' },
                            ]}
                        />
                    </div>
                )}
            />
        </div>
    )
}

export default SentimentForm;