/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { ToggleButtonGroup } from "@mui/material";


export const PSToggleButtonGroup = styled((ToggleButtonGroup))`
    &.MuiToggleButtonGroup-root {
        & .MuiButtonBase-root.Mui-selected {
            background: var(--color-black);
            color: var(--color-white);
            border-color: var(--color-black);
            &:hover {
                background: var(--color-black);
            }
        }
    }
`;

export default PSToggleButtonGroup;