/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const TopicsDetectorFormStyle = {
    self: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
    `,
    tableAlignRight: css`
        margin-left: auto;
        margin-right: 0;
    `

}