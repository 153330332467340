import { DateTime } from 'luxon';
import React, { createContext, useCallback, useEffect } from 'react';
import { useLocalStorage, useSearchParam } from 'react-use';
import { predefinedToDates } from '../ui-kit';

export type TDateSelection = TAbsoluteDateSelection | TRelativeDateSelection;

export const DateTypeSelection = {
    absolute: "absolute",
    relative: "relative",
} as const;

export type TDateTypeSelection<T extends keyof typeof DateTypeSelection> =
    (typeof DateTypeSelection)[T];

export type TAbsoluteDateSelection = {
    type: TDateTypeSelection<"absolute">;
    period: 'custom';
    dates: [Date, Date];
};

export type TRelativeDateSelection = {
    type: TDateTypeSelection<"relative">;
    period: TPredefinedDateOrTimeSelection;
    dates: [Date, Date];
};

export const predefinedDateSelections = ['today', 'yesterday', 'last7days', 'last-month', 'last-year'] as const;
export const predefinedTimeSelections = ['last5minutes', 'last15minutes', 'last30minutes', 'last1hour', 'last3hours', 'last6hours', 'last12hours'] as const;
export const predefinedDateOrTimeSelections = [...predefinedDateSelections, ...predefinedTimeSelections] as const;

export type TPredefinedDateSelection = typeof predefinedDateSelections[number];
export type TPredefinedTimeSelection = typeof predefinedTimeSelections[number];
export type TPredefinedDateOrTimeSelection = TPredefinedDateSelection | TPredefinedTimeSelection


export type DateContextType = {
    date: TDateSelection;
    setDate: React.Dispatch<React.SetStateAction<TDateSelection>>;
}

export const DateContext = createContext<DateContextType | null>(null);

type IProps = {
    children: React.ReactNode;
};

export const defaultDates: [Date, Date] = [DateTime.utc().minus({ days: 7 }).toJSDate(), new Date()];

export const DateContextProvider: React.FC<IProps> = (props) => {
    const { children } = props;

    const searchParamsDateType = useSearchParam('dateType') as keyof typeof DateTypeSelection;
    const searchParamsDates = useSearchParam('dates');
    const searchParamsPeriod = useSearchParam('period') as TPredefinedDateOrTimeSelection;

    const getDateSelection = useCallback((dateType: keyof typeof DateTypeSelection): TDateSelection => {
        if (dateType === 'absolute') {
            return {
                type: 'absolute',
                period: 'custom',
                dates: searchParamsDates?.split(',').map(x => new Date(x)) as [Date, Date] || defaultDates
            }
        }

        else if (dateType === 'relative') {
            return {
                type: 'relative',
                period: predefinedDateOrTimeSelections.includes(searchParamsPeriod) ? searchParamsPeriod : 'last7days',
                dates: predefinedToDates(searchParamsPeriod)
            }
        }

        return {
            type: 'relative',
            period: 'last7days',
            dates: predefinedToDates(searchParamsPeriod)
        }
    }, [searchParamsDates, searchParamsPeriod]);

    const [storedDateType, setStoredDateType] = useLocalStorage<TDateSelection>('dateStore', getDateSelection(searchParamsDateType), {
        raw: false,
        serializer: (value) => {
            if (value.type === 'absolute') {
                return JSON.stringify({
                    type: 'absolute',
                    period: 'custom',
                    dates: [value.dates[0].toISOString(), value.dates[1].toISOString()]
                })
            }

            return JSON.stringify({
                type: 'relative',
                period: value.period,
                dates: predefinedToDates(value.period).map(x => x.toISOString())
            })
        },
        deserializer: (value) => {
            const parsed = JSON.parse(value);
            if (parsed.type === 'absolute') {
                const from = DateTime.fromISO(parsed.dates[0]);
                const to = DateTime.fromISO(parsed.dates[1]);

                return {
                    type: 'absolute',
                    period: 'custom',
                    dates: [from.toJSDate(), to.toJSDate()]
                }
            }

            return {
                type: 'relative',
                period: parsed.period,
                dates: predefinedToDates(parsed.period)
            }
        }
    });

    useEffect(() => {
        if (searchParamsDateType) {
            setStoredDateType(getDateSelection(searchParamsDateType));
        }
    }, [searchParamsDateType, getDateSelection, setStoredDateType])

    return (
        <DateContext.Provider value={{ date: storedDateType!, setDate: setStoredDateType as React.Dispatch<React.SetStateAction<TDateSelection>> }}>
            {children}
        </DateContext.Provider>
    )
}

export const useDateContext = () => {
    const context = React.useContext(DateContext);
    if (!context) {
        throw new Error('useDateContext must be used within a DateContextProvider');
    }
    return context;
}