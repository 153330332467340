/** @jsxImportSource @emotion/react */
import React from 'react';
import { Link, Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { ConnectorContextProvider } from '../../../contexts';
import { PSTab, PSTabs } from '../../../ui-kit';

type IProps = {};

const TABS = {
    'policies': 'Policies',
    'deployment': 'Deployment'
};


const HomegrownApplications: React.FC<IProps> = (props) => {
    const { } = props;
    const { pathname } = useLocation();

    const { connectorName } = useParams();
    const [searchParams] = useSearchParams();
    const policyIdSearchParams = searchParams.get('policyId');

    if (connectorName && connectorName === ":connectorName") return null;

    const tabValue = pathname.split('/').pop();

    return (
        <ConnectorContextProvider>
            {!policyIdSearchParams && <PSTabs
                value={tabValue}
                indicatorColor="secondary"
                variant='scrollable'
                scrollButtons="auto"
            >
                {Object.entries(TABS).map(([key, value]) => (
                    <PSTab
                        key={key}
                        value={key}
                        label={value}
                        component={Link}
                        //@ts-ignore
                        to={key}
                    />
                ))}
            </PSTabs>}

            <Outlet />
        </ConnectorContextProvider>
    )
}

export default HomegrownApplications;