import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

type ConfigurationItemProps = {
  configurationTitle: string;
  configurationHelpText: string;
  children: JSX.Element;
};

export const ConfigurationItem = ({ configurationTitle, configurationHelpText, children }: ConfigurationItemProps) => {

  return (
    <Box>
      <Box sx={{ borderRadius: 2 }}>
        <Typography gutterBottom variant="h6" component="div">
          {configurationTitle}
        </Typography>
        <Typography variant="body2" sx={{ my: 2 }}>
          {configurationHelpText}
        </Typography>
      </Box>
      <Divider variant="middle" />
      <Box sx={{ my: 1, mx: 2 }}>
        <Grid container alignItems="center">
          <Grid item xs sx={{ positionLeft: '50px' }}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}