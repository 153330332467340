/** @jsxImportSource @emotion/react */
import { PieTooltipProps } from '@nivo/pie';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { DataItem } from './LLMByUsage';
import { Circle, Text, PSBox } from '../../../ui-kit';
import { LLMByUsageTooltipStyle } from './LLMByUsageTooltip.css';
import { formatTwoDecimalIfCents } from '../../../utils/number';

const datumDetails = ({ usersCount, requests, percentage }: {
    usersCount: number | undefined;
    requests: number;
    percentage: number | undefined;
}) => [
        {
            label: 'Users Count',
            value: usersCount?.toLocaleString(),
        },
        {
            label: 'Requests',
            value: requests.toLocaleString()
        },
        {
            label: 'Percentage',
            value: `${formatTwoDecimalIfCents(percentage || 0)}%`
        }
    ]

export const LLMDetails: React.FC<DataItem> = (datum) => {

    const details = datumDetails({
        percentage: datum.percentage,
        requests: datum.value,
        usersCount: datum.usersCount
    })

    return (
        <>
            <div css={LLMByUsageTooltipStyle.llmHeaderContainer}>
                <div
                    css={LLMByUsageTooltipStyle.llmImage(datum.imageUrl || '')}
                ></div>
                <Text>{datum.label}</Text>
            </div>
            <Text textCss={LLMByUsageTooltipStyle.llmUrl} variant='small'>{datum.url}</Text>
            <div css={LLMByUsageTooltipStyle.detailsContainer}>
                {details.map((detail, index) => (
                    <div key={detail.label + index} css={LLMByUsageTooltipStyle.detailItem}>
                        <Text color='black-50'>{detail.label}</Text>
                        <Text ellipsis>{detail.value}</Text>
                    </div>
                ))}
                {datum.isBlocked && <div>
                    <Text color='red' variant='bold'>Blocked</Text>
                </div>}
            </div>
        </>
    )
}

const LLMByUsageTooltip: React.FC<PieTooltipProps<DataItem>> = (props) => {
    const { datum } = props;



    const tooltipRef = useRef<HTMLDivElement>(null);
    const [positionStyle, setPositionStyle] = useState<React.CSSProperties>({});

    useLayoutEffect(() => {
        function updatePosition() {
            if (tooltipRef.current) {
                const rect = tooltipRef.current.getBoundingClientRect();
                const adjustments: React.CSSProperties = {};

                if (rect.right > window.innerWidth - rect.width) {
                    adjustments.right = 20;
                    adjustments.left = 'auto';
                } else if (rect.left < 0) {
                    adjustments.left = 20;
                    adjustments.right = 'auto';
                }

                if (rect.bottom > window.innerHeight - rect.height) {
                    adjustments.bottom = 20;
                    adjustments.top = 'auto';
                } else if (rect.top < 0) {
                    adjustments.top = 20;
                    adjustments.bottom = 'auto';
                }
                setPositionStyle(adjustments);
            }
        }

        updatePosition();
        window.addEventListener('resize', updatePosition);

        return () => {
            window.removeEventListener('resize', updatePosition);
        };
    }, [datum]);

    if (datum.id === '_others_') {
        const othersAggregated = datum.data.details?.reduce((acc, curr) => {
            return {
                usersCount: acc.usersCount + (curr.usersCount || 0),
                requests: acc.requests + curr.value,
                percentage: acc.percentage + (curr.percentage || 0)
            }
        }, {
            usersCount: 0,
            requests: 0,
            percentage: 0
        });

        const othersDetails = datumDetails(othersAggregated!);

        return (
            <div ref={tooltipRef} style={{ position: 'absolute', ...positionStyle }}>
                <PSBox
                    borderRadius={6}
                    padding={12}
                    isBoxShadow
                >
                    <div css={LLMByUsageTooltipStyle.othersContainer}>
                        <div css={LLMByUsageTooltipStyle.othersHeader}>
                            <Circle size={9} color='black-50' />
                            <div>
                                <Text ellipsis variant='bold' color='black-70'>Others (+{datum.data.details?.length})</Text>
                                <Text ellipsis variant='small'>Click to see all</Text>
                            </div>
                        </div>
                        <div css={LLMByUsageTooltipStyle.detailsContainer}>
                            <Text>Aggregated:</Text>
                            {othersDetails.map((detail, index) => (
                                <div key={detail.label + index} css={LLMByUsageTooltipStyle.detailItem}>
                                    <Text color='black-50'>{detail.label}</Text>
                                    <Text ellipsis>{detail.value}</Text>
                                </div>
                            ))}
                        </div>
                    </div>
                </PSBox>
            </div>
        )
    }

    return (
        <div ref={tooltipRef} style={{ position: 'absolute', ...positionStyle }}>
            <PSBox
                borderRadius={6}
                padding={'10px 15px 20px 15px'}
                isBoxShadow
            >
                <LLMDetails {...datum.data} />
            </PSBox>
        </div>
    )
}

export default LLMByUsageTooltip;