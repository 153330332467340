/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Checkbox, CheckboxProps } from "@mui/material";
import { ColorThemeKeys } from "../../styles";


export const PSCheckbox = styled(({ fillColor, ...props }: CheckboxProps & { fillColor?: ColorThemeKeys }) => (
    <Checkbox {...props} />
))`
    &.MuiCheckbox-root {
        padding: 0;
        color: var(--color-black-50);
    }
    &.Mui-checked {
        color: var(--color-${props => props.fillColor || 'black'});
    }
`;

export default PSCheckbox;