import { graphql } from "../../../gql";

export const createConnectorAndPolicy = graphql(`
    mutation CreateConnector($name: String!, $type: ApplicationTypes!) {
        createApplicationAndRuleAndPolicy(input: {
            name: $name
            type: $type
        }) {
            id
            name
        }
    }
`);

export const deleteConnectorByName = graphql(`
    mutation DeleteConnector($name: String!) {
        deleteApplication(name: $name, deletePolicy: true) {
            id
        }
    }
`);

export const renameConnectorByName = graphql(`
    mutation RenameConnector($oldName: String!, $newName: String!) {
        updateApplication(input: {
            oldName: $oldName
            newName: $newName
        }) {
            id
            name
        }
    }
`)