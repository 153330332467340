/** @jsxImportSource @emotion/react */
import { Checkbox, Collapse, IconButton, InputAdornment, Radio, RadioGroup, Slider, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Chip, Icon, ItemListInput, NoData, PSCopyText, PSFormControlLabel, Text, Tooltip } from '../../../../ui-kit';
import { IProtectionFormProps, SENSITIVE_DATA_TYPES, TSensitiveDataProtection } from '../Common';
import { CATEGORIES, categorizedSensitiveData, CATEGORY_EXPLANATIONS, SensitiveDataPreset, SENSITIVE_DATA_PRESETS } from './preset';
import { SensitiveDataFormStyle } from './SensitiveDataForm.css';

const CategorySection: React.FC<{
    category: string;
    presets: SensitiveDataPreset[];
    protection: TSensitiveDataProtection;
    searchValue: string;
    onCategoryChange: (category: keyof typeof CATEGORIES, action: 'add' | 'remove') => void;
    onEntityTypeChange: (entityType: keyof typeof SENSITIVE_DATA_TYPES) => void;
}> = (props) => {
    const { category, presets, protection, searchValue, onCategoryChange, onEntityTypeChange } = props;
    const [isExpanded, setIsExpanded] = React.useState(false);
    const isAllChecked = presets.every(x => protection.entity_types.includes(x.key));
    const isSomeChecked = presets.some(x => protection.entity_types.includes(x.key));

    useEffect(() => {
        setIsExpanded(!!searchValue);
    }, [searchValue])

    return (
        <div>
            <div css={SensitiveDataFormStyle.collapseCategory}>
                <Checkbox
                    size='small'
                    indeterminate={isSomeChecked && !isAllChecked}
                    checked={isAllChecked}
                    onChange={() => onCategoryChange(category as keyof typeof CATEGORIES, isAllChecked ? 'remove' : 'add')}
                    disabled={!!searchValue}
                />
                <Tooltip disableInteractive placement='top' title={CATEGORY_EXPLANATIONS[category as keyof typeof CATEGORY_EXPLANATIONS].label}>
                    <div><Chip size='small' variant='outlined' label={category.toUpperCase()} /></div>
                </Tooltip>
                <Tooltip disableInteractive placement='top' title={CATEGORY_EXPLANATIONS[category as keyof typeof CATEGORY_EXPLANATIONS].explanation}>
                    <div><Icon iconName='HelpOutline' color='black-70' iconSize={16} /></div>
                </Tooltip>
                <Text><b>{presets.filter(x => protection.entity_types.includes(x.key)).length}/{presets.length}</b> related data types</Text>
                <IconButton onClick={() => setIsExpanded(!isExpanded)} css={SensitiveDataFormStyle.collapseCategoryButton}>
                    <Icon color='black-70' iconName={isExpanded ? 'ExpandLessRounded' : 'ExpandMoreRounded'} />
                </IconButton>
            </div>
            <Collapse in={isExpanded} timeout='auto' unmountOnExit>
                <div css={SensitiveDataFormStyle.categoryDataTypesContainer}>
                    {presets
                        .sort((a, b) => a.key.localeCompare(b.key))
                        .filter(preset =>
                            preset.key.toLowerCase().includes(searchValue.toLowerCase())
                            || preset.description.toLowerCase().includes(searchValue.toLowerCase())
                        )
                        .map(preset => (
                            <div key={category + preset.key} css={SensitiveDataFormStyle.dataTypeContainer}>
                                <Switch
                                    size='small'
                                    checked={protection.entity_types.includes(preset.key)}
                                    onChange={() => onEntityTypeChange(preset.key as keyof typeof SENSITIVE_DATA_TYPES)}
                                />
                                <div css={SensitiveDataFormStyle.dataTypeLabelAndDescription}>
                                    <Text>{preset.key}</Text>
                                    <Text pre variant='small' color='black-70'>{preset.description}</Text>
                                    <div css={SensitiveDataFormStyle.examplesContainer}>
                                        <Text variant='smallBold' color='black-70'>Examples</Text>
                                        {preset.examples.map((example: string) => (
                                            <PSCopyText key={category + preset.key + example} text={example} toastText="Example copied to clipboard" />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </Collapse>
        </div>
    );
};

const SensitiveDataForm: React.FC<IProtectionFormProps<TSensitiveDataProtection>> = (props) => {
    const { control, formName, protection, isAdvancedMode } = props;
    const { setValue } = useFormContext();
    const [dataTypesView, setDataTypesView] = useState<'group' | 'flat'>('group');
    const [dataTypesSearchValue, setDataTypesSearchValue] = useState('');

    const onEntityTypesChange = (entityType: keyof typeof SENSITIVE_DATA_TYPES) => {
        const newEntityTypes = protection.entity_types.includes(entityType)
            ? (protection.entity_types as string[]).filter(x => x !== entityType)
            : [...protection.entity_types, entityType];

        setValue(`${formName}.entity_types`, newEntityTypes, { shouldDirty: true, shouldTouch: true });
    }

    const onCategoryChange = (category: keyof typeof CATEGORIES, action: 'add' | 'remove') => {
        const categoryEntityTypes = categorizedSensitiveData[category].map(x => x.key);
        const entityTypeSet = new Set(protection.entity_types);

        if (action === 'add') {
            categoryEntityTypes.forEach(x => entityTypeSet.add(x));
        } else {
            categoryEntityTypes.forEach(x => entityTypeSet.delete(x));
        }

        setValue(`${formName}.entity_types`, Array.from(entityTypeSet), { shouldDirty: true, shouldTouch: true });
    }

    const onAllEntityTypesChange = (action: 'add' | 'remove') => {
        const entityTypeSet = new Set(protection.entity_types);

        if (action === 'add') {
            SENSITIVE_DATA_PRESETS.forEach(x => entityTypeSet.add(x.key));
        } else {
            SENSITIVE_DATA_PRESETS.forEach(x => entityTypeSet.delete(x.key));
        }

        setValue(`${formName}.entity_types`, Array.from(entityTypeSet), { shouldDirty: true, shouldTouch: true });
    }

    const combinedThresholdsAndCustomEntityTypes: Record<string, number> = {
        ...protection.thresholds as Record<string, number>,
        ...protection.custom_entity_types.reduce((acc, entityType) => {
            acc[entityType] = 0.5;
            return acc;
        }, {} as Record<string, number>)
    }

    const categorizedSensitiveDataEntries = Object.entries(categorizedSensitiveData)
        .filter(([category, presets]) => presets.some(preset =>
            preset.key.toLowerCase().includes(dataTypesSearchValue.toLowerCase())
            || preset.description.toLowerCase().includes(dataTypesSearchValue.toLowerCase())
        ));

    const tableSensitiveDataEntries = SENSITIVE_DATA_PRESETS
        .sort((a, b) => a.key.localeCompare(b.key))
        .filter(x =>
            x.key.toLowerCase().includes(dataTypesSearchValue.toLowerCase())
            || x.description.toLowerCase().includes(dataTypesSearchValue.toLowerCase())
            || x.categories.some(category => category?.toLowerCase().includes(dataTypesSearchValue.toLowerCase()))
        )

    return (
        <div css={SensitiveDataFormStyle.self}>

            <div css={SensitiveDataFormStyle.actionsContainer}>
                <div css={SensitiveDataFormStyle.actionContainer}>
                    <Text variant='bold'>Action</Text>
                    <Controller
                        name={`${formName}.action`}
                        defaultValue={protection.action}
                        control={control}
                        render={({ field }) => (
                            <RadioGroup
                                {...field}
                                row
                                value={field.value}
                            >
                                <PSFormControlLabel value={'block'} control={<Radio size='small' />} label="Block" />
                                <PSFormControlLabel value={'sanitize'} control={<Radio size='small' />} label="Redact" />
                            </RadioGroup>
                        )}
                    />

                    {protection.action === 'sanitize' && <Controller
                        name={`${formName}.use_faker`}
                        defaultValue={protection.use_faker}
                        control={control}
                        render={({ field }) => (
                            <PSFormControlLabel
                                {...field}
                                checked={field.value}
                                label="Generate dummy data matching the specific data type"
                                control={<Switch />}
                            />
                        )}
                    />}
                </div>
            </div>

            <div>
                <div css={SensitiveDataFormStyle.dataTypesTitleContainer}>
                    <Text variant='bold'>Data Types</Text>

                    <RadioGroup
                        row
                        value={dataTypesView}
                        onChange={(_, value) => setDataTypesView(value as typeof dataTypesView)}
                    >
                        <PSFormControlLabel value={'group'} control={<Radio size='small' />} label="By compliance framework" />
                        <PSFormControlLabel value={'flat'} control={<Radio size='small' />} label="All" />
                    </RadioGroup>

                    <TextField
                        css={SensitiveDataFormStyle.dataTypesSearch}
                        value={dataTypesSearchValue}
                        onChange={(e) => setDataTypesSearchValue(e.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position='start'><Icon iconName='SearchRounded' color='black-50' /></InputAdornment>
                        }}
                        size='small'
                        label='Search'
                    />
                </div>
                {dataTypesView === 'group' && <div>
                    <Text css={SensitiveDataFormStyle.noticeText} variant='smallBold'>* Notice that the following data types are shared across multiple categories.</Text>
                    {categorizedSensitiveDataEntries.length > 0 ?
                        categorizedSensitiveDataEntries.map(([category, presets]) => (
                            <CategorySection
                                key={category}
                                searchValue={dataTypesSearchValue}
                                category={category}
                                presets={presets}
                                protection={protection}
                                onCategoryChange={onCategoryChange}
                                onEntityTypeChange={onEntityTypesChange}
                            />
                        )) : <NoData
                            header='No data types found'
                            message='Please try a different search term.'
                        />}
                </div>}
                {dataTypesView === 'flat' && <div css={SensitiveDataFormStyle.tableOverflow}>
                    <table css={SensitiveDataFormStyle.tableContainer}>
                        <thead>
                            <tr>
                                <th>
                                    <div css={SensitiveDataFormStyle.checkboxColumn}>
                                        <Checkbox
                                            size='small'
                                            indeterminate={SENSITIVE_DATA_PRESETS.some(x => protection.entity_types.includes(x.key)) && !SENSITIVE_DATA_PRESETS.every(x => protection.entity_types.includes(x.key))}
                                            checked={SENSITIVE_DATA_PRESETS.every(x => protection.entity_types.includes(x.key))}
                                            onChange={() => onAllEntityTypesChange(SENSITIVE_DATA_PRESETS.every(x => protection.entity_types.includes(x.key)) ? 'remove' : 'add')}
                                            disabled={!!dataTypesSearchValue}
                                        />
                                        <Text color='black-70'>DATA TYPE</Text>
                                    </div>
                                </th>
                                <th><Text color='black-70'>COMPLIANCE FRAMEWORK</Text></th>
                                <th><Text color='black-70'>EXAMPLES</Text></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableSensitiveDataEntries.length > 0 ? tableSensitiveDataEntries.map(x => (
                                <tr key={x.key}>
                                    <td>
                                        <div css={SensitiveDataFormStyle.checkboxColumn}>
                                            <Checkbox
                                                size='small'
                                                checked={protection.entity_types.includes(x.key)}
                                                onChange={() => onEntityTypesChange(x.key as keyof typeof SENSITIVE_DATA_TYPES)}
                                            />
                                            <Text css={SensitiveDataFormStyle.sensitiveDataKey}>{x.key}</Text>
                                            <Tooltip disableInteractive placement='top' title={x.description}>
                                                <div><Icon iconName='HelpOutline' color='black-70' iconSize={16} /></div>
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <td>
                                        <Text>{x.categories.join(', ')}</Text>
                                    </td>
                                    <td>
                                        <div css={SensitiveDataFormStyle.examplesContainer}>
                                            {x.examples.map((example: string) => (
                                                <PSCopyText key={x.key + example} text={example} toastText="Example copied to clipboard" maxWidth={280} />
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                            )) : <tr>
                                <td colSpan={3}>
                                    <NoData
                                        header='No data types found'
                                        message='Please try a different search term.'
                                    />
                                </td>
                            </tr>}
                        </tbody>
                    </table>
                </div>}
            </div>


            <Controller
                name={`${formName}.custom_entity_types`}
                control={control}
                defaultValue={protection.custom_entity_types}
                render={({ field }) => (
                    <ItemListInput
                        {...field}
                        editable={false}
                        label='Custom Data Types'
                        placeholder='Add custom data types'
                    />
                )}
            />

            <Controller
                name={`${formName}.hidden_names`}
                control={control}
                defaultValue={protection.hidden_names}
                render={({ field }) => (
                    <ItemListInput
                        {...field}
                        editable={false}
                        label='Hidden terms to redact'
                        placeholder='Add hidden terms'
                        description='These terms will be hidden e.g. [REDACTED_CUSTOM_1].'
                    />
                )}
            />

            <Controller
                name={`${formName}.allowed_names`}
                control={control}
                defaultValue={protection.allowed_names}
                render={({ field }) => (
                    <ItemListInput
                        {...field}
                        value={((typeof field.value === 'object' && !Array.isArray(field.value) && field.value !== null) ? Object.values(field.value) : field.value) || []}
                        editable={false}
                        label='Allowed terms to ignore'
                        placeholder='Add allowed terms'
                        description='These terms will be ignored even if flagged by the detector.'
                    />
                )}
            />

            {isAdvancedMode &&
                <React.Fragment>
                    {Object.entries(combinedThresholdsAndCustomEntityTypes)
                        .sort(([a], [b]) => a.localeCompare(b))
                        .map(([key, value]) => (
                            <Controller
                                key={key}
                                name={`${formName}.thresholds.${key}`}
                                control={control}
                                defaultValue={value}
                                rules={{
                                    min: 0,
                                    max: 1
                                }}
                                render={({ field }) => (
                                    <div>
                                        <Text>{key}</Text>
                                        <Slider {...field} min={0} max={1} step={0.05} valueLabelDisplay="on"
                                            marks={[
                                                { value: 0, label: '0' },
                                                { value: 0.25, label: '0.25' },
                                                { value: 0.5, label: '0.5' },
                                                { value: 0.75, label: '0.75' },
                                                { value: 1, label: '1' },
                                            ]}
                                        />
                                    </div>
                                )}
                            />
                        ))}
                </React.Fragment>
            }
        </div>
    )
}

export default SensitiveDataForm;