/** @jsxImportSource @emotion/react */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PSError404Icon } from '../../assets/images';
import { PSButton, Text } from '../../ui-kit';
import { Error404Style } from './Error404.css';

type IProps = {};

const Error404: React.FC<IProps> = (props) => {
    const { } = props;

    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/');
    };

    return (
        <div css={Error404Style.self}>
            <PSError404Icon />
            <Text variant='header2'>Lost in Space?</Text>
            <Text >Looks like you tried to access a restricted area or just mistyped something. It's all good—this happens even to the best of us in the realm of security! Let's redirect you back to familiar grounds.</Text>
            <PSButton css={Error404Style.goHomeButton} variant='filled' onClick={handleRedirect}>Go Home</PSButton>
        </div>
    )
}

export default Error404;