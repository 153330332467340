import { Grid, FormLabel, FormControl, Tooltip, RadioGroup, Radio, FormControlLabel } from "@mui/material";

export type typeDisplay = {
    text: string;
    helpText: string;
}

type TypeRadioProps = {
    value: string | undefined | null;
    title: string;
    help: string | null;
    handleOnChange: (value: string) => void;
    disable: boolean;
    types: string[];
    typeDisplayText: (type: string) => typeDisplay;
}

export const TypeRadio = ({ value, title, help, handleOnChange, disable, types, typeDisplayText }: TypeRadioProps) => {
    if (value === undefined || value === null) {
        value = types[0];
    }

    return (
        <Grid container alignItems="center">
            <Grid item xs={4} sx={{ mx: 3, my: 2 }}>
                <Tooltip key={title} title={help} placement='top-start'>
                    <FormLabel component="legend">{<span style={{ color: 'black' }}>{title}</span>}</FormLabel>
                </Tooltip>
            </Grid>
            <Grid item xs sx={{ mx: 3 }}>
                <FormControl>
                    <RadioGroup
                    row
                    value={value}
                    >
                    {types.map((type) => {
                        const {text, helpText} = typeDisplayText(type);
                        return (
                            <Tooltip key={type} title={helpText} placement="top-start">
                                <FormControlLabel
                                    disabled={disable}
                                    key={type}
                                    value={type}
                                    control={<Radio size='small' />}
                                    label={<span>{text}</span>}
                                    onChange={() => handleOnChange(type)}
                                />
                            </Tooltip>
                    )})}
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    )
}