/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const NavigationMenuUserStyle = {
  self: css``,

  userButton: (isCollapsed: boolean) => css`
    border-radius: 25px;
    padding: var(--spacing-sm);
    border: none;
    width: 100%;
    display: flex;
    flex: 1;
    gap: ${isCollapsed ? 'var(--spacing-xs)' : 'var(--spacing-sm)'};
    align-items: ${isCollapsed ? 'center' : 'flex-start'};
    background: var(--color-transparent);
    cursor: pointer;

    &.active, &:hover {
      background: var(--color-white-15);

      & .user-details {
        & .icon {
          min-width: 15px;
        }
      }
    }

    &:active {
      background: var(--color-white-10);
    }

    & .user-details {
      display: flex;
      flex-direction: column;
      flex: 1;

      & .user-details-name {
        display: flex;
        align-items: center;
        gap: var(--spacing-sm);
      }
  
      & .user-details-tenant {
        display: flex;
        flex: 1;
        margin-left: var(--spacing-xl);
        color: var(--color-black-50);
      }
    }
  `,

  userDetailsIcon: css`
    min-width: 15px;
    min-height: 15px;
  `,

  userDetails: css`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-block: 20px 15px;
    align-items: center;
  `,

  userTenants: css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
  `,

  logOutButton: css`
    padding: 12px 15px;

    & .MuiListItemIcon-root {
      min-width: 25px;
    }
  `,

  userMenuPopover: css`
    margin-top: -14px;
  `,
};