/** @jsxImportSource @emotion/react */
import React from 'react';
import { CustomCellRendererProps } from 'ag-grid-react';
import { GenAiApplicationStyle } from './GenAiApplication.css';
import Text from '../../../Text/Text';
import Icon from '../../../Icon/Icon';

type IProps = {
    includeAppDomain?: boolean;
    iconSize?: number;
} & Partial<CustomCellRendererProps>;

const GenAiApplication: React.FC<IProps> = (props) => {
    const { value, includeAppDomain = false, iconSize = 24 } = props;

    if (!value) return null;

    const imageSrc = value.logo || value.faviconUrl;
    const isCustom = value.isCustom || value.verifiedOrCustom === 'Custom';

    return (
        <div css={GenAiApplicationStyle.self}>
            {isCustom && <Icon iconName='PSCustomApplicationIcon' iconSize={iconSize} />}
            {!isCustom && imageSrc && <img
                width={iconSize}
                height={iconSize}
                src={imageSrc}
                alt={value.name}
                loading='lazy'
                onError={(e) => {
                    e.currentTarget.src = 'https://fakeimg.pl/24x24/?text=GENAI'
                }}
            />}
            <Text ellipsis>{value.name}</Text>
            {includeAppDomain && <Text variant='small' color='black-70'>({value.domain})</Text>}
        </div>
    )
}

export default GenAiApplication;