import { useState } from 'react';
import { ConfigurationItem } from '../ConfigurationComponents/ConfigurationItem';
import { TypeRadio, typeDisplay } from '../ConfigurationComponents/TypeRadio';
import { Box } from '@mui/material';

type ConfigurationItemLoggingFrequencyProps = {
    inputLoggingFrequencyInSeconds: number;
    editMode: boolean;
    handleConfigurationChange: (mode: number) => void;
};

const Frequencies = new Map([
    ['zero', 0],
    ['hour', 60 * 60],
    ['day', 60 * 60 * 24],
    ['week', 60 * 60 * 24 * 7],
    ['twoWeeks', 60 * 60 * 24 * 7 * 2]
]);

export const ConfigurationItemLoggingFrequency = ({ inputLoggingFrequencyInSeconds, editMode, handleConfigurationChange }: ConfigurationItemLoggingFrequencyProps) => {
    const [loggingFrequencyInSeconds, setLoggingFrequencyInSeconds] = useState<number>(inputLoggingFrequencyInSeconds);

    if (loggingFrequencyInSeconds !== inputLoggingFrequencyInSeconds && !editMode) {
        setLoggingFrequencyInSeconds(inputLoggingFrequencyInSeconds);
    }

    const handleChange = (mode: number | string) => {
        setLoggingFrequencyInSeconds(mode as number);
        handleConfigurationChange(mode as number);
    };

    const displayLoggingFrequencyText = (loggingFrequencyInSeconds: number | string): typeDisplay => {
        if (loggingFrequencyInSeconds === Frequencies.get('zero')) {
            return { text: 'Log all the violations', helpText: '' };
        } else if (loggingFrequencyInSeconds === Frequencies.get('hour')) {
            return { text: '1 hour', helpText: '' };
        } else if (loggingFrequencyInSeconds === Frequencies.get('day')) {
            return { text: '1 day', helpText: '' };
        } else if (loggingFrequencyInSeconds === Frequencies.get('week')) {
            return { text: '1 week', helpText: '' };
        } else if (loggingFrequencyInSeconds === Frequencies.get('twoWeeks')) {
            return { text: '2 weeks', helpText: '' };
        } else {
            return { text: '', helpText: ''}
        }
    }

    return (
        <ConfigurationItem 
            configurationTitle='Frequency of Alerts for Developer-Related Violations'
            configurationHelpText='This field lets you set the frequency of alerts for violations found in code. In other words, it determines how often the Prompt Security Platform will alert you about the same violation, such as an exposed cloud token, for the same user. Applies only when set to log violations only.'
        >
            <Box>
                <TypeRadio value={loggingFrequencyInSeconds} title={null} help={null} handleOnChange={handleChange} types={Array.from(Frequencies.values())} typeDisplayText={displayLoggingFrequencyText}/>
            </Box>
        </ConfigurationItem>
    )
};