/** @jsxImportSource @emotion/react */
import React from 'react';
import { GenAi } from '../../GenAiMgmt';
import { EmployeesManageGenAiApplicationsStyle } from './EmployeesManageGenAiApplications.css';

type IProps = {

};

const EmployeesManageGenAiApplications: React.FC<IProps> = (props) => {
    const { } = props;

    return (
        <div css={EmployeesManageGenAiApplicationsStyle.self}>
            <GenAi />
        </div>
    )
}

export default EmployeesManageGenAiApplications;