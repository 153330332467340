/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { TErrorDirection } from "./PSError";

export const PSErrorStyle = {
  self: (direction: TErrorDirection) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    ${direction === "row" &&
    `
      flex-direction: row; 
      align-items: center;
      gap: 10px;
    `}
  `,
  iconContainer: (direction: TErrorDirection) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    background: var(--color-red-10);
    width: fit-content;
    height: fit-content;

    ${direction === "row" && "scale: 0.7;"};
  `,
  errorHeader: (direction: TErrorDirection) => css`
    margin-top: 14px;

    ${direction === "row" && "margin: 0;"};
  `,
  errorMessage: (direction: TErrorDirection) => css`
    margin-top: 8px;

    ${direction === "row" && "margin: 0;"};
  `,
};
