/** @jsxImportSource @emotion/react */
import MuiPopover from '@mui/material/Popover';
import styled from '@emotion/styled';
import { SerializedStyles, css } from '@emotion/react';

interface PopoverProps {
  width?: string | number;
  customCss?: SerializedStyles;
}

const Popover = styled(MuiPopover)<PopoverProps>(
  ({ width, customCss }) => css`
  .MuiPopover-paper {
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    width: ${typeof width === 'number' ? `${width}px` : width};
    ${customCss}
  }
  `
);

export default Popover;