/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import React, { useCallback } from 'react';
import { ColorThemeKeys } from '../../styles';
import { CircleStyle } from './Circle.css';
type IProps = {
    size: string | number;
    circleCss?: SerializedStyles | string;
    children?: React.ReactNode;
} & (
        | {
            nativeColor?: string;
        }
        | {
            color?: ColorThemeKeys;
        }
    );

const Circle: React.FC<IProps> = (props) => {
    const { children, size = 10, circleCss } = props;

    const CircleComponent = (color: string) => (
        <div css={[CircleStyle.self(size, color), circleCss]}>
            {children}
        </div>
    );

    if ('nativeColor' in props) {
        const { nativeColor = 'var(--color-black)' } = props;
        return CircleComponent(nativeColor);
    }

    if ('color' in props) {
        const { color = 'black' } = props;
        return CircleComponent(`var(--color-${color})`);
    }

    return CircleComponent('var(--color-black)');
}

export default Circle;