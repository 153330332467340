import { v4 as uuidv4 } from 'uuid';

type OperatingSystem = 'mac' | 'windows' | 'enterprise';
type Browser = 'chrome' | 'edge' | 'brave' | 'vivaldi' | 'arc' | 'firefox' | 'safari' | 'talon' | 'island' | 'opera' | 'whale' | 'chromium';

interface ConfigOptions {
    operatingSystem: OperatingSystem;
    browser: Browser;
    domain: string;
    appId: string;
    addCustomHttpHeader: string;
}

interface ConfigFile {
    content: string;
    filename: string;
}

function getFirefoxWindowsMdmConfig(apiDomain: string, apiKey: string, addCustomHttpHeader: string): string {
    return `[HKEY_LOCAL_MACHINE\\SOFTWARE\\Mozilla\\ManagedStorage\\extension@prompt.security]
"apiDomain"="${apiDomain}"
"apiKey"="${apiKey}"
"addCustomHttpHeader"="${addCustomHttpHeader}"
[HKEY_LOCAL_MACHINE\\SOFTWARE\\Policies\\Mozilla\\Firefox\\Extensions\\Install]
"1"="https://addons.mozilla.org/firefox/downloads/file/4237157/prompt_security_browser_ext-4.8.3.xpi"
[HKEY_LOCAL_MACHINE\\SOFTWARE\\Policies\\Mozilla\\Firefox\\Extensions\\Locked]
"1"="extension@prompt.security"
`;
}

function getWindowsMdmConfig(browserName: Browser, apiDomain: string, apiKey: string, addCustomHttpHeader: string): string {
    const globals = `
$global:API_DOMAIN = "${apiDomain}" 
$global:API_KEY = "${apiKey}" 
$global:ADD_CUSTOM_HTTP_HEADER = "${addCustomHttpHeader}" # If you want the extension to add a custom http header to all requests, set this to "true"
`;

    const script = `
function Set-BrowserExtensionPolicy {
    param (
        [string]$basePath,
        [string]$extensionId,
        [string]$extensionUpdateUrl
    )

    $path = "$basePath\\ExtensionInstallForcelist"
    if (-not (Test-Path $path)) {
        New-Item -Path $path -Force
    }

    $value = "$extensionId;$extensionUpdateUrl"
    $success = $false
    for ($NUM = 1; $NUM -le 10; $NUM++) {
        try {
            $currentValue = Get-ItemProperty -Path $path -Name $NUM -ErrorAction SilentlyContinue
            if ($currentValue) {
                if ($currentValue.$NUM -eq $value) {
                    $success = $true
                    break
                }
            } else {
                New-ItemProperty -Path $path -Name $NUM -Value $value -PropertyType String -ErrorAction Stop
                $success = $true
                break
            }
        } catch {
            Write-Error "Failed to set extension policy: $_"
        }
    }

    if (-not $success) {
        Write-Error "Failed to set extension policy after 10 attempts."
    }

    $parentKey = "$basePath\\3rdparty\\extensions\\$extensionId\\policy"
    if (-not (Test-Path $parentKey)) {
        New-Item -Path $parentKey -Force
    }

    try {
        New-ItemProperty -Path $parentKey -Name 'apiDomain' -Value $global:API_DOMAIN -PropertyType String -Force
        New-ItemProperty -Path $parentKey -Name 'apiKey' -Value $global:API_KEY -PropertyType String -Force
        New-ItemProperty -Path $parentKey -Name 'addCustomHttpHeader' -Value $global:ADD_CUSTOM_HTTP_HEADER -PropertyType String -Force
    } catch {
        Write-Error "Failed to set extension properties: $_"
    }
}

function Set-FirefoxExtensionPolicy {
    $firefoxDistDir = "$env:ProgramFiles\\Mozilla Firefox\\distribution"
    if (-Not (Test-Path -Path $firefoxDistDir)) {
        New-Item -ItemType Directory -Path $firefoxDistDir -Force
    }

    $policiesJson = @"
{
    "policies": {
        "ExtensionSettings": {
            "extension@prompt.security": {
                "installation_mode": "force_installed",
                "install_url": "https://addons.mozilla.org/firefox/downloads/latest/prompt-security-browser-ext/latest.xpi"
            }
        }
    }
}
"@
    Set-Content -Path "$firefoxDistDir\\policies.json" -Value $policiesJson

    $extensionJson = @"
{
    "name": "extension@prompt.security",
    "description": "ignored",
    "type": "storage",
    "data": {
        "apiDomain": "$global:API_DOMAIN",
        "apiKey": "$global:API_KEY",
        "addCustomHttpHeader": "$global:ADD_CUSTOM_HTTP_HEADER"
    }
}
"@
    Set-Content -Path "$firefoxDistDir\\extension@prompt.security.json" -Value $extensionJson

    reg add "HKLM\\SOFTWARE\\Mozilla\\ManagedStorage\\extension@prompt.security" /ve /t REG_SZ /d "$env:ProgramFiles\\Mozilla Firefox\\distribution\\extension@prompt.security.json" /f

    if ($env:ProgramFiles -ne  \${env:ProgramFiles(x86)}) {
        $firefoxDistDir32 = "\${env:ProgramFiles(x86)}\\Mozilla Firefox\\distribution"
        if (-Not (Test-Path -Path $firefoxDistDir32)) {
            New-Item -ItemType Directory -Path $firefoxDistDir32 -Force
        }

        Copy-Item -Path "$firefoxDistDir\\policies.json" -Destination "$firefoxDistDir32\\" -Force
        Copy-Item -Path "$firefoxDistDir\\extension@prompt.security.json" -Destination "$firefoxDistDir32\\" -Force

        reg add "HKLM\\SOFTWARE\\WOW6432Node\\Mozilla\\ManagedStorage\\extension@prompt.security" /ve /t REG_SZ /d "$firefoxDistDir32\\extension@prompt.security.json" /f
    }
}

# Chrome 
Set-BrowserExtensionPolicy -basePath "HKLM:\\SOFTWARE\\Policies\\Google\\Chrome" -extensionId "iidnankcocecmgpcafggbgbmkbcldmno" -extensionUpdateUrl "https://clients2.google.com/service/update2/crx"

# Edge 
Set-BrowserExtensionPolicy -basePath "HKLM:\\SOFTWARE\\Policies\\Microsoft\\Edge" -extensionId "pbbbemeeoofiifphflkhmldadohopcki" -extensionUpdateUrl "https://edge.microsoft.com/extensionwebstorebase/v1/crx"

# Brave 
Set-BrowserExtensionPolicy -basePath "HKLM:\\SOFTWARE\\Policies\\BraveSoftware\\Brave-Browser" -extensionId "iidnankcocecmgpcafggbgbmkbcldmno" -extensionUpdateUrl "https://clients2.google.com/service/update2/crx"

# Vivaldi 
Set-BrowserExtensionPolicy -basePath "HKLM:\\SOFTWARE\\Policies\\Vivaldi" -extensionId "iidnankcocecmgpcafggbgbmkbcldmno" -extensionUpdateUrl "https://clients2.google.com/service/update2/crx"

# Arc & Chromium
Set-BrowserExtensionPolicy -basePath "HKLM:\\SOFTWARE\\Policies\\Chromium" -extensionId "iidnankcocecmgpcafggbgbmkbcldmno" -extensionUpdateUrl "https://clients2.google.com/service/update2/crx"

# Naver Whale
Set-BrowserExtensionPolicy -basePath "HKLM:\\SOFTWARE\\Policies\\Naver\\Whale" -extensionId "iidnankcocecmgpcafggbgbmkbcldmno" -extensionUpdateUrl "https://clients2.google.com/service/update2/crx"

# Firefox 
Set-FirefoxExtensionPolicy
`;

    return globals + script;
}

function getFirefoxMdmConfig(apiDomain: string = 'app.prompt.security', apiKey: string, addCustomHttpHeader: string): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE plist PUBLIC "-//Apple//DTD PLIST 1.0//EN" "http://www.apple.com/DTDs/PropertyList-1.0.dtd">
<plist version="1.0">
<dict>
    <key>PayloadContent</key>
    <array>
        <dict>
            <key>PayloadContent</key>
            <dict>
                <key>org.mozilla.firefox</key>
                <dict>
                    <key>Forced</key>
                    <array>
                        <dict>
                            <key>mcx_preference_settings</key>
                            <dict>
                                <key>3rdparty</key>
                                <dict>
                                    <key>Extensions</key>
                                    <dict>
                                        <key>extension@prompt.security</key>
                                        <dict>
                                            <key>apiDomain</key>
                                            <string>${apiDomain}</string>
                                            <key>apiKey</key>
                                            <string>${apiKey}</string>
                                            <key>addCustomHttpHeader</key>
                                            <string>${addCustomHttpHeader}</string>
                                        </dict>
                                    </dict>
                                </dict>
                                <key>EnterprisePoliciesEnabled</key>
                                <true/>
                                <key>ExtensionSettings</key>
                                <dict>
                                    <key>extension@prompt.security</key>
                                    <dict>
                                        <key>install_url</key>
                                        <string>https://addons.mozilla.org/firefox/downloads/latest/prompt-security-browser-ext/latest.xpi</string>
                                        <key>installation_mode</key>
                                        <string>force_installed</string>
                                    </dict>
                                </dict>
                            </dict>
                        </dict>
                    </array>
                </dict>
            </dict>
            <key>PayloadDisplayName</key>
            <string>Mozilla Firefox Extension Settings</string>
            <key>PayloadEnabled</key>
            <true/>
            <key>PayloadIdentifier</key>
            <string>org.mozilla.firefox.extension@prompt.security.installation_mode</string>
            <key>PayloadType</key>
            <string>com.apple.ManagedClient.preferences</string>
            <key>PayloadUUID</key>
            <string>${uuidv4()}</string>
            <key>PayloadVersion</key>
            <integer>1</integer>
        </dict>
    </array>
    <key>PayloadDisplayName</key>
    <string>Prompt Security Firefox Extension</string>
    <key>PayloadIdentifier</key>
    <string>org.extension.profiles.firefox</string>
    <key>PayloadRemovalDisallowed</key>
    <true/>
    <key>PayloadScope</key>
    <string>System</string>
    <key>PayloadType</key>
    <string>Configuration</string>
    <key>PayloadUUID</key>
    <string>${uuidv4()}</string>
    <key>PayloadVersion</key>
    <integer>1</integer>
</dict>
</plist>`;
}

function getMacMdmConfig(browserName: Browser, apiDomain: string = 'app.prompt.security', apiKey: string, addCustomHttpHeader: string): string {
    if (browserName === 'firefox') {
        return getFirefoxMdmConfig(apiDomain, apiKey, addCustomHttpHeader);
    }
    const extensionId = browserName === "edge" ? "pbbbemeeoofiifphflkhmldadohopcki" : "iidnankcocecmgpcafggbgbmkbcldmno";
    const updateUrl = browserName === "edge" ? "https://edge.microsoft.com/extensionwebstorebase/v1/crx" : "https://clients2.google.com/service/update2/crx";

    const browserKey: { [key in Browser]?: string } = {
        chrome: "com.google.Chrome",
        edge: "com.microsoft.Edge",
        brave: "com.Brave.Browser",
        arc: "company.thebrowser.Browser",
        vivaldi: "com.vivaldi.Vivaldi",
        chromium: "org.chromium.Chromium",
        whale: "com.naver.Whale"
    };

    if (!browserKey[browserName]) {
        throw new Error(`Unsupported browser for Mac: ${browserName}`);
    }

    return `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE plist PUBLIC "-//Apple//DTD PLIST 1.0//EN" "http://www.apple.com/DTDs/PropertyList-1.0.dtd">
<plist version="1.0">
  <dict>
    <key>PayloadIdentifier</key>
    <string>org.extension.profiles.${browserKey[browserName]}</string>
    <key>PayloadType</key>
    <string>Configuration</string>
    <key>PayloadUUID</key>
    <string>${uuidv4()}</string>
    <key>PayloadDisplayName</key>
    <string>Prompt Security ${browserName} Extension</string>
    <key>PayloadRemovalDisallowed</key>
    <true/>
    <key>PayloadScope</key>
    <string>System</string>
    <key>PayloadContent</key>
    <array>
      <dict>
        <key>PayloadType</key>
        <string>com.apple.ManagedClient.preferences</string>
        <key>PayloadIdentifier</key>
        <string>org.extension.profiles.${browserKey[browserName]}.forcelist</string>
        <key>PayloadUUID</key>
        <string>${uuidv4()}</string>
        <key>PayloadDisplayName</key>
        <string>Prompt Security ${browserName} Extension Forcelist</string>
        <key>PayloadContent</key>
        <dict>
          <key>${browserKey[browserName]}</key>
          <dict>
            <key>Forced</key>
            <array>
              <dict>
                <key>mcx_preference_settings</key>
                <dict>
                  <key>ExtensionInstallForcelist</key>
                  <array>
                    <string>${extensionId};${updateUrl}</string>
                  </array>
                </dict>
              </dict>
            </array>
          </dict>
        </dict>
      </dict>
      <dict>
        <key>PayloadType</key>
        <string>com.apple.ManagedClient.preferences</string>
        <key>PayloadIdentifier</key>
        <string>org.extension.profiles.${browserKey[browserName]}.extensions</string>
        <key>PayloadUUID</key>
        <string>${uuidv4()}</string>
        <key>PayloadDisplayName</key>
        <string>Prompt Security ${browserName} Extension Policy</string>
        <key>PayloadContent</key>
        <dict>
          <key>${browserKey[browserName]}.extensions.${extensionId}</key>
          <dict>
            <key>Forced</key>
            <array>
              <dict>
                <key>mcx_preference_settings</key>
                <dict>
                  <key>apiDomain</key>
                  <string>${apiDomain}</string>
                  <key>apiKey</key>
                  <string>${apiKey}</string>
                  <key>addCustomHttpHeader</key>
                  <string>${addCustomHttpHeader}</string>
                </dict>
              </dict>
            </array>
          </dict>
        </dict>
      </dict>
    </array>
  </dict>
</plist>`;
}

function getSafariInstallScriptContent(apiDomain: string, apiKey: string): string {
    return `#!/bin/zsh
username=\`/bin/ls -l /dev/console | /usr/bin/awk '{ print $3 }'\`
cd /Users/$username/Library/Containers/com.apple.Safari/Data/Library/Safari/WebExtensions/

installed=$(plutil -type "com\\.prompt\\.security\\.Safari-Extension\\.Extension (J7M9U73T5B)" Extensions.plist)
if [ $? -eq 0 ]; then
    command="-replace"
else
    command="-insert"
    plutil $command "com\\.prompt\\.security\\.Safari-Extension\\.Extension (J7M9U73T5B)" -dictionary Extensions.plist
fi

plutil $command "com\\.prompt\\.security\\.Safari-Extension\\.Extension (J7M9U73T5B)".AllowInPrivateBrowsing -bool "Yes" Extensions.plist
plutil $command "com\\.prompt\\.security\\.Safari-Extension\\.Extension (J7M9U73T5B)".Enabled -bool "Yes" Extensions.plist
plutil $command "com\\.prompt\\.security\\.Safari-Extension\\.Extension (J7M9U73T5B)".EnabledByUserGesture -bool "Yes" Extensions.plist
plutil $command "com\\.prompt\\.security\\.Safari-Extension\\.Extension (J7M9U73T5B)".GrantedPermissionOrigins -dictionary Extensions.plist
plutil $command "com\\.prompt\\.security\\.Safari-Extension\\.Extension (J7M9U73T5B)".GrantedPermissionOrigins."*://*/*" -date "4001-01-01T00:00:00Z" Extensions.plist


mkdir -p "/Users/$username/Library/Group Containers/J7M9U73T5B.com.prompt.security.safari/Library/Preferences/"
cd "/Users/$username/Library/Group Containers/J7M9U73T5B.com.prompt.security.safari/Library/Preferences/"

if [ ! -f J7M9U73T5B.com.prompt.security.safari.plist ]; then
    plutil -create xml1 J7M9U73T5B.com.prompt.security.safari.plist
    command="-insert"
else
    command="-replace"
fi
plutil $command apiDomain -string "${apiDomain}" J7M9U73T5B.com.prompt.security.safari.plist
plutil $command apiKey -string "${apiKey}" J7M9U73T5B.com.prompt.security.safari.plist
`;
}

export function generateConfig(options: ConfigOptions): ConfigFile | null {
    const { operatingSystem, browser, domain, appId, addCustomHttpHeader } = options;

    try {
        if (browser === 'safari' && operatingSystem === 'mac') {
            return {
                content: getSafariInstallScriptContent(domain, appId),
                filename: 'ps_install_safari_extension.sh'
            };
        } else if (browser === 'opera') {
            return null; // Opera doesn't support managed policies
        } else if (['talon', 'island'].includes(browser)) {
            return {
                content: JSON.stringify({
                    apiDomain: { Value: domain },
                    apiKey: { Value: appId },
                    addCustomHttpHeader: { Value: addCustomHttpHeader },
                }, null, 2),
                filename: 'extension_policy.txt'
            };
        } else if (operatingSystem === 'windows') {
            return {
                content: getWindowsMdmConfig(browser, domain, appId, addCustomHttpHeader),
                filename: 'Prompt_Extension.ps1'
            };
        } else if (operatingSystem === 'mac') {
            return {
                content: getMacMdmConfig(browser, domain, appId, addCustomHttpHeader),
                filename: `Prompt_Extension_${browser}.mobileconfig`
            };
        } else {
            return null; // Unsupported configuration
        }
    } catch (error) {
        console.error('Error generating config:', error);
        return null;
    }
}

export function handleDownload(configFile: ConfigFile | null) {
    if (!configFile) {
        console.error('No configuration file to download');
        return;
    }

    const blob = new Blob([configFile.content], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = configFile.filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}
