import { Grid, Tooltip, FormLabel, TextField, Button, Switch } from "@mui/material";
import { CoachAwareness } from "../../../gql/generated/graphql";

type TextInputDefaultValueProps = {
    value: string | undefined | null;
    title: string;
    help: string | null;
    multiline: boolean;
    disabled: boolean;
    maxLength: number | null;
    defaultValue: string;
    switchValue: string | null;
    handleSwitchChange: ((value: boolean) => void) | null;
    handleOnChange: (value: string) => void;
}

export const TextInputDefaultValue = ({ value, title, help, multiline, disabled, maxLength, defaultValue, switchValue, handleSwitchChange, handleOnChange }: TextInputDefaultValueProps) => {
    const handleDefault = () => {
        handleOnChange(defaultValue);
    }

    if (value === undefined || value === null) {
        value = '';
    }
    const maxLengthProp = maxLength !== null ? maxLength : -1;
    return (
        <Grid container alignItems="center" sx={{ my: -1, width: '100%' }}>
            <Grid item xs={3}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'flex-end' }}>
                    <Tooltip key={title} title={help} placement='top-start'>
                        <FormLabel component="legend"  sx={{ paddingTop: 1 }}>{<span style={{ color: 'black' }}>{title}</span>}</FormLabel>
                    </Tooltip>
                    {switchValue && handleSwitchChange && <Switch size="small" checked={switchValue === CoachAwareness.All} onChange={(event) => handleSwitchChange(event.target.checked)} inputProps={{ 'color': 'secondary' }} />}
                </div>
            </Grid>
            <Grid item container xs={7} sx={{ mx: 13, marginRight: 0 }}>
                <Grid item xs={9}
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '95%' },
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={(event) => event.preventDefault()} 
                    >
                    <TextField
                        id={title}
                        variant="standard"
                        value={value}
                        multiline={multiline}
                        onChange={(event) => handleOnChange(event.target.value)}
                        disabled={disabled || switchValue === CoachAwareness.Nothing}
                        inputProps={{ maxLength: maxLengthProp }}
                    />
                </Grid>
                <Grid item xs sx={{ marginRight: 0, marginTop: 2 }}>
                    <Button onClick={handleDefault} disabled={disabled || value === defaultValue } size="small">
                        Restore To Default
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}