import { css } from "@emotion/react";

export const SquareButtonStyle = {
  self: css`
    border-radius: 5px;
    border: 1px solid var(--color-black-50);
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    max-width: 100%;

    color: var(--color-black-70);
    background: var(--color-transparent);

    & .icon {
      width: 20px;
      height: 20px;
      transition: none;
      fill: var(--color-black-70);
    }

    &:disabled {
      border-color: var(--color-black-50);
      background: var(--color-black-50);
      color: var(--color-black-70);
      & .icon {
        fill: var(--color-black-70);
      }
      cursor: default;
    }

    :not(:disabled) {
      &:hover {
        border-color: var(--color-green);
        background: var(--color-green);
        color: var(--color-white);
        & .icon {
          fill: var(--color-white);
        }
      }

      &:active,
      &.active {
        border-color: #65D5AA;
        background: #65D5AA;
        color: var(--color-white);
        & .icon {
          fill: var(--color-white);
        }
      }
    }
  `,
};
