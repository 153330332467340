/** @jsxImportSource @emotion/react */
import React from 'react';
import { Text } from '../../../../../ui-kit';
import { css } from '@emotion/react';

type IProps = {};

const JumpcloudWindows: React.FC<IProps> = (props) => {
    const { } = props;

    return (
        <div css={css`display: flex; flex-direction: column; gap: 15px;`}>
            <Text>The goal is to run the above powershell script as admin on the endpoint machines of your employees</Text>
            <div css={css`display: flex; gap: 5px;`}>
                <Text>Please follow the instructions</Text>
                <a href='https://jumpcloud.com/support/create-your-own-windows-policy-using-registry-keys' target='_blank' rel="noreferrer"><Text>Create a custom Windows Policy.</Text></a>
            </div>
            <ol css={css`list-style: number; margin-left: 30px; display: flex; flex-direction: column; gap: 10px;`}>
                <li><a href='https://console.jumpcloud.com/login' target='_blank' rel="noreferrer"><Text onClick={() => { }}>Log in to the JumpCloud Admin Portal.</Text></a></li>
                <li><Text>Click "<b>Policies</b>" in the sidebar.</Text></li>
                <li><Text>Click "<b>Add Policy</b>" and select "<b>Windows</b>".</Text></li>
                <li><Text>Choose "PowerShell".</Text></li>
                <li><Text>Name the policy and paste the <b>.ps1</b> script content.</Text></li>
                <li><Text>Select target <b>systems/groups</b>.</Text></li>
                <li><Text>Click "<b>Save</b>".</Text></li>
            </ol>
            <Text>Your script will now run on the assigned systems.</Text>
        </div>
    )
}

export default JumpcloudWindows;