/** @jsxImportSource @emotion/react */
import React from 'react';
import { Text } from '../../../../../ui-kit';
import { css } from '@emotion/react';

type IProps = {};

const MicrosoftGroupPolicyManagement: React.FC<IProps> = (props) => {
    const { } = props;

    return (
        <div css={css`display: flex; flex-direction: column; gap: 15px;`}>
            <Text>The goal is to run the above powershell script as admin on the endpoint machines of your employees</Text>
            <ol css={css`list-style: decimal; margin-left: 30px; display: flex; flex-direction: column; gap: 10px;`}>
                <li>
                    <Text><b>Open Group Policy Management</b></Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>Press <b>Win + R</b>, type <b>gpmc.msc</b>, and press <b>Enter</b>.</Text></li>
                    </ul>
                </li>
                <li>
                    <Text><b>Open Group Policy Management</b></Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>In the left-hand pane, right-click on your desired domain or organizational unit (OU) and select "<b>Create a GPO in this domain, and Link it here</b>" or select an existing GPO and click "<b>Edit</b>".</Text></li>
                    </ul>
                </li>
                <li>
                    <Text><b>Navigate to the Script Settings</b></Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>In the Group Policy Management Editor, go to "<b>Computer Configuration</b>" or "<b>User Configuration</b>" depending on the scope you want.</Text></li>
                        <li><Text>Computer Configuration: {`Policies > Windows Settings > Scripts (Startup/Shutdown)`}</Text></li>
                        <li><Text>User Configuration: {`Policies > Windows Settings > Scripts (Logon/Logoff)`}</Text></li>
                    </ul>
                </li>
                <li>
                    <Text><b>Add the PowerShell Script</b></Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>Select either "<b>Startup</b>" (for computer configuration) or "<b>Logon</b>" (for user configuration).</Text></li>
                        <li><Text>Click "<b>Add...</b>".</Text></li>
                        <li><Text>In the "<b>Script Name</b>" field, click "<b>Browse...</b>" and navigate to the location of your <b>.ps1</b> script. Make sure the script is accessible from the network path (e.g., <b>\domain\sysvol\domain\scripts\</b>).</Text></li>
                    </ul>
                </li>
                <li>
                    <Text><b>Configure the Script Parameters</b></Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>If needed, enter any script parameters in the "Script Parameters" field.</Text></li>
                        <li><Text>Click "<b>OK</b>".</Text></li>
                    </ul>
                </li>
                <li>
                    <Text><b>Apply and Close</b></Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>Click "<b>Apply</b>" and then "<b>OK</b>".</Text></li>
                        <li><Text>Close the Group Policy Management Editor.</Text></li>
                    </ul>
                </li>
                <li>
                    <Text><b>Force Group Policy Update</b></Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>On a target machine, open Command Prompt and run <b>gpupdate /force</b> to apply the new or updated GPO immediately.</Text></li>
                    </ul>
                </li>
            </ol>
            <Text>Your PowerShell script will now be executed based on the configuration (startup/logon) you specified in the GPO.</Text>
        </div>
    )
}

export default MicrosoftGroupPolicyManagement;