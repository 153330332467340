/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const RootStyle = {
  self: (drawerWidth: number) => css`
    overflow: hidden;
    display: grid;
    height: 100vh;
    grid-template-columns: 230px 1fr ${drawerWidth}px;

    &.collapsed {
      grid-template-columns: 70px 1fr ${drawerWidth}px;
    }
    
    transition: grid-template-columns 100ms ease-in-out;
  `,
  outletContainer: css`
    overflow: auto;
    height: 100%;

    /* DO NOT REMOVE: keep for future */
    /* padding: 20px;
        background: var(--color-background);
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 20px; */

    background-color: var(--color-white);
    padding: 20px 25px;

    display: flex;
    flex-direction: column;
    flex: 1;

    &:has(iframe) {
      overflow: hidden;
      padding: 0;
    }

    & iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  `,
  contentContainer: css`
    background: var(--color-white);
    overflow: auto;
    display: flex;
    flex-direction: column;
  `,
  kibanaIframe: css`
    display: none;
  `
};
