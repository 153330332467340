import { FilterInput, InputMaybe } from "../gql/generated/graphql";

export const cleanFilter = (
  filter: InputMaybe<FilterInput>
): FilterInput | undefined => {
  if (!filter) return undefined;
  if (!filter.value) return undefined;
  if (!filter.key) return undefined;

  const value = filter.value.filter((value) => {
    return Object.keys(value).length > 0;
  });

  return {
    key: filter.key,
    value,
  };
};
