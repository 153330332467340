/** @jsxImportSource @emotion/react */
import React from 'react';
import CodeMirror, { EditorView } from '@uiw/react-codemirror';
import { Log } from '../../../gql/generated/graphql';
import { ActivityMonitorJSONViewerStyle } from './ActivityMonitorJSONViewer.css';
import { ContainerWithLogDetails, SystemPrompt } from './ActivityMonitorContent';
import { TTextDiffMode, Text } from '../../../ui-kit';
import { langs } from '@uiw/codemirror-extensions-langs';
import CodeMirrorMerge from 'react-codemirror-merge';


type IGithubCopilot = {
    prompt?: string,
    prefix?: string,
    suffix?: string,
}

export const jsonToGithubCopilot = (anyObj: any): IGithubCopilot | null => {
    if (!anyObj) return null;

    if (!anyObj.hasOwnProperty('prompt') || !anyObj.hasOwnProperty('suffix')) return null;


    const lines = anyObj['prompt'].trim().split('\n');
    const linesNumber = 2;

    if (linesNumber >= lines.length) {
        return {
            prompt: anyObj['prefix'],
            prefix: undefined,
            suffix: anyObj['suffix'],
        }
    }

    return {
        prompt: lines.slice(-linesNumber).join("\n"),
        prefix: lines.slice(0, -linesNumber).join("\n"),
        suffix: anyObj['suffix'],
    }
}


type IProps = {
    githubCopilotObject: IGithubCopilot;
    modifiedGithubCopilotObject: IGithubCopilot;
    logData: Log;
};

const ActivityMonitorGithubCopilot: React.FC<IProps> = (props) => {
    const {
        githubCopilotObject,
        modifiedGithubCopilotObject,
        logData
    } = props;

    const { systemPrompt } = logData;

    const { prefix, prompt, suffix } = githubCopilotObject;
    const { prefix: prefixModified, prompt: promptModified, suffix: suffixModified } = modifiedGithubCopilotObject || {
        prefix: '',
        prompt: '',
        suffix: ''
    };


    return (
        <div css={ActivityMonitorJSONViewerStyle.self}>
            <div>
                <Text textCss={ActivityMonitorJSONViewerStyle.jsonKey} variant='monoSmall'>Prompt:</Text>
                {(promptModified && promptModified !== prompt) ?
                    <CodeDiffViewer code={prompt || ''} modifiedCode={promptModified || ''} logData={logData} />
                    : <CodeViewer code={prompt || ''} logData={logData}
                    />}
            </div>
            {prefix && <div>
                <Text textCss={ActivityMonitorJSONViewerStyle.jsonKey} variant='monoSmall'>Prefix:</Text>
                {(prefixModified && prefixModified !== prefix) ?
                    <CodeDiffViewer code={prefix || ''} modifiedCode={prefixModified || ''} logData={logData} />
                    : <CodeViewer code={prefix || ''} logData={logData}
                    />}
            </div>}
            {suffix && <div>
                <Text textCss={ActivityMonitorJSONViewerStyle.jsonKey} variant='monoSmall'>Suffix:</Text>
                {(suffixModified && suffixModified !== suffix) ?
                    <CodeDiffViewer code={suffix || ''} modifiedCode={suffixModified || ''} logData={logData} />
                    : <CodeViewer code={suffix || ''} logData={logData}
                    />}
            </div>}
            {systemPrompt && <SystemPrompt>{systemPrompt}</SystemPrompt>}
        </div>
    )
}

export default ActivityMonitorGithubCopilot;

type CodeViewerProps = {
    code: string;
    logData: Log;
};
export const CodeViewer: React.FC<CodeViewerProps> = (props) => {
    const { code, logData } = props;
    return (
        <ContainerWithLogDetails logData={logData}>
            <CodeMirror value={code} readOnly theme={'dark'} extensions={[langs.cpp(), langs.csharp(), langs.tsx(), langs.python(), EditorView.lineWrapping]} />
        </ContainerWithLogDetails>
    );
};

type CodeDiffViewerProps = {
    code: string;
    modifiedCode: string;
    logData: Log;
};
export const CodeDiffViewer: React.FC<CodeDiffViewerProps> = (props) => {
    const { code, modifiedCode, logData } = props;

    const [diffMode, setDiffMode] = React.useState<TTextDiffMode>('original');

    const Original = CodeMirrorMerge.Original;
    const Modified = CodeMirrorMerge.Modified;

    return (
        <ContainerWithLogDetails logData={logData} showTextDiff handleChangeDiff={setDiffMode}>
            <CodeMirrorMerge theme={'dark'} highlightChanges={false}
                css={ActivityMonitorJSONViewerStyle.codeViewerDiff(diffMode)}
            >
                <Original value={code} readOnly extensions={[langs.cpp(), langs.csharp(), langs.tsx(), langs.python(), EditorView.lineWrapping]} />
                <Modified value={modifiedCode} readOnly extensions={[langs.cpp(), langs.csharp(), langs.tsx(), langs.python(), EditorView.lineWrapping]} />
            </CodeMirrorMerge>
        </ContainerWithLogDetails>
    );
}