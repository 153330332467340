/** @jsxImportSource @emotion/react */
import React from 'react';
import CodeMirror, { EditorView } from '@uiw/react-codemirror';
import { Log } from '../../../gql/generated/graphql';
import { ActivityMonitorJSONViewerStyle } from './ActivityMonitorJSONViewer.css';
import { ContainerWithLogDetails, SystemPrompt } from './ActivityMonitorContent';
import { TTextDiffMode, Text } from '../../../ui-kit';
import { langs } from '@uiw/codemirror-extensions-langs';
import CodeMirrorMerge from 'react-codemirror-merge';

type IProps = {
    json: string;
    modifiedJson?: string;
    logData: Log;
};

const ActivityMonitorJSONViewer: React.FC<IProps> = (props) => {
    const { json, modifiedJson, logData } = props;
    const { systemPrompt } = logData;


    return (
        <div css={ActivityMonitorJSONViewerStyle.self}>
            <div>
                <Text textCss={ActivityMonitorJSONViewerStyle.jsonKey} variant='monoSmall'>JSON:</Text>
                {modifiedJson && <JsonDiffViewer json={json} modifiedJson={modifiedJson} logData={logData} />}
                {!modifiedJson && <JsonViewer json={json} logData={logData} />}
            </div>
            {systemPrompt && <SystemPrompt>{systemPrompt}</SystemPrompt>}
        </div>
    )
}

export default ActivityMonitorJSONViewer;

type JsonViewerProps = {
    json: string;
    logData: Log;
};
export const JsonViewer: React.FC<JsonViewerProps> = (props) => {
    const { json, logData } = props;
    return (
        <ContainerWithLogDetails logData={logData}>
            <CodeMirror value={JSON.stringify(json, null, 2)} readOnly theme={'dark'} extensions={[langs.json(), EditorView.lineWrapping]} />
        </ContainerWithLogDetails>
    );
};

type JsonDiffViewerProps = {
    json: string;
    modifiedJson: string;
    logData: Log;
};
export const JsonDiffViewer: React.FC<JsonDiffViewerProps> = (props) => {
    const { json, modifiedJson, logData } = props;

    const [diffMode, setDiffMode] = React.useState<TTextDiffMode>('original');

    const Original = CodeMirrorMerge.Original;
    const Modified = CodeMirrorMerge.Modified;

    return (
        <ContainerWithLogDetails logData={logData} showTextDiff handleChangeDiff={setDiffMode}>
            <CodeMirrorMerge theme={'dark'} highlightChanges={false}
                css={ActivityMonitorJSONViewerStyle.codeViewerDiff(diffMode)}
            >
                <Original value={JSON.stringify(json, null, 2)} readOnly extensions={[langs.json(), EditorView.lineWrapping]} />
                <Modified value={JSON.stringify(modifiedJson, null, 2)} readOnly extensions={[langs.json(), EditorView.lineWrapping]} />
            </CodeMirrorMerge>
        </ContainerWithLogDetails>
    );
}