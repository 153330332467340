/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Slider, Switch } from '@mui/material';
import { PSFormControlLabel, PSTransferList, Text } from '../../../../ui-kit';
import { IProtectionFormProps, SUPPORTED_CODE_LANGUAGES, TCodeDetectorProtection } from '../Common';
import { CodeDetectorFormStyle } from './CodeDetectorForm.css';
import { PSListItem } from '../../../../ui-kit/PSTransferList/PSTransferList';

type TSupportedCodeLanguages = keyof typeof SUPPORTED_CODE_LANGUAGES;

const CodeDetectorForm: React.FC<IProtectionFormProps<TCodeDetectorProtection>> = (props) => {
    const { control, formName, protection, isAdvancedMode } = props;
    const { setValue } = useFormContext();

    const [lists, setLists] = useState<Record<string, PSListItem>>({
        options: { label: "Detect Only", items: [], order: 0 },
        allowed: { label: "Allow", items: [], order: 1 },
        denied: { label: "Block", items: [], order: 2 }
    });

    useEffect(() => {
        const currentOptionsWithoutAllowedAndDenied = Object.keys(SUPPORTED_CODE_LANGUAGES).filter(
            x => !protection.allowed.includes(x as any) && !protection.denied.includes(x as any)
        ) as TSupportedCodeLanguages[];

        setLists({
            options: { ...lists.options, items: currentOptionsWithoutAllowedAndDenied },
            allowed: { ...lists.allowed, items: protection.allowed as TSupportedCodeLanguages[] },
            denied: { ...lists.denied, items: protection.denied as TSupportedCodeLanguages[] }
        });
    }, [protection.allowed, protection.denied]);

    const handleListsChange = (updatedLists: Record<string, PSListItem>) => {
        setLists(updatedLists);
        setValue(`${formName}.allowed`, updatedLists.allowed.items, { shouldDirty: true, shouldTouch: true });
        setValue(`${formName}.denied`, updatedLists.denied.items, { shouldDirty: true, shouldTouch: true });
    };

    return (
        <div css={CodeDetectorFormStyle.self}>
            <PSTransferList lists={lists} onChange={handleListsChange} />

            <Controller
                name={`${formName}.indented`}
                defaultValue={protection.indented}
                control={control}
                render={({ field }) => (
                    <PSFormControlLabel
                        {...field}
                        checked={field.value}
                        label="Require code to be indented"
                        control={<Switch />}
                    />
                )}
            />

            {isAdvancedMode && (
                <Controller
                    name={`${formName}.threshold`}
                    defaultValue={protection.threshold}
                    control={control}
                    rules={{
                        min: 0,
                        max: 1,
                    }}
                    render={({ field }) => (
                        <div>
                            <Text>Threshold</Text>
                            <Slider
                                {...field}
                                min={0}
                                max={1}
                                step={0.05}
                                valueLabelDisplay="auto"
                                marks={[
                                    { value: 0, label: '0' },
                                    { value: 0.25, label: '0.25' },
                                    { value: 0.5, label: '0.5' },
                                    { value: 0.75, label: '0.75' },
                                    { value: 1, label: '1' },
                                ]}
                            />
                        </div>
                    )}
                />
            )}
        </div>
    );
};

export default CodeDetectorForm;