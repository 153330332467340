/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const LLMByUsageTooltipStyle = {
  othersContainer: css`
    padding: 0 25px 0 10px;
    display: flex;
    flex-direction: column;
  `,
  othersHeader: css`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  `,
  llmHeaderContainer: css`
    display: flex;
    gap: 5px;
  `,
  llmImage: (imageUrl: string) => css`
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-image: url(${imageUrl});
    background-size: contain;
    object-fit: cover;
  `,
  llmUrl: css`
    margin-top: 10px;
  `,
  detailsContainer: css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  `,
  detailItem: css`
    display: flex;
    & div:first-of-type {
      min-width: 115px;
    }
  `,
};
