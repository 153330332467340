import { css } from "@emotion/react";

export const NavigationMenuStyle = {
  self: css`
    position: relative;
    display: flex;
    flex-direction: column;
    background: var(--color-blue-menu);
    padding-block: var(--spacing-lg);
    padding-left: 0;
    overflow: hidden;

    &.collapsed {
      & .navigation-actions-container {
        align-items: center;
      }
    }
  `,

  collapseButton: (showCollapseButton: boolean, isCollapsed: boolean) => css`
    position: relative;
    display: flex;
    opacity: ${showCollapseButton ? 1 : 0};
    pointer-events: ${showCollapseButton ? 'auto' : 'none'};
    transition: opacity 0.3s ease;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    left: ${isCollapsed ? '69px' : '229px'};
    top: 50%;
    position: absolute;
    background: var(--color-blue-menu);
    transform: translate(-50%, -50%);
    clip-path: inset(0 0 0 52%);
    z-index: 5;
    cursor: pointer;

    & .icon {
      margin-left: 20px;
    }

    &:hover {
      background: var(--color-blue-menu-hover);
      & .icon {
        fill: var(--color-white);
      }
    }
  `,

  promptLogoContainer: css`
    display: flex;
  `,

  navigation: css`
    margin-top: var(--spacing-xxl);
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    gap: 20px;
  `,

  navigationActionsContainer: css`
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
  `,

  userMenuContainer: css`
    margin-top: auto;
    margin-inline: var(--spacing-sm);
    padding-top: var(--spacing-md);
  `,
};