/** @jsxImportSource @emotion/react */
import React from 'react';
import { Action, Log } from '../../../gql/generated/graphql';
import { ActivityMonitorTextStyle } from './ActivityMonitorText.css';
import { PSTextDiff, TTextDiffMode, Text } from '../../../ui-kit';
import { ContainerWithLogDetails, SystemPrompt } from './ActivityMonitorContent';

type IProps = {
    logData: Log;
    logText: string;
};

const ActivityMonitorText: React.FC<IProps> = (props) => {
    const { logData, logText } = props;
    const { systemPrompt, modifiedText } = logData;
    

    return (
        <div css={ActivityMonitorTextStyle.self}>
            <div>
                <Text textCss={ActivityMonitorTextStyle.textKey} variant='monoSmall'>Text:</Text>
                {modifiedText && <TextDiffViewer logData={logData} logText={logText} />}
                {!modifiedText && <TextViewer logData={logData} logText={logText} />}
            </div>
            {systemPrompt && <SystemPrompt>{systemPrompt}</SystemPrompt>}
        </div>
    )
}

export default ActivityMonitorText;


type TextProps = {
    logText: string;
    logData: Log;
}
export const TextViewer: React.FC<TextProps> = (props) => {
    const { logText, logData } = props;
    return (
        <ContainerWithLogDetails logData={logData}>
            <Text pre>{logText}</Text>
        </ContainerWithLogDetails>
    );
};

type TextDiffProps = {
    logText: string;
    logData: Log;
};
export const TextDiffViewer: React.FC<TextDiffProps> = (props) => {
    const { logText, logData } = props;
    const { modifiedText, action } = logData;

    const isBlocked = action === Action.Block;

    const [diffMode, setDiffMode] = React.useState<TTextDiffMode>('original');


    return (
        <ContainerWithLogDetails logData={logData} showTextDiff handleChangeDiff={setDiffMode}>
            <PSTextDiff text={logText} modifiedText={modifiedText!} textMode={diffMode} includeColor={!isBlocked} />
        </ContainerWithLogDetails>
    );
};