import { Box, SxProps, Theme } from "@mui/material";
import { PSButton } from "../ui-kit";

type EditButtonsProps = {
    editMode: boolean;
    sx?: SxProps<Theme>;
    handleSaveClick: () => void;
    handleCancelClick: () => void;
};

export const EditButtons = ({ sx, editMode, handleSaveClick, handleCancelClick }: EditButtonsProps) => {
    return (
        <Box sx={{ ...sx, display: 'flex', gap: '10px', alignItems: 'center' }}>
            <PSButton
                variant="flat"
                disabled={!editMode}
                onClick={handleCancelClick}
            >
                Cancel
            </PSButton>
            <PSButton
                variant="filled"
                disabled={!editMode}
                onClick={handleSaveClick}
            >
                Save
            </PSButton>
        </Box>
    )
};