/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PSLoadingScreenStyle = {
    self: css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    `
}