// TransferList.tsx
/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Button, Checkbox, ListItemButton, ListItemIcon } from '@mui/material';
import { PSTransferListStyle } from './PSTransferList.css';
import Text from '../Text/Text';

export type PSListItem = {
    label: string;
    items: string[];
    order: number;
};

export type PSTransferListProps = {
    lists: Record<string, PSListItem>;
    onChange: (updatedLists: Record<string, PSListItem>) => void;
};

export const PSTransferList: React.FC<PSTransferListProps> = (props) => {
    const { lists, onChange } = props;
    const [checkedItems, setCheckedItems] = useState<Record<string, string[]>>({});

    const handleToggle = (listKey: string, value: string) => {
        setCheckedItems(prev => {
            const currentChecked = prev[listKey] || [];
            const newChecked = currentChecked.includes(value)
                ? currentChecked.filter(item => item !== value)
                : [...currentChecked, value];
            return { ...prev, [listKey]: newChecked };
        });
    };

    const handleHeaderToggle = (listKey: string) => {
        setCheckedItems(prev => {
            const currentChecked = prev[listKey] || [];
            const allItems = lists[listKey].items;
            const newChecked = currentChecked.length === allItems.length ? [] : [...allItems];
            return { ...prev, [listKey]: newChecked };
        });
    };

    const moveItems = (fromKey: string, toKey: string) => {
        const updatedLists = { ...lists };
        const itemsToMove = checkedItems[fromKey] || [];

        updatedLists[fromKey].items = updatedLists[fromKey].items.filter(item => !itemsToMove.includes(item));
        updatedLists[toKey].items = [...updatedLists[toKey].items, ...itemsToMove];

        setCheckedItems(prev => ({ ...prev, [fromKey]: [] }));
        onChange(updatedLists);
    };

    const sortedLists = Object.entries(lists).sort((a, b) => a[1].order - b[1].order);

    return (
        <div css={PSTransferListStyle.transferListContainer}>
            {sortedLists.map(([listKey, list], index) => (
                <React.Fragment key={listKey}>
                    <div css={PSTransferListStyle.transferList}>
                        <div css={PSTransferListStyle.transferListHeader}>
                            <Checkbox
                                size="small"
                                checked={(checkedItems[listKey] || []).length === list.items.length && list.items.length > 0}
                                indeterminate={(checkedItems[listKey] || []).length > 0 && (checkedItems[listKey] || []).length < list.items.length}
                                onChange={() => handleHeaderToggle(listKey)}
                                disabled={list.items.length === 0}
                            />
                            <div css={PSTransferListStyle.transferListHeaderTitle}>
                                <Text variant='bold'>{list.label}</Text>
                                <Text>{(checkedItems[listKey] || []).length}/{list.items.length} selected</Text>
                            </div>
                        </div>
                        <div css={PSTransferListStyle.transferListItems}>
                            {list.items.map(item => (
                                <ListItemButton key={item} dense onClick={() => handleToggle(listKey, item)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            size="small"
                                            tabIndex={-1}
                                            disableRipple
                                            checked={(checkedItems[listKey] || []).includes(item)}
                                        />
                                    </ListItemIcon>
                                    <Text ellipsis>{item}</Text>
                                </ListItemButton>
                            ))}
                        </div>
                    </div>
                    {index < sortedLists.length - 1 && (
                        <div css={PSTransferListStyle.arrowsContainer}>
                            <Button
                                variant="outlined"
                                onClick={() => moveItems(listKey, sortedLists[index + 1][0])}
                                disabled={(checkedItems[listKey] || []).length === 0}
                            >
                                &gt;
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => moveItems(sortedLists[index + 1][0], listKey)}
                                disabled={(checkedItems[sortedLists[index + 1][0]] || []).length === 0}
                            >
                                &lt;
                            </Button>
                        </div>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default PSTransferList;