/** @jsxImportSource @emotion/react */
import React, { Component, ErrorInfo, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';
import { PSErrorBoundaryCatIcon } from '../../assets/images';
import { PSButton, Text } from '../../ui-kit';
import { ErrorBoundaryStyle } from './ErrorBoundary.css';

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    error: Error | null;
    errorInfo: ErrorInfo | null;
    hasError: boolean;
}


class OutletErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ error: error, errorInfo: errorInfo });
    }

    setErrorToFalse = () => {
        this.setState({ hasError: false });
    }

    render() {
        if (this.state.hasError) {
            return <ErrorBoundaryContent setErrorToFalse={this.setErrorToFalse} />;
        }

        return this.props.children;
    }
}

export default OutletErrorBoundary;

type IProps = {
    setErrorToFalse: () => void
}

const ErrorBoundaryContent: React.FC<IProps> = (props) => {
    const { setErrorToFalse } = props;

    const navigate = useNavigate();

    const handleRedirect = () => {
        setErrorToFalse();
        navigate('/');
    }
    
    return (
        <div css={ErrorBoundaryStyle.self}>
            <PSErrorBoundaryCatIcon />
            <Text variant='header2'>Whoops! That Wasn’t Supposed to Happen?</Text>
            <Text>Oops, looks like something went a bit wonky! Don’t worry, it’s definitely not your fault. Just a tiny hiccup on our side. Click the button below to head back home and we’ll get everything sorted out in no time. Thanks for hanging in there!</Text>
            <PSButton css={ErrorBoundaryStyle.goHomeButton} variant='filled' onClick={handleRedirect}>Go Home</PSButton>
        </div>
    )
}