/** @jsxImportSource @emotion/react */
import { FormControl, InputLabel, Select } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PSAutocomplete, PSMenuItem } from '../../../../ui-kit';
import { IProtectionFormProps, TSecretsProtection, REDACT_MODES, SECRET_PLUGINS } from '../Common';
import { SecretsFormStyle } from './SecretsForm.css';

const SecretsForm: React.FC<IProtectionFormProps<TSecretsProtection>> = (props) => {
    const { control, formName, protection } = props;
    const { setValue } = useFormContext();

    const [ignoredRules, setIgnoredRules] = useState(_.without(Object.keys(SECRET_PLUGINS), ...protection.ignored_rules));

    useEffect(() => {
        setIgnoredRules(_.without(Object.keys(SECRET_PLUGINS), ...protection.ignored_rules));
    }, [protection.ignored_rules]);

    const onIgnoredRulesChange = (value: string[]) => {
        setIgnoredRules(value);
        setValue(`${formName}.ignored_rules`, _.difference(Object.keys(SECRET_PLUGINS), value), { shouldDirty: true, shouldTouch: true });
    }

    return (
        <div css={SecretsFormStyle.self}>
            <Controller
                name={`${formName}.redact_mode`}
                control={control}
                defaultValue={protection.redact_mode}
                render={({ field }) => (
                    <FormControl>
                        <InputLabel id="label">Redact Mode</InputLabel>
                        <Select
                            {...field}
                            labelId="label"
                            size='small'
                            label="Redact Mode"
                        >
                            {Object.entries(REDACT_MODES).map(([key, value]) => (
                                <PSMenuItem key={key} value={key} >{value}</PSMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            />

            <PSAutocomplete
                multiple
                includeSelectAll
                value={ignoredRules}
                optionsObject={SECRET_PLUGINS}
                textFieldLabel='Secret Detectors'
                outsideOnChange={(value) => onIgnoredRulesChange(value as string[])}
            />

        </div>
    )
}

export default SecretsForm;