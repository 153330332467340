/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const ActivityMonitorContentStyle = {
  self: css``,
  keyText: css`
    margin-bottom: 10px;
    &:not(:first-of-type) {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid var(--color-black-50);
    }
  `,
  textHeader: css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding-bottom: 10px;
    gap: 10px;
    border-bottom: 1px solid var(--color-black-50);
  `,
  textHeaderDetailsContainer: css`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
  `,
  systemPromptContainer: css`
      padding-top: 10px;
      border-top: 1px solid var(--color-black-50);
    `
};
