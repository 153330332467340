/** @jsxImportSource @emotion/react */
import React from 'react';
import * as MUIIcons from '@mui/icons-material';
import { PSIcons } from '../../assets/icons';
import { IconStyle } from './Icon.css';
import { ColorThemeKeys } from '../../styles';
import { css } from '@emotion/react';

export type MUIIconNames = keyof typeof MUIIcons;
export type PSIconNames = keyof typeof PSIcons;
export type IconNames = MUIIconNames | PSIconNames | '';

export type TIConSize = 'small' | 'medium' | 'large';

type IProps = {
    iconName?: IconNames;
    iconSize?: TIConSize | number;
    className?: string;
    color?: ColorThemeKeys;
};

const Icon: React.FC<IProps> = (props) => {
    const { iconName, className, iconSize = 20, color } = props;

    const colorCss = css`
        color: var(--color-${color});
        fill: var(--color-${color});
    `;

    const IconComponent = React.useMemo(() => {
        if (!iconName) return null;

        if (iconName in MUIIcons) {
            const MuiIcon = MUIIcons[iconName as MUIIconNames];
            return <MuiIcon css={[IconStyle.self, IconStyle.iconSize(iconSize), color && colorCss]} className={`icon ${className ? className : ''}`} />;
        }

        if (iconName in PSIcons) {
            const PSIcon = PSIcons[iconName as PSIconNames];
            return <PSIcon css={[IconStyle.self, IconStyle.iconSize(iconSize), color && colorCss]} className={`icon ${className ? className : ''}`} />;
        }

        return <span>ICON NAME NOT FOUND</span>;

    }, [iconName, iconSize, color, className, colorCss]);

    return IconComponent;
};

export default Icon;
export type { IProps as IconProps };