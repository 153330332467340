import thumbs_up_icon from './icons/thumbs_up_icon.png';
import { classificationInfo, clearDocumentInfoAndGetInfo, handleLogClassificationReport, setClassificationHelpBubble } from './common';

const getTrueClassificationButton = (iframeDocument: Document, buttonId: string) => {
    const button = iframeDocument.createElement(buttonId);
  
    button.id = buttonId;
    button.innerHTML = `<img src=${thumbs_up_icon} width="20" height="17">`;
    button.style.marginTop = '4px';
    button.style.width = '25px';
    button.style.height = '20px';
    return button;
}

const handleTrueClassificationPopup = (iframeDocument: Document, rowIndex: number) => {

    const displayClassificationReport = (popupElement: ParentNode | undefined | null, info: classificationInfo) => {
        let classification: string = '';

        if (info.valid) {
            classification = 'True-negative';
        } else {
            classification = 'True-positive';
        }

        handleLogClassificationReport(iframeDocument, popupElement, info, classification);
    }

    const removeDocumentHeaders = () => {
        let parent;

        const title = iframeDocument.querySelector('div[class="euiFlyoutHeader"]');
        if (title !== null) {
            parent = title.parentNode;
            parent?.removeChild(title);
        }

        const tabs = iframeDocument.querySelector('div[class="euiFlyoutBody__overflow"]');
        if (tabs !== null) {
            parent = tabs.parentNode;
            parent?.removeChild(tabs);
        }

        return parent;
    }
  
    const documentOpenButton = iframeDocument.querySelector(`button[data-test-subj="docTableExpandToggleColumn-${rowIndex}"]`);
    const documentCloseButton = iframeDocument.querySelector(`button[data-test-subj="euiFlyoutCloseButton"]`);
    (documentOpenButton as any).click();

    const info = clearDocumentInfoAndGetInfo(iframeDocument);
    if (info === null) {
        (documentCloseButton as any).click();
    } else {
        const popupElement = removeDocumentHeaders();
        displayClassificationReport(popupElement, info);
    }
}
  
  
export const handleTrueClassification = (iframeDocument: Document, pagesNumber: number, pageIndex: number, rowPageIndex: number, rowIndex: number, cell: Element) => {
    const buttonId = `true_classification_button_${rowPageIndex}_${pageIndex}`;

    for (let i = 1; i <= pagesNumber; i++) {
        if (i !== pageIndex) {
            const oldButton = iframeDocument.getElementById(`true_classification_button_${rowPageIndex}_${i}`);
            if (oldButton) {
                cell.removeChild(oldButton);
            }
        }
    }

    if (!iframeDocument.getElementById(buttonId)) {
        const button = getTrueClassificationButton(iframeDocument, buttonId);
        const helpBubble = iframeDocument.createElement('bubble');
        (cell as any).appendChild(button);

        button.addEventListener('mouseover', function() {
            const buttonRect = button.getBoundingClientRect();
            setClassificationHelpBubble(iframeDocument, buttonRect, helpBubble, 'Report log true classification');
        });

        button.addEventListener('mouseout', function () {
            helpBubble.style.display = 'none';
        });

        button.addEventListener('click', function () {
            handleTrueClassificationPopup(iframeDocument, rowIndex);
        });
    }
}