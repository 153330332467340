/** @jsxImportSource @emotion/react */
import { FormControl, InputLabel, Select, Slider, Switch, TextField } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PSFormControlLabel, PSMenuItem, Text } from '../../../../ui-kit';
import { VENDORS } from '../Common';
import { IProtectionFormProps, TPromptInjectionEngineProtection } from '../Common/protections_types';
import { PromptInjectionEngineFormStyle } from './PromptInjectionEngineForm.css';

const PromptInjectionEngineForm: React.FC<IProtectionFormProps<TPromptInjectionEngineProtection>> = (props) => {
    const { control, formName, protection } = props;
    const { setError, clearErrors, formState, watch } = useFormContext();

    const protectionEnabledStates = watch([
        `${formName}.Prompt Injection Similarity.enabled`,
        `${formName}.Prompt Injection Classifier.enabled`,
        `${formName}.Prompt Injection LLM Judger.enabled`
    ]);

    const isAllInnerProtectionsDisabled = useMemo(() => 
        protectionEnabledStates.every(x => x === false),
        [protectionEnabledStates]
    );

    useEffect(() => {
        if (isAllInnerProtectionsDisabled) {
            setError('Prompt Injection Engine', { type: 'manual', message: 'At least one Prompt Injection must be enabled.' });
        } else {
            clearErrors('Prompt Injection Engine');
        }
    }, [isAllInnerProtectionsDisabled, setError, clearErrors]);

    if (!protection['Prompt Injection Similarity']
        || !protection['Prompt Injection Classifier']
        || !protection['Prompt Injection LLM Judger']) {
        return null;
    }

    return (
        <div css={PromptInjectionEngineFormStyle.self}>
            {formState.errors['Prompt Injection Engine'] && (
                <Text variant='bold' color='red'>{formState.errors['Prompt Injection Engine'].message as string}</Text>
            )}

            <Controller
                name={`${formName}.Prompt Injection Similarity.enabled`}
                defaultValue={protection['Prompt Injection Similarity'].enabled}
                control={control}
                render={({ field }) => (
                    <PSFormControlLabel
                        {...field}
                        checked={field.value}
                        label="Prompt Injection Similarity"
                        control={<Switch />}
                    />
                )}
            />

            {protection['Prompt Injection Similarity'].enabled && (
                <div css={PromptInjectionEngineFormStyle.insideFormContainer}>
                    <Controller
                        name={`${formName}.Prompt Injection Similarity.threshold`}
                        defaultValue={protection['Prompt Injection Similarity'].threshold}
                        control={control}
                        render={({ field }) => (
                            <div>
                                <Text>Threshold</Text>
                                <Slider {...field} min={0} max={1} step={0.05} valueLabelDisplay="auto"
                                    marks={[
                                        { value: 0, label: '0' },
                                        { value: 0.25, label: '0.25' },
                                        { value: 0.5, label: '0.5' },
                                        { value: 0.75, label: '0.75' },
                                        { value: 1, label: '1' },
                                    ]}
                                />
                            </div>
                        )}
                    />

                    <Controller
                        name={`${formName}.Prompt Injection Similarity.top_k`}
                        defaultValue={protection['Prompt Injection Similarity'].top_k}
                        control={control}
                        render={({ field }) => (
                            <div>
                                <Text>Top K</Text>
                                <Slider {...field} min={0} max={30} step={1} valueLabelDisplay="auto"
                                    marks={[
                                        { value: 0, label: '0' },
                                        { value: 5, label: '5' },
                                        { value: 10, label: '10' },
                                        { value: 15, label: '15' },
                                        { value: 20, label: '20' },
                                        { value: 25, label: '25' },
                                        { value: 30, label: '30' },
                                    ]}
                                />
                            </div>
                        )}
                    />
                </div>
            )}

            <Controller
                name={`${formName}.Prompt Injection Classifier.enabled`}
                defaultValue={protection['Prompt Injection Classifier'].enabled}
                control={control}
                render={({ field }) => (
                    <PSFormControlLabel
                        {...field}
                        checked={field.value}
                        label="Prompt Injection Classifier"
                        control={<Switch />}
                    />
                )}
            />

            {protection['Prompt Injection Classifier'].enabled && (
                <div css={PromptInjectionEngineFormStyle.insideFormContainer}>
                    <Controller
                        name={`${formName}.Prompt Injection Classifier.threshold`}
                        defaultValue={protection['Prompt Injection Classifier'].threshold}
                        control={control}
                        render={({ field }) => (
                            <div>
                                <Text>Threshold</Text>
                                <Slider {...field} min={0} max={1} step={0.05} valueLabelDisplay="auto"
                                    marks={[
                                        { value: 0, label: '0' },
                                        { value: 0.25, label: '0.25' },
                                        { value: 0.5, label: '0.5' },
                                        { value: 0.75, label: '0.75' },
                                        { value: 1, label: '1' },
                                    ]}
                                />
                            </div>
                        )}
                    />
                </div>
            )}

            <Controller
                name={`${formName}.Prompt Injection LLM Judger.enabled`}
                defaultValue={protection['Prompt Injection LLM Judger'].enabled}
                control={control}
                render={({ field }) => (
                    <PSFormControlLabel
                        {...field}
                        checked={field.value}
                        label="Prompt Injection LLM Judger"
                        control={<Switch />}
                    />
                )}
            />

            {protection['Prompt Injection LLM Judger'].enabled && (
                <div css={PromptInjectionEngineFormStyle.insideFormContainer}>
                    <Controller
                        name={`${formName}.Prompt Injection LLM Judger.threshold`}
                        defaultValue={protection['Prompt Injection LLM Judger'].threshold}
                        control={control}
                        render={({ field }) => (
                            <div>
                                <Text>Threshold</Text>
                                <Slider {...field} min={0} max={1} step={0.05} valueLabelDisplay="auto"
                                    marks={[
                                        { value: 0, label: '0' },
                                        { value: 0.25, label: '0.25' },
                                        { value: 0.5, label: '0.5' },
                                        { value: 0.75, label: '0.75' },
                                        { value: 1, label: '1' },
                                    ]}
                                />
                            </div>
                        )}
                    />

                    <Controller
                        name={`${formName}.Prompt Injection LLM Judger.vendor`}
                        defaultValue={protection['Prompt Injection LLM Judger'].vendor}
                        control={control}
                        render={({ field }) => (
                            <FormControl>
                                <InputLabel id="label">Vendor</InputLabel>
                                <Select
                                    {...field}
                                    labelId="label"
                                    size='small'
                                    label="Vendor"
                                >
                                    {Object.entries(VENDORS).map(([key, value]) => (
                                        <PSMenuItem key={key} value={key} >{value}</PSMenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />

                    {protection['Prompt Injection LLM Judger'].vendor === 'openai' && (
                        <React.Fragment>
                            <Controller
                                name={`${formName}.Prompt Injection LLM Judger.model`}
                                defaultValue={protection['Prompt Injection LLM Judger'].model}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Model"
                                        size='small'
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />

                            <Controller
                                name={`${formName}.Prompt Injection LLM Judger.llm_api_key`}
                                defaultValue={protection['Prompt Injection LLM Judger'].llm_api_key}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="API Key"
                                        size='small'
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </React.Fragment>
                    )}

                    {protection['Prompt Injection LLM Judger'].vendor === 'azure_openai' && (
                        <React.Fragment>
                            <Controller
                                name={`${formName}.Prompt Injection LLM Judger.model`}
                                defaultValue={protection['Prompt Injection LLM Judger'].model}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Model"
                                        size='small'
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />

                            <Controller
                                name={`${formName}.Prompt Injection LLM Judger.llm_api_key`}
                                defaultValue={protection['Prompt Injection LLM Judger'].llm_api_key}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="API Key"
                                        size='small'
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />

                            <Controller
                                name={`${formName}.Prompt Injection LLM Judger.azure_endpoint`}
                                defaultValue={protection['Prompt Injection LLM Judger'].azure_endpoint}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Azure Endpoint"
                                        size='small'
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </React.Fragment>
                    )}
                </div>
            )}
        </div>
    )
}

export default PromptInjectionEngineForm;