/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useConnectorContext } from '../../../../contexts';
import { PSBox, PSButton, PSCopyText, Text } from '../../../../ui-kit';
import { css } from '@emotion/react';
import { ChromeBrowserIcon, EdgeBrowserIcon, BraveBrowserIcon, VivaldiBrowserIcon, ArcBrowserIcon, FirefoxBrowserIcon, SafariBrowserIcon, TalonBrowserIcon, IslandBrowserIcon, OperaBrowserIcon, WhaleBrowserIcon, ChromiumBrowserIcon } from '../../../../assets/images';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ToggleButtonIcon } from './EmployeesMDM';
import { EmployeesMDMStyle } from './EmployeesMDM.css';
import { censorStringEnd } from '../../../../utils';

type IProps = {};

type Browser = 'chrome' | 'edge' | 'brave' | 'vivaldi' | 'arc' | 'firefox' | 'safari' | 'talon' | 'island' | 'opera' | 'whale' | 'chromium';

interface BrowserInfo {
    label: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    browserStoreLink: string;
}

const BROWSERS: Record<Browser, BrowserInfo> = {
    chrome: { label: 'Chrome', icon: ChromeBrowserIcon, browserStoreLink: 'https://chromewebstore.google.com/detail/prompt-security-browser-e/iidnankcocecmgpcafggbgbmkbcldmno' },
    edge: { label: 'Edge', icon: EdgeBrowserIcon, browserStoreLink: 'https://microsoftedge.microsoft.com/addons/detail/prompt-security-browser-e/pbbbemeeoofiifphflkhmldadohopcki' },
    brave: { label: 'Brave', icon: BraveBrowserIcon, browserStoreLink: 'https://chromewebstore.google.com/detail/prompt-security-browser-e/iidnankcocecmgpcafggbgbmkbcldmno' },
    vivaldi: { label: 'Vivaldi', icon: VivaldiBrowserIcon, browserStoreLink: 'https://chromewebstore.google.com/detail/prompt-security-browser-e/iidnankcocecmgpcafggbgbmkbcldmno' },
    arc: { label: 'Arc', icon: ArcBrowserIcon, browserStoreLink: 'https://chromewebstore.google.com/detail/prompt-security-browser-e/iidnankcocecmgpcafggbgbmkbcldmno' },
    firefox: { label: 'Firefox', icon: FirefoxBrowserIcon, browserStoreLink: 'https://addons.mozilla.org/en-US/firefox/addon/prompt-security-browser-ext/' },
    safari: { label: 'Safari', icon: SafariBrowserIcon, browserStoreLink: 'https://apps.apple.com/us/app/prompt-security-genai-shield/id6479560423?mt=12' },
    whale: { label: 'Whale', icon: WhaleBrowserIcon, browserStoreLink: 'https://chromewebstore.google.com/detail/prompt-security-browser-e/iidnankcocecmgpcafggbgbmkbcldmno' },
    chromium: { label: 'Chromium', icon: ChromiumBrowserIcon, browserStoreLink: 'https://chromewebstore.google.com/detail/prompt-security-browser-e/iidnankcocecmgpcafggbgbmkbcldmno' },
    talon: { label: 'Talon', icon: TalonBrowserIcon, browserStoreLink: 'https://chromewebstore.google.com/detail/prompt-security-browser-e/iidnankcocecmgpcafggbgbmkbcldmno' },
    island: { label: 'Island', icon: IslandBrowserIcon, browserStoreLink: 'https://chromewebstore.google.com/detail/prompt-security-browser-e/iidnankcocecmgpcafggbgbmkbcldmno' },
    opera: { label: 'Opera', icon: OperaBrowserIcon, browserStoreLink: 'https://chromewebstore.google.com/detail/prompt-security-browser-e/iidnankcocecmgpcafggbgbmkbcldmno' },
};


const EmployeesManual: React.FC<IProps> = (props) => {
    const { } = props;
    const { connector } = useConnectorContext();
    const [selectedBrowser, setSelectedBrowser] = useState<Browser>('chrome');

    if (!connector) return null;

    const connectorIdCensored = censorStringEnd(connector.id, 12);

    const handleBrowserChange = (event: React.MouseEvent<HTMLElement>, newBrowser: Browser) => {
        if (newBrowser === null) return;
        setSelectedBrowser(newBrowser);
    }

    return (
        <PSBox padding={30}>
            <Text textCss={css`margin-bottom: 20px;`} variant='header2'>Manual</Text>

            <div css={EmployeesMDMStyle.sectionContainer}>
                <ToggleButtonGroup
                    value={selectedBrowser}
                    onChange={handleBrowserChange}
                    exclusive
                    css={EmployeesMDMStyle.sectionSelectContainer}
                >
                    {Object.entries(BROWSERS).map(([value, browser]) => (
                        <ToggleButton key={value} value={value} css={EmployeesMDMStyle.toggleButton}>
                            <ToggleButtonIcon Icon={browser.icon} label={browser.label} />
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>

                <PSButton variant='filled' onClick={() => { window.open(BROWSERS[selectedBrowser].browserStoreLink, '_blank'); }}>Extension Store</PSButton>
            </div>

            <Text>Enter the API Domain and Key in the extension settings (by clicking on the extension icon):</Text>
            <ul css={css`list-style: disc; margin-left: 30px; display: flex; flex-direction: column; gap: 10px; margin-top: 10px;`}>
                <li css={css`display: flex; align-items: center; gap: 10px;`}>
                    <Text variant='bold'>API Domain</Text>
                    <PSCopyText text={window.location.hostname} />
                </li>
                <li css={css`display: flex; align-items: center; gap: 10px;`}>
                    <Text variant='bold'>API Key</Text>
                    <PSCopyText label={connectorIdCensored} text={connector.id} />
                </li>
            </ul>
        </PSBox>
    )
}

export default EmployeesManual;