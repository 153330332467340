/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import PromptHardeningForm from './PromptHardeningForm';

const PromptHardeningProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues } = props;

    return (
        <ProtectionWrapper
            control={control}
            label="Prompt Hardening"
            getValues={getValues}
            description="Strengthen your system prompt, reduce the risk of prompt injection"
            formName="Prompt Hardening"
            Component={PromptHardeningForm}
            summary={`The "Prompt hardening" protection enhances the security of your homegrown genAI application by augmenting your system prompt with added safeguards, which fortifies it and minimizes the chance of successful prompt injection attempts.`}
        />
    )
}

export default PromptHardeningProtection;