/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const PSPromptContainerStyle = {
  self: css`
    padding: 30px;
    border-radius: 30px;
    background: var(--color-black-10);
  `,
};
