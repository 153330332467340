/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { loadingAnimation } from "../../../styles";

export const PieChartStyle = {
  pieChart: (size: number | string) => css`
    position: relative;
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-rows: minmax(0, 1fr);
    max-height: ${typeof size === "number" ? size + "px" : size};
    aspect-ratio: 1 / 1;

    & svg {
      overflow: visible;
    }

    &.others path[fill="var(--color-black-25)"] {
      cursor: pointer;
    }
  `,
  noData: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  `,
  legendContainer: css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px 30px;
    margin-top: 60px;
  `,
  legendItem: css`
    display: flex;
    align-items: center;
    gap: 5px;
  `,
  loadingContainer: css`
    & div {
      animation: ${loadingAnimation} 500ms infinite alternate;
    }
  `,
  loadingText: css`
    width: 82px;
    height: 10px;
    border-radius: 2px;
  `,
};
