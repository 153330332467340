/** @jsxImportSource @emotion/react */
import React from 'react';
import { PSBox, PSTab, PSTabs } from '../../../ui-kit';
import { css } from "@emotion/react";
import RiskyUsersOrGroups from './RiskyUsersOrGroups';
import { RiskyUsersAndGroupsStyle } from './RiskyUsersAndGroups.css';

type TTabValue = 'users' | 'groups';

type IProps = {

};

const RiskyUsersAndGroups: React.FC<IProps> = (props) => {
    const { } = props;

    const [tabValue, setTabValue] = React.useState<TTabValue>('users');

    return (
        <PSBox boxCss={RiskyUsersAndGroupsStyle.self} padding={"30px 30px 35px 40px"}>
            <PSTabs
                variant='fullWidth'
                indicatorColor="secondary"
                textColor="inherit"
                scrollButtons="auto"
                value={tabValue}
                onChange={(_, newValue) => setTabValue(newValue)}
            >
                <PSTab value={'users'} label="Risky Users" />
                <PSTab value={'groups'} label="Risky Groups" />
            </PSTabs>

            <div css={css`flex: 1;`}>
                <div css={css`display: ${tabValue === 'users' ? 'block' : 'none'}; height: 100%;`}>
                    <RiskyUsersOrGroups type='user' />
                </div>
                <div css={css`display: ${tabValue === 'groups' ? 'block' : 'none'}; height: 100%;`}>
                    <RiskyUsersOrGroups type='group' />
                </div>
            </div>
        </PSBox>
    )
}

export default RiskyUsersAndGroups;