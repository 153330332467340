export const formatTwoDecimalIfCents = (number: number) => {
    return (number % 1 !== 0) ? number.toFixed(2) : number;
}

export const fileSizeFormatter = new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'byte',
    notation: "compact",
    unitDisplay: "narrow",
})
