/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const IsValidStyle = {
  self: css`
    display: flex;
    gap: 10px;
    align-items: center;
  `,
};
