/** @jsxImportSource @emotion/react */
import React from 'react';
import { Risk } from '../../../gql/generated/graphql';
import { RiskBarStyle } from './RiskBar.css';

const riskToColor = (risk: Risk) => {
    if (risk === Risk.Low) return 'var(--color-green)';
    if (risk === Risk.Medium) return 'var(--color-orange)';
    if (risk === Risk.High) return 'var(--color-red)';
    if (risk === Risk.Critical) return 'var(--color-mahogany)';
    return 'var(--color-black-25)';
}

const riskToPercentage = (risk: Risk) => {
    if (risk === Risk.Low) return 0;
    if (risk === Risk.Medium) return 33;
    if (risk === Risk.High) return 66;
    if (risk === Risk.Critical) return 100;
    return 0;
}

type IProps = {
    risk: Risk;
};

const RiskBar: React.FC<IProps> = (props) => {
    const { risk } = props;

    const color = riskToColor(risk);
    const percentage = riskToPercentage(risk);

    return (
        <div css={RiskBarStyle.self}>
            <div css={RiskBarStyle.bar}></div>
            <div css={RiskBarStyle.barThumb(percentage, color)}></div>
        </div>
    )
}

export default RiskBar;