import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { Dashboard, ManagePolicies, ManageApplications, AdvancedRules } from './routes';

import { ManageConfiguration } from './routes/ManageConfiguration';
import { ManageIntegration } from './routes/ManageIntegration';
import { ExtensionPage, Root } from './routes-new';
import AlertsLogsPage from './routes-new/AlertsLogsPage/AlertsLogsPage';
import { Error404 } from './routes-new/ErrorPages';
import { FronteggProvider } from '@frontegg/react';
import RootAuthWrapper from './routes-new/RootAuthWrapper';
import { Developers, DevelopersDeployments, DevelopersPolicies, Employees, EmployeesDeployment, EmployeesExtensionSettings, EmployeesGenAiApplications, EmployeesPolicies, HomegrownApplications, HomegrownApplicationsDeployments, HomegrownApplicationsPolicies } from './routes-new/Manage';
import { useFronteggConfig } from './hooks';
import { PSLoadingScreen } from './ui-kit';

type IProps = {}

const Router: React.FC<IProps> = (props) => {
    const { contextOptions, isLoading } = useFronteggConfig();

    if (isLoading) return <PSLoadingScreen />;

    const router = createBrowserRouter([
        {
            path: '/',
            element: (
                <FronteggProvider
                    contextOptions={contextOptions}
                    hostedLoginBox={true}
                >
                    <RootAuthWrapper>
                        <Root />
                    </RootAuthWrapper>
                </FronteggProvider>
            ),
            errorElement: <Error404 />,
            children: [
                { path: '/', Component: () => <Navigate to="/activity-monitor" relative='path' /> },
                {
                    path: '/appsec-dashboard',
                    element: <Dashboard dashboardType="ps-dash" />,
                    handle: { breadcrumb: "Homegrown Apps Dashboard" }
                },
                {
                    index: true,
                    path: "/activity-monitor",
                    element: <AlertsLogsPage />,
                    handle: { breadcrumb: 'Activity Monitor', showDateRange: true },
                },
                {
                    path: "/extension-new",
                    element: <ExtensionPage />,
                    handle: { breadcrumb: 'Extension New (Beta)', showDateRange: true },
                },
                {
                    path: "/extension",
                    element: <Dashboard dashboardType="ps-dash-extension" />,
                    handle: { breadcrumb: 'Employees Dashboard' },
                },
                {
                    path: "/code-assistant",
                    element: <Dashboard dashboardType="ps-dash-code-assistant" />,
                    handle: { breadcrumb: 'Developers Dashboard' },
                },
                {
                    path: "/alerts",
                    element: <Dashboard dashboardType="ps-alerts" />,
                    handle: { breadcrumb: 'Alerts' },
                },
                {
                    path: "/logs",
                    element: <Dashboard dashboardType="ps-logs" />,
                    handle: { breadcrumb: 'Logs' },
                },
                {
                    path: "/audit",
                    element: <Dashboard dashboardType="ps-audit" />,
                    handle: { breadcrumb: 'Audit', showDateRange: true },
                },
                {
                    path: "/manage/policies",
                    element: <ManagePolicies />,
                    handle: { breadcrumb: 'Manage Policies' },
                },
                {
                    path: "/manage/connectors",
                    element: <ManageApplications />,
                    handle: { breadcrumb: 'Manage Connectors' },
                },
                {
                    path: "/manage/connectors/:applicationName/rules",
                    element: <AdvancedRules />,
                    handle: { breadcrumb: 'Connectors Rules' },
                },

                {
                    path: "/manage/configuration",
                    element: <ManageConfiguration />,
                    handle: { breadcrumb: 'Manage Configuration' },
                },
                {
                    path: "/manage/integrations",
                    element: <ManageIntegration />,
                    handle: { breadcrumb: 'Manage Integrations' },
                },
                {
                    path: "/homegrown-applications/manage/:connectorName",
                    element: <HomegrownApplications />,
                    handle: {
                        breadcrumb: 'Manage Homegrown Applications',
                        shouldShowManageConnector: true,
                        manageConnectorTopBarProps: {
                            connectorFirstUrlPart: 'homegrown-applications',
                            connectorType: 'REGULAR'
                        }
                    },
                    children: [
                        {
                            path: "policies",
                            element: <HomegrownApplicationsPolicies />,
                        },
                        {
                            path: 'deployment',
                            element: <HomegrownApplicationsDeployments />,
                        }
                    ]
                },
                {
                    path: "/employees/manage/:connectorName",
                    element: <Employees />,
                    handle: {
                        breadcrumb: 'Manage Employees',
                        shouldShowManageConnector: true,
                        manageConnectorTopBarProps: {
                            connectorFirstUrlPart: 'employees',
                            connectorType: 'EXTENSION'
                        }
                    },
                    children: [
                        { path: "", Component: () => <Navigate to="policies" /> },
                        {
                            path: "policies",
                            element: <EmployeesPolicies />,
                        },
                        {
                            path: 'deployment',
                            element: <EmployeesDeployment />,
                        },
                        {
                            path: 'extension-settings',
                            element: <EmployeesExtensionSettings />,
                        },
                        {
                            path: 'manage-gen-ai-applications',
                            element: <EmployeesGenAiApplications />,
                        }
                    ]
                },
                {
                    path: "/developers/manage/:connectorName",
                    element: <Developers />,
                    handle: {
                        breadcrumb: 'Manage Developers',
                        shouldShowManageConnector: true,
                        manageConnectorTopBarProps: {
                            connectorFirstUrlPart: 'developers',
                            connectorType: 'CODE_ASSISTANT'
                        }
                    },
                    children: [
                        { path: "", Component: () => <Navigate to="policies" /> },
                        {
                            path: "policies",
                            element: <DevelopersPolicies />,
                        },
                        {
                            path: 'deployment',
                            element: <DevelopersDeployments />,
                        }
                    ]
                },
                {
                    path: "*",
                    element: <Error404 />,
                    handle: { breadcrumb: 'Page Not Found!' },
                }
            ],
        },
    ]);

    return (
        <RouterProvider router={router} />
    );
}

export default Router;