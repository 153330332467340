/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import SensitiveDataForm from './SensitiveDataForm';

const SensitiveDataProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode } = props;

    return (
        <ProtectionWrapper
            label="Sensitive Data"
            description="Prevent sensitive data/PII leakage to comply with regulations"
            formName="Sensitive Data"
            Component={SensitiveDataForm}
            control={control}
            getValues={getValues}
            isAdvancedMode={isAdvancedMode}
            summary={`The "Sensitive Data" protection prevents such data from appearing in your prompts or responses. It maps data types according to various regulations and data categories, such as PCI, GDPR, CCPA, PHI, COPPA, PII, and IT, supporting compliance efforts. You may also add custom data types (e.g. employee wages), which provide powerful guardrails that are personalized to your particular organizational privacy needs.`}
        />
    )
}

export default SensitiveDataProtection;