/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PSCopyTextStyle = {
    self: (maxWidth?: number) => css`
        display: flex;
        align-items: center;
        gap: 5px;
        background: var(--color-white);
        padding: 5px 10px;
        border: 1px solid var(--color-black-10);
        width: fit-content;
        overflow: hidden;
        ${maxWidth ? `max-width: ${maxWidth}px;` : ''}
    `
}