import Kandji from "./Kandji";
import Jamf from "./Jamf";
import JumpCloudMac from "./JumpCloudMac";
import JumpCloudWindows from "./JumpCloudWindows";
import MicrosoftGroupPolicyManagement from "./MicrosoftGroupPolicyManagement";
import MicrosoftIntuneWindows from "./MicrosoftIntuneWindows";
import MicrosoftIntuneMac from "./MicrosoftIntuneMac";
import MicrosoftSCCM from "./MicrosoftSCCM";
import MobileIronMac from "./MobileIronMac";
import MobileIronWindows from "./MobileIronWindows";

export const VendorToComponent = {
    'kandji': <Kandji />,
    'jamf': <Jamf />,
    'jumpcloud-mac': <JumpCloudMac />,
    'jumpcloud-windows': <JumpCloudWindows />,
    'mobileiron-mac': <MobileIronMac />,
    'mobileiron-windows': <MobileIronWindows />,
    'microsoft-intune-mac': <MicrosoftIntuneMac />,
    'microsoft-intune-windows': <MicrosoftIntuneWindows />,
    'microsoft-sccm': <MicrosoftSCCM />,
    'microsoft-group-policy-management': <MicrosoftGroupPolicyManagement />
}