import React, { useState, createContext, useEffect } from 'react';
import { DateTime } from 'luxon';

export type NowContextType = {
    now: DateTime;
}

export const NowContext = createContext<NowContextType | null>(null);

type IProps = {
    children: React.ReactNode;
};

export const NowContextProvider: React.FC<IProps> = (props) => {
    const { children } = props;

    const [now, setNow] = useState(DateTime.now());

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(DateTime.now());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <NowContext.Provider value={{ now }}>
            {children}
        </NowContext.Provider>
    )
}

export const useNowContext = () => {
    const context = React.useContext(NowContext);
    if (!context) {
        throw new Error('useNowContext must be used within a NowContextProvider');
    }
    return context;
}
