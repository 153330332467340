/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionFormProps, TOKEN_LIMITATION_ENCODING_NAMES, TTokenLimitationProtection, tokenLimitToValidNumber } from '../Common';
import { TokenLimitationFormStyle } from './TokenLimitationForm.css';
import { Controller } from 'react-hook-form';
import { FormControl, InputLabel, Select, TextField } from '@mui/material';
import { PSMenuItem } from '../../../../ui-kit';

const TokenLimitationForm: React.FC<IProtectionFormProps<TTokenLimitationProtection>> = (props) => {
    const { control, formName, protection, isAdvancedMode } = props;

    return (
        <div css={TokenLimitationFormStyle.self}>
            <Controller
                name={`${formName}.limit`}
                defaultValue={protection.limit}
                control={control}
                rules={{
                    min: 0,
                    max: 32678,
                }}
                render={({ field }) => (
                    <div>
                        <TextField
                            {...field}
                            size='small'
                            type="number"
                            label="Limit"
                            variant="outlined"
                            fullWidth
                            InputProps={{ inputProps: { min: 0, max: 32678, step: 8 } }}
                            onBlur={event => field.onChange(tokenLimitToValidNumber(event.target.value))}
                        />
                    </div>
                )}
            />

            {isAdvancedMode && <Controller
                name={`${formName}.encoding_name`}
                control={control}
                defaultValue={protection.encoding_name}
                render={({ field }) => (
                    <FormControl>
                        <InputLabel id="label">Encoding Name</InputLabel>
                        <Select
                            {...field}
                            labelId="label"
                            size='small'
                            label="Encoding Name"
                        >
                            {Object.entries(TOKEN_LIMITATION_ENCODING_NAMES).map(([key, value]) => (
                                <PSMenuItem key={key} value={key} >{value}</PSMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            />}
        </div >
    )
}

export default TokenLimitationForm;