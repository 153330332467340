/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const AddCustomApplicationDialogStyle = {
  self: css``,
  dialogContent: css`
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: start;
    align-items: start;
  `,
  dialogAddShouldCreateRule: css`
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  `,
};
