import thumbs_down_icon from './icons/thumbs_down_icon.png';
import { classificationInfo, clearDocumentInfoAndGetInfo, setClassificationHelpBubble, handleLogClassificationReport, displayText } from './common';

const getFalseClassificationButton = (iframeDocument: Document, buttonId: string) => {
    const button = iframeDocument.createElement(buttonId);
  
    button.id = buttonId;
    button.innerHTML = `<img src=${thumbs_down_icon} width="20" height="17">`;
    button.style.marginTop = '4px';
    button.style.width = '25px';
    button.style.height = '20px';
    return button;
}

const sendGenAiClassificationRequest = async (domain: string) => {
    try {
        const request = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                domain
            })
        }
        await fetch('/api/be/report-gen-ai', request);
    } catch (error) {
        console.error('reportGenAi: Error sending data:', error)
    }
}

enum FalseClassificationTypes {
    Logs = 'Log False Classification',
    GenAiApp = 'GenAI Application False Detection',
}

const handleFalseClassificationPopup = (iframeDocument: Document, rowIndex: number) => {

    const handleGenAiAppClassificationReport = (iframeDocument: Document, popupElement: ParentNode | undefined | null, info: classificationInfo) => {
        let parent = iframeDocument.createElement('div');
        parent.id='gen_ai_classification_report';

        displayText(iframeDocument, parent, `Report false detection of the application '${info.appName}' as a GenAI Application`, '20px');
        let submitButton = iframeDocument.createElement('button');
        submitButton.innerHTML = 'Submit';
        submitButton.style.marginTop = '20px';
        submitButton.style.marginLeft = '50px';
        submitButton.style.backgroundColor = 'rgba(200, 200, 200, 0.5)';
        submitButton.style.border = '0.5px solid gray';
        submitButton.style.paddingTop = '2px';
        submitButton.style.paddingBottom = '2px';
        submitButton.style.paddingLeft = '5px';
        submitButton.style.paddingRight = '5px';
        submitButton.style.borderRadius = '4%';
        submitButton.addEventListener('click', function() {
            submitButton.parentNode?.removeChild(submitButton);
            const classificationReport = iframeDocument.getElementById('classification_report');
            classificationReport?.parentNode?.removeChild(classificationReport);
            parent.parentNode?.removeChild(parent);

            const text = `The application '${info.appName}' was successfully reported as a false detection of GenAI Application, and was added to your configuration as a domain to bypass.`;
            sendGenAiClassificationRequest(info.appName).then(() => {
                displayText(iframeDocument, popupElement, text, '20px');
            });
        });
        parent.appendChild(submitButton);
        popupElement?.appendChild(parent);
    }

    const displayClassificationReportType = (iframeDocument: Document, popupElement: ParentNode | undefined | null, info: classificationInfo, classification: string) => {

        let parent = iframeDocument.createElement('div');
        parent.id='classification_report';
        displayText(iframeDocument, parent, 'Choose the type of false classification you want to report', '20px', '600');

        Object.entries(FalseClassificationTypes).forEach(([key, value]) => {
            let radioElement = iframeDocument.createElement('input');
            radioElement.type = 'radio';
            radioElement.name = 'option';
            radioElement.value = key;
            radioElement.style.marginTop = '20px';
            radioElement.style.marginRight = '5px';

            let labelElement = iframeDocument.createElement('label');
            labelElement.textContent = value;
            labelElement.style.marginLeft = '10px';

            let wrapper = iframeDocument.createElement('div');
            wrapper.appendChild(radioElement);
            wrapper.appendChild(labelElement);
            wrapper.style.marginLeft = '50px';

            parent.appendChild(wrapper);

            radioElement.addEventListener('change', function() {
                if (this.checked) {
                    if (value === FalseClassificationTypes.Logs) {
                        const genAiClassificationReport = iframeDocument.getElementById('gen_ai_classification_report');
                        genAiClassificationReport?.parentNode?.removeChild(genAiClassificationReport);
                        handleLogClassificationReport(iframeDocument, popupElement, info, classification);
                    } else if (value === FalseClassificationTypes.GenAiApp) {
                        const logsClassificationReport = iframeDocument.getElementById('logs_classification_report');
                        logsClassificationReport?.parentNode?.removeChild(logsClassificationReport);
                        handleGenAiAppClassificationReport(iframeDocument, popupElement, info);
                    }
                }
            });
        });

        popupElement?.appendChild(parent);
    }

    const displayClassificationReport = (popupElement: ParentNode | undefined | null, info: classificationInfo) => {
        let classification: string = '';

        if (info.valid) {
            classification = 'False-negative';
        } else {
            classification = 'False-positive';
        }

        if (info.isExtension === true && info.appName !== '') {
            displayClassificationReportType(iframeDocument, popupElement, info, classification);
        } else {
            handleLogClassificationReport(iframeDocument, popupElement, info, classification);
        }
    }

    const removeDocumentHeaders = () => {
        let parent;

        const title = iframeDocument.querySelector('div[class="euiFlyoutHeader"]');
        if (title !== null) {
            parent = title.parentNode;
            parent?.removeChild(title);
        }

        const tabs = iframeDocument.querySelector('div[class="euiFlyoutBody__overflow"]');
        if (tabs !== null) {
            parent = tabs.parentNode;
            parent?.removeChild(tabs);
        }

        return parent;
    }
  
    const documentOpenButton = iframeDocument.querySelector(`button[data-test-subj="docTableExpandToggleColumn-${rowIndex}"]`);
    const documentCloseButton = iframeDocument.querySelector(`button[data-test-subj="euiFlyoutCloseButton"]`);
    (documentOpenButton as any).click();

    const info = clearDocumentInfoAndGetInfo(iframeDocument);
    if (info === null) {
        (documentCloseButton as any).click();
    } else {
        const popupElement = removeDocumentHeaders();
        displayClassificationReport(popupElement, info);
    }
}
  
  
export const handleFalseClassification = (iframeDocument: Document, pagesNumber: number, pageIndex: number, rowPageIndex: number, rowIndex: number, cell: Element) => {
    const buttonId = `false_classification_button_${rowPageIndex}_${pageIndex}`;

    for (let i = 1; i <= pagesNumber; i++) {
        if (i !== pageIndex) {
            const oldButton = iframeDocument.getElementById(`false_classification_button_${rowPageIndex}_${i}`);
            if (oldButton) {
                cell.removeChild(oldButton);
            }
        }
    }

    if (!iframeDocument.getElementById(buttonId)) {
        const button = getFalseClassificationButton(iframeDocument, buttonId);
        const helpBubble = iframeDocument.createElement('bubble');
        (cell as any).appendChild(button);

        button.addEventListener('mouseover', function() {
            const buttonRect = button.getBoundingClientRect();
            setClassificationHelpBubble(iframeDocument, buttonRect, helpBubble, 'Report false classification');
        });

        button.addEventListener('mouseout', function () {
            helpBubble.style.display = 'none';
        });

        button.addEventListener('click', function () {
            handleFalseClassificationPopup(iframeDocument, rowIndex);
        });
    }
}