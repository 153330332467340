
export type classificationInfo = {
    promptResponseId: string;
    textType: string;
    valid: boolean;
    isExtension: boolean;
    classificationType: string | null;
    appName: string;
}

export const clearDocumentInfoAndGetInfo = (iframeDocument: Document): classificationInfo | null => {
    let promptResponseId: string | null | undefined;
    let valid: string | null | undefined;
    let textType: string | null | undefined;
    let extension: string | null | undefined;
    let violations: string | null | undefined;
    let appName: string | null | undefined;

    const tableDocViewRows = iframeDocument.querySelectorAll('tr[data-test-subj*="tableDocViewRow"]');
    tableDocViewRows.forEach((row) => {
        const dataTestSubjValue = row.getAttribute('data-test-subj');
        if (dataTestSubjValue === 'tableDocViewRow-Prompt Response ID') {
            promptResponseId = iframeDocument.querySelector('div[data-test-subj="tableDocViewRow-Prompt Response ID-value"]')?.textContent;
        } else if (dataTestSubjValue === 'tableDocViewRow-Valid') {
            valid = iframeDocument.querySelector('div[data-test-subj="tableDocViewRow-Valid-value"]')?.textContent;
        } else if (dataTestSubjValue === 'tableDocViewRow-Text Type') {
            textType = iframeDocument.querySelector('div[data-test-subj="tableDocViewRow-Text Type-value"]')?.textContent;
        } else if (dataTestSubjValue === 'tableDocViewRow-log.extension_data.extensionVersion') {
            extension = iframeDocument.querySelector('div[data-test-subj="tableDocViewRow-log.extension_data.extensionVersion-value"]')?.textContent;
        } else if (dataTestSubjValue === 'tableDocViewRow-Violations') {
            violations = iframeDocument.querySelector('div[data-test-subj="tableDocViewRow-Violations-value"]')?.textContent;
        } else if (dataTestSubjValue === 'tableDocViewRow-App Name') {
            appName = iframeDocument.querySelector('div[data-test-subj="tableDocViewRow-App Name-value"]')?.textContent;
        }
        const parentTable = row.parentNode;
        parentTable?.removeChild(row);
    });

    if (promptResponseId === undefined || promptResponseId === null ||
        valid === undefined || valid === null ||
        textType === undefined || textType === null) {
            console.log("Error getting info");
            return null;
    }

    return {
        promptResponseId,
        valid: valid === 'Valid' ? true : false,
        textType,
        isExtension: (extension !== undefined && extension !== null) ? true : false,
        classificationType: (violations !== undefined && violations !== null && violations !== '') ? violations : null,
        appName: appName || ''
    }
}

const reloadPage = () => {
    window.location.reload();
}

const sendClassificationRequest = async (classification: string, promptResponseId: string, textType: string, classificationType: string | null, flipClassification: string) => {
    const flipClassificationBool = flipClassification === 'Yes' ? true : false;
    try {
        const request = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                classification: classification,
                classificationType: classificationType, 
                promptResponseId: promptResponseId,
                textType: textType,
                flipClassification: flipClassificationBool
            })
        }
        await fetch('/api/be/report-classification', request);
        if (flipClassificationBool === true) {
            setTimeout(reloadPage, 1000);
        }
    } catch (error) {
        console.error('reportClassification: Error sending data:', error)
    }
}

export const displayText = (iframeDocument: Document, popupElement: ParentNode | undefined | null, text: string, fontSize: string, fontWeight: string | null = null) => {
    let textElement = iframeDocument.createElement('p');

    textElement.innerHTML = text;
    textElement.style.marginTop = '35px';
    textElement.style.marginLeft = '50px';
    textElement.style.fontSize = fontSize
    if (fontWeight) {
        textElement.style.fontWeight = fontWeight;
    }
    popupElement?.appendChild(textElement);

    return textElement;
}

export const setClassificationHelpBubble = (iframeDocument: Document, buttonRect: DOMRect, helpBubble: Element, text: string) => {
    helpBubble.textContent = text;
    (helpBubble as any).style.position = 'absolute';
    (helpBubble as any).style.display = 'none';
    (helpBubble as any).style.backgroundColor = '#293847';
    (helpBubble as any).style.color = '#fff';
    (helpBubble as any).style.padding = '13px 13px';
    (helpBubble as any).style.height = '40px';
    (helpBubble as any).style.borderRadius = '4px';
    (helpBubble as any).style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.1)';
    (helpBubble as any).style.fontSize = '14px';
    (helpBubble as any).style.display = 'block';
    (helpBubble as any).style.left = `${buttonRect.left - 15 + window.scrollX}px`;
    (helpBubble as any).style.bottom = `${window.innerHeight - buttonRect.top + 20 + window.scrollY}px`;
  
    const triangle = document.createElement('div');
    triangle.style.position = 'absolute';
    triangle.style.top = '100%';
    triangle.style.left = '15%';
    triangle.style.borderStyle = 'solid';
    triangle.style.borderColor = '#001f3f transparent transparent transparent';
    triangle.style.borderWidth = '7px';
    triangle.style.transform = 'translateX(-50%)';
    helpBubble.appendChild(triangle);
    iframeDocument.body.appendChild(helpBubble);
}

type optionElement = {
    value: string;
    text: string;
}

export const handleLogClassificationReport = (iframeDocument: Document, popupElement: ParentNode | undefined | null, info: classificationInfo, classification: string) => {
    let classificationTypeSelectElement: any = null;
    let parent = iframeDocument.createElement('div');
    parent.id='logs_classification_report';

    const options: Record<string, optionElement> = {
        'code': {value: 'Code Detector', text: 'Code'},
        'secrets': {value: 'Secrets', text: 'Secrets'},
        'sensitiveData': {value: 'Sensitive Data', text: 'Sensitive Data'},
        'toxicity': {value: 'Toxicity', text: 'Toxicity'},  
    }
    if (!info.isExtension) {
        options['promptInjection'] = {value: 'Prompt Injection Classifier', text: 'Prompt Injection'};
    }

    if (classification === 'False-negative') {
        displayText(iframeDocument, parent, 'Have you found a violation in this interaction? If so, select which one', '20px');
        classificationTypeSelectElement = iframeDocument.createElement('select');


        for (let key in options) {
            let optionElement = iframeDocument.createElement('option');
            optionElement.value = options[key].value;
            optionElement.text = options[key].text;
            classificationTypeSelectElement.add(optionElement);
        };

        classificationTypeSelectElement.style.marginTop = '20px';
        classificationTypeSelectElement.style.marginLeft = '50px';
        classificationTypeSelectElement.style.fontSize = '16px';
    
        parent.appendChild(classificationTypeSelectElement);
    }

    if (classification === 'False-negative' || classification === 'False-positive') {
        let text: string = '';
        if (classification === 'False-negative') {
            text = 'Do you want to change the classification for this log and create an alert for it?';
        } else {
            text = 'Do you want to change the classification for this log and dismiss this alert?';
        }
        displayText(iframeDocument, parent, text, '20px');
        
        let flipClassificationElement = iframeDocument.createElement('select');
        let yes = iframeDocument.createElement('option');
        yes.value = 'Yes';
        yes.text = 'Yes';
        let no = iframeDocument.createElement('option');
        no.value = 'No';
        no.text = 'No';
        flipClassificationElement.add(no);
        flipClassificationElement.add(yes);

        flipClassificationElement.style.marginTop = '20px';
        flipClassificationElement.style.marginLeft = '50px';
        flipClassificationElement.style.fontSize = '16px';

        parent.appendChild(flipClassificationElement);

        displayText(iframeDocument, parent, ``, '20px');
        let submitButton = iframeDocument.createElement('button');
        submitButton.innerHTML = 'Submit';
        submitButton.style.marginTop = '20px';
        submitButton.style.marginLeft = '50px';
        submitButton.style.backgroundColor = 'rgba(200, 200, 200, 0.5)';
        submitButton.style.border = '0.5px solid gray';
        submitButton.style.paddingTop = '2px';
        submitButton.style.paddingBottom = '2px';
        submitButton.style.paddingLeft = '5px';
        submitButton.style.paddingRight = '5px';
        submitButton.style.borderRadius = '4%';
        submitButton.addEventListener('click', function() {
            submitButton.parentNode?.removeChild(submitButton);
            parent.parentNode?.removeChild(parent);
            const classificationReport = iframeDocument.getElementById('classification_report');
            classificationReport?.parentNode?.removeChild(classificationReport);

            const text = classificationTypeSelectElement !== null ? `${classification} classification of type "${classificationTypeSelectElement.options[classificationTypeSelectElement.selectedIndex].text}" was successfully reported` : `${classification} classification of the log was successfully reported`;
            const classificationType = classificationTypeSelectElement !== null ? classificationTypeSelectElement.value : info.classificationType;
            sendClassificationRequest(classification, info.promptResponseId, info.textType, classificationType, flipClassificationElement.value).then(() => {
                displayText(iframeDocument, popupElement, text, '20px');
            });
        });
        parent.appendChild(submitButton);
        popupElement?.appendChild(parent);
    } else {
        sendClassificationRequest(classification, info.promptResponseId, info.textType, info.classificationType, 'No').then(() => {
            displayText(iframeDocument, popupElement, `${classification} classification of the log was successfully reported`, '20px');
        });
    }
}