/** @jsxImportSource @emotion/react */
import { Select, IconButton, Skeleton } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApplicationTypes } from '../../../gql/generated/graphql';
import { useGraphQL, useGraphQLMutation } from '../../../hooks';
import { Icon, PSMenu, PSMenuItem } from '../../../ui-kit';
import { IconNames } from '../../../ui-kit/Icon/Icon';
import { getConnectorsByType } from '../ConnectorsCommon';
import ManageConnectorTopBarCreateDialog from './ManageConnectorTopBarCreateDialog';
import ManageConnectorTopBarDeleteDialog from './ManageConnectorTopBarDeleteDialog';
import ManageConnectorTopBarRenameDialog from './ManageConnectorTopBarRenameDialog';
import { ManageConnectorTopBarStyle } from './ManageConnectorTopBar.css';
import { createConnectorAndPolicy, deleteConnectorByName, renameConnectorByName } from './ManageConnectorTopBar.queries';

type TConnectorDialogTypes = 'create' | 'rename' | 'delete';
type TConnectorMenuItem = {
    label: string;
    iconName: IconNames;
    exclude?: boolean;
    action: () => void;
}

const ApplicationTypesTranslate: {
    [key in ApplicationTypes]: string;
} = {
    CODE_ASSISTANT: 'Developers',
    EXTENSION: 'Employees',
    REGULAR: 'Homegrown applications',
    SHADOW: 'SHADOW'
}

type IProps = {
    connectorFirstUrlPart: string;
    connectorType: ApplicationTypes;
};

const ManageConnectorTopBarTopBar: React.FC<IProps> = (props) => {
    const { connectorFirstUrlPart, connectorType } = props;

    const [searchParams] = useSearchParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const [selectedConnectorName, setSelectedConnectorName] = useState<string>('');
    const [settingsAnchorEl, setSettingsAnchorEl] = useState<null | HTMLElement>(null);
    const [currentOpenDialog, setCurrentOpenDialog] = useState<TConnectorDialogTypes | null>(null);

    const closeDialog = () => setCurrentOpenDialog(null);

    const openDialog = (dialogType: TConnectorDialogTypes) => {
        setCurrentOpenDialog(dialogType)
        setSettingsAnchorEl(null);
    };

    const lastPathname = pathname.split('/').pop();

    const switchToConnector = async (connectorName: string, shouldKeepSearchParams: boolean = false) => {
        setSelectedConnectorName(connectorName);
        navigate({
            pathname: `/${connectorFirstUrlPart}/manage/${connectorName}/${lastPathname === ':connectorName' ? 'policies' : lastPathname}`,
            search: shouldKeepSearchParams ? searchParams.toString() : undefined,
            hash: window.location.hash
        });
    }

    const connectors = useGraphQL({
        document: getConnectorsByType,
        variables: {
            connectorTypes: [connectorType]
        },
        onSuccess: (data) => {
            if (data.applications.length === 0) throw new Error('No connectors found');
            const paramsConnectorName = params.connectorName as string;
            const firstConnector = data.applications[0];

            if (!paramsConnectorName) {
                switchToConnector(firstConnector.name)
                return;
            };
            if (paramsConnectorName === ':connectorName') {
                switchToConnector(firstConnector.name);
                return;
            }

            const connector = data.applications.find(connector => connector.name === paramsConnectorName);
            if (!connector) {
                switchToConnector(firstConnector.name);
                toast.error('Connector not found, redirecting to the first connector');
                return;
            }

            switchToConnector(paramsConnectorName, true);
        },
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (params.connectorName !== ':connectorName') return;
        connectors.refetch();
    }, [connectors, params.connectorName])

    const selectedConnector = connectors.data?.applications?.find(connector => connector.name === selectedConnectorName)!;

    const goToAdvancedRulesPage = useCallback(() => {
        navigate(`/manage/connectors/${selectedConnector.name}/rules`);
    }, [selectedConnector, navigate])

    const createConnector = useGraphQLMutation({
        document: createConnectorAndPolicy,
        onSuccess: async (data) => {
            await connectors.refetch();
            toast.success('Connector created successfully');
            switchToConnector(data.createApplicationAndRuleAndPolicy.name)
        },
    })

    const deleteConnector = useGraphQLMutation({
        document: deleteConnectorByName,
        onSuccess: async () => {
            toast.success('Connector deleted successfully');
            const nextConnectorName = connectors.data?.applications.filter(connector => connector.name !== selectedConnectorName)[0].name;
            switchToConnector(nextConnectorName || '');
            setTimeout(() => connectors.refetch(), 100)
        }
    })

    const renameConnector = useGraphQLMutation({
        document: renameConnectorByName,
        onSuccess: async (data) => {
            toast.success('Connector renamed successfully');
            switchToConnector(data.updateApplication.name);
            setTimeout(() => connectors.refetch(), 100)
        }
    })

    const ManageConnectorTopBarSettingsMenuItems: Array<TConnectorMenuItem> = useMemo(() => [
        {
            label: `Create ${ApplicationTypesTranslate[connectorType].toLowerCase()} connector`,
            iconName: 'AddRounded',
            action: () => openDialog('create'),
            exclude: connectorType !== ApplicationTypes.Regular
        },
        {
            label: 'Rename',
            iconName: 'EditRounded',
            action: () => openDialog('rename'),
            exclude: connectorType !== ApplicationTypes.Regular
        },
        {
            label: 'Delete',
            iconName: 'DeleteRounded',
            action: () => openDialog('delete'),
            exclude: connectors?.data?.applications?.length! <= 1
        },
        {
            label: 'Edit advanced rules',
            iconName: 'TuneRounded',
            action: goToAdvancedRulesPage,
        }
    ], [connectorType, connectors?.data?.applications?.length, goToAdvancedRulesPage])

    if (connectors.isLoading || connectors.isRefetching) return <div css={ManageConnectorTopBarStyle.self}>
        <Skeleton variant='rounded' width={200} height={30} />
        <Skeleton variant='circular' width={40} height={40} />
    </div>

    return (
        <div css={ManageConnectorTopBarStyle.self}>
            <Select
                size='small'
                value={selectedConnectorName}
                onChange={(e) => switchToConnector(e.target.value)}
                disabled={connectors?.data?.applications?.length! <= 1}
            >
                {connectors.data?.applications.map((connector) => (
                    <PSMenuItem
                        key={connector.id}
                        value={connector.name}
                    >
                        {connector.name}
                    </PSMenuItem>
                ))}
            </Select>

            {connectorType !==  ApplicationTypes.Extension &&  <IconButton onClick={(e) => setSettingsAnchorEl(e.currentTarget)}>
                <Icon iconName='SettingsOutlined' iconSize={24} />
            </IconButton>}

            <PSMenu
                open={Boolean(settingsAnchorEl)}
                onClose={() => setSettingsAnchorEl(null)}
                anchorEl={settingsAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{ marginTop: '10px' }}
            >

                {ManageConnectorTopBarSettingsMenuItems.map((item) => {
                    if (item.exclude) return null
                    return <PSMenuItem key={item.label} type='custom' iconName={item.iconName} onClick={item.action} >{item.label}</PSMenuItem>
                })}

            </PSMenu>

            {currentOpenDialog === 'create' && <ManageConnectorTopBarCreateDialog
                open={currentOpenDialog === 'create'}
                onClose={closeDialog}
                onCreate={(name) => createConnector.mutateAsync({ name, type: ApplicationTypes.Regular })}
                connectorTypeName={ApplicationTypesTranslate[connectorType]}
            />}
            {currentOpenDialog === 'rename' && <ManageConnectorTopBarRenameDialog
                open={currentOpenDialog === 'rename'}
                onClose={closeDialog}
                onRename={(name) => renameConnector.mutateAsync({ oldName: selectedConnector.name, newName: name })}
                currentName={selectedConnector.name}
                connectorTypeName={ApplicationTypesTranslate[connectorType]}
            />}
            {currentOpenDialog === 'delete' && <ManageConnectorTopBarDeleteDialog
                open={currentOpenDialog === 'delete'}
                onClose={closeDialog}
                onDelete={() => deleteConnector.mutateAsync({ name: selectedConnector.name })}
                connectorTypeName={ApplicationTypesTranslate[connectorType]}
            />}
        </div>
    )
}

export default ManageConnectorTopBarTopBar;