/** @jsxImportSource @emotion/react */
import React from 'react';
import { ActivityMonitorConversationStyle } from './ActivityMonitorConversation.css';
import { isObjectsArray } from '../../../utils';
import { Icon, PSTextDiff, TTextDiffMode, Text, Tooltip } from '../../../ui-kit';
import { ContainerWithLogDetails, SystemPrompt } from './ActivityMonitorContent';
import { Log } from '../../../gql/generated/graphql';

type TMessage = { role: TRoles, content: string|object[] };

export type TRoles = 'assistant' | 'user' | 'system';
type TConversation = { role: TRoles, content: string[] }[];


export const jsonToConversation = (anyObj: unknown): TConversation => {
    if (!anyObj) return [];
    if (!isObjectsArray(anyObj)) return [];

    const isObjectValid = anyObj.every((obj: any): obj is TMessage => {
        return obj && typeof obj === 'object' && 'role' in obj && 'content' in obj;
    });

    if (!isObjectValid) return [];

    const messages: TMessage[] = anyObj.map((message: any) => ({ role: message.role,  content: message.content })); 
    const sections: { role: TRoles, content: string[] }[] = [];

    let currentSection: { role: TRoles, content: string[] } | null = null;

    messages.forEach((message) => {
        let texts: string[] = [];
        if (typeof message.content === 'string') {
            texts = [message.content];
        } else {
            texts = message.content.map((entry: any) => entry.text);
        }
        if (!currentSection || currentSection.role !== message.role) {
            if (currentSection) {
                sections.push(currentSection);
            }
            currentSection = { role: message.role, content: texts };
        } else {
            currentSection.content.push(...texts);
        }
    });

    if (currentSection) {
        sections.push(currentSection);
    }

    return sections;
};

type IProps = {
    conversation: TConversation;
    conversationModified?: TConversation;
    logData: Log;
};

const ActivityMonitorConversation: React.FC<IProps> = (props) => {
    const { conversation, conversationModified, logData } = props;

    const systemPrompt = conversation.find(section => section.role === 'system')?.content[0] || '';

    return (
        <div css={ActivityMonitorConversationStyle.self}>
            <div>
                <Text textCss={ActivityMonitorConversationStyle.conversationKey} variant='monoSmall'>Conversation:</Text>
                {conversationModified && conversationModified.length > 0
                    ? <ConversationWithDiff conversation={conversation} conversationModified={conversationModified!} logData={logData} />
                    : <Conversation conversation={conversation} logData={logData} />
                }
            </div>
            {systemPrompt && <SystemPrompt>{systemPrompt}</SystemPrompt>}
        </div>
    )
}

export default ActivityMonitorConversation;


type IConversationProps = {
    conversation: TConversation;
    logData: Log;
}
const Conversation: React.FC<IConversationProps> = (props) => {
    const { logData, conversation } = props;

    return (
        <ContainerWithLogDetails logData={logData}>
            <div css={ActivityMonitorConversationStyle.conversationContainer}>
                {conversation.map((section, index) => {
                    const { role, content } = section;
                    if (role === 'system') return null;

                    return (
                        <div key={index} css={ActivityMonitorConversationStyle.section(role)}>
                            <Tooltip title={role === 'assistant' ? 'Assistant' : 'User'} placement='top'>
                                <div css={ActivityMonitorConversationStyle.role}>
                                    <Icon iconName={role === 'assistant' ? 'AutoAwesomeRounded' : 'PersonRounded'} color='black-70' />
                                </div>
                            </Tooltip>
                            <div css={ActivityMonitorConversationStyle.messagesContainer(role)}>
                                {content.map((content, index) => (
                                    <Text pre key={index} textCss={ActivityMonitorConversationStyle.messageBubble(role)}>{content}</Text>
                                ))}
                            </div>
                        </div>
                    )
                })}
            </div>
        </ContainerWithLogDetails>
    )
}


type IConversationWithDiffProps = {
    logData: Log;
    conversation: TConversation;
    conversationModified: TConversation;
}
const ConversationWithDiff: React.FC<IConversationWithDiffProps> = (props) => {
    const { logData, conversation, conversationModified } = props;

    const [diffMode, setDiffMode] = React.useState<TTextDiffMode>('original');

    return (
        <ContainerWithLogDetails logData={logData} showTextDiff handleChangeDiff={setDiffMode}>
            <div css={ActivityMonitorConversationStyle.conversationContainer}>
                {conversation.map((section, index) => {
                    const { role, content } = section;
                    if (role === 'system') return null;

                    return (
                        <div key={index} css={ActivityMonitorConversationStyle.section(role)}>
                            <Tooltip title={role === 'assistant' ? 'Assistant' : 'User'} placement='top'>
                                <div css={ActivityMonitorConversationStyle.role}>
                                    <Icon iconName={role === 'assistant' ? 'AutoAwesomeRounded' : 'PersonRounded'} color='black-70' />
                                </div>
                            </Tooltip>
                            <div css={ActivityMonitorConversationStyle.messagesContainer(role)}>
                                {content.map((content, contentIndex) => (
                                    <div key={index} css={ActivityMonitorConversationStyle.messageBubble(role)}>
                                        <PSTextDiff
                                            text={content || ''}
                                            modifiedText={conversationModified[index]?.content?.[contentIndex] || ''}
                                            textMode={diffMode}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                })}
            </div>
        </ContainerWithLogDetails >
    )
}
