/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Risk, RiskyGroups, RiskyUser } from '../../../gql/generated/graphql';
import { Chips, Text } from '../../../ui-kit';
import { emailToInitials, nameToUpperCase } from '../../../utils';
import RiskBar from './RiskBar';
import { Skeleton } from '@mui/material';
import { RiskyCardStyle } from './RiskyCard.css';
import clsx from 'clsx';

type IProps = ({
    type: 'user'
    user?: RiskyUser;
} | {
    type: 'group'
    group?: RiskyGroups;
}) & {
    isLoading?: boolean;
    onClick?: () => void;
}

const riskToColor = (risk: Risk) => {
    if (risk === Risk.Low) return 'green';
    if (risk === Risk.Medium) return 'orange';
    if (risk === Risk.High) return 'red';
    if (risk === Risk.Critical) return 'mahogany';
    return 'black';
}

const RiskyCard: React.FC<IProps> = (props) => {
    const { type, isLoading, onClick } = props;


    if (isLoading) {
        return (
            <div css={RiskyCardStyle.self()} className={clsx({ loading: isLoading })}>
                <Skeleton animation='wave' variant='text' width='70%' />
                <Skeleton animation='wave' variant='text' width='50%' />
                <Skeleton animation='wave' variant='text' width='85%' />
                <Skeleton animation='wave' variant='text' width='70%' css={css`margin-top: auto;`} />
                <Skeleton animation='wave' variant='text' width='100%' />
            </div>
        )
    }

    if (type === 'group') {
        const { group } = props;
        if (!group) return null;
        const { groupName, usersCount, risk, violationsCount } = group;
        const violationCountLabel = violationsCount === 1 ? 'Alert' : 'Alerts';
        const usersCountLabel = usersCount === 1 ? 'Employee' : 'Employees';

        return (
            <div css={RiskyCardStyle.self(riskToColor(risk))} onClick={onClick}>
                <Text variant='bold' color='black-70' ellipsis>{groupName}</Text>
                <Text color='black-70' ellipsis>{usersCount} {usersCountLabel}</Text>

                <Text variant='bold' css={css`margin-top: auto;`} color='black-70'>{violationsCount} {violationCountLabel}</Text>

                <div css={css`display: flex; align-items: center; gap: 8px;`}>
                    <Text color='black-50' variant='monoSmall'>RISK</Text>
                    <RiskBar risk={risk} />
                </div>

            </div>
        )
    }

    const { user } = props;
    if (!user) return null;
    const { userEmail, userGroups, violationsCount, risk } = user;
    const name = nameToUpperCase(emailToInitials(userEmail || ''))
    const violationCountLabel = violationsCount === 1 ? 'Alert' : 'Alerts';;

    return (
        <div css={RiskyCardStyle.self(riskToColor(risk))} onClick={onClick}>
            <Text variant='bold' color='black-70' ellipsis>{name}</Text>
            <div css={RiskyCardStyle.chipsContainer}>
                <Chips value={userGroups} />
            </div>
            <Text color='black-70' ellipsis>{userEmail?.toLocaleLowerCase()}</Text>

            <Text variant='bold' css={css`margin-top: auto;`} color='black-70'>{violationsCount} {violationCountLabel}</Text>

            <div css={css`display: flex; align-items: center; gap: 8px;`}>
                <Text color='black-50' variant='monoSmall'>RISK</Text>
                <RiskBar risk={risk} />
            </div>

        </div>
    )
}

export default RiskyCard;