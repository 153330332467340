/** @jsxImportSource @emotion/react */
import React from 'react';
import { PSPromptContainerStyle } from './PSPromptContainer.css';

type IProps = {
    children?: React.ReactNode;
};

const PSPromptContainer: React.FC<IProps> = (props) => {
    const { children } = props;

    return (
        <div css={PSPromptContainerStyle.self}>
            {children}
        </div>
    )
}

export default PSPromptContainer;