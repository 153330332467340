/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import DataPrivacyGuidelinesForm from './DataPrivacyGuidelinesForm';

const DataPrivacyGuidelinesProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode, connectorType } = props;

    if (connectorType === 'developers' && !isAdvancedMode) return null;

    return (
        <ProtectionWrapper
            label="Data Privacy Guidelines"
            description="Protect your app with custom or industry-specific guidelines"
            formName="Data Privacy Guidelines"
            Component={DataPrivacyGuidelinesForm}
            control={control}
            getValues={getValues}
            isAdvancedMode={isAdvancedMode}
            summary={`Data Privacy Guidelines are designed for enforcing data privacy rules and moderate content in genAI applications. It provides the ability to block unapproved content types and offers customization options for defining what should not be allowed. This genAI-driven feature allows users to add custom guidelines, ensuring flexibility in tailoring the protection to specific content elements that they do not want in their application. While there might be a slight delay in processing due to the guidelines added, this delay is a small price for the enhanced control and protection it provides to the users.`}
        />
    )
}

export default DataPrivacyGuidelinesProtection;