import React from 'react';

import {
    DndContext,
    closestCenter,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext
} from '@dnd-kit/sortable';
import { DraggableWrapper } from './DraggableWrapper';
import { ApplicationTypes, Rule } from '../../gql/generated/graphql';
import { SingleRuleRegular } from './SingleRules/singleRuleRegular';
import { TRule } from '.';
import { SingleRuleBrowserExtension } from './SingleRules/singleRuleBrowserExtension';

type DraggableRulesProps = {
    rules: TRule[],
    updateRules: React.Dispatch<React.SetStateAction<TRule[] | undefined>>
    applicationType: ApplicationTypes;
}

export const DraggableRules = ({ rules, updateRules, applicationType }: DraggableRulesProps) => {

    const localRulesIndexesForDND = rules.map(x => x.id);

    const sensors = useSensors(
        useSensor(PointerSensor)
    );

    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = rules.findIndex(x => x.id === active.id);
            const newIndex = rules.findIndex(x => x.id === over.id);
            const changedRules = arrayMove(rules, oldIndex, newIndex);
            updateRules(changedRules);
        }
    };

    const updateRule = (id: string) => (rule: Rule) => {
        const changedRules = rules.map(x => x.id === id ? rule : x);
        updateRules(changedRules);
    };

    const deleteRule = (id: string) => () => {
        const changedRules = rules.filter(x => x.id !== id);
        updateRules(changedRules);
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={localRulesIndexesForDND}
            >
                {
                    [...rules].reverse().map(localRule =>
                        <DraggableWrapper
                            key={localRule.id}
                            id={localRule.id}
                            isNew={localRule.isNew}
                        >
                            {applicationType !== ApplicationTypes.Extension && <SingleRuleRegular key={localRule.id} rule={localRule} updateRule={updateRule(localRule.id)} deleteRule={deleteRule(localRule.id)} applicationType={applicationType}/>}
                            {applicationType === ApplicationTypes.Extension && <SingleRuleBrowserExtension key={localRule.id} rule={localRule} updateRule={updateRule(localRule.id)} deleteRule={deleteRule(localRule.id)}/>}
                        </DraggableWrapper>)
                }
            </SortableContext>
        </DndContext>
    );
}