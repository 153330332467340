/** @jsxImportSource @emotion/react */
import React from 'react';
import { CustomCellRendererProps } from 'ag-grid-react';
import Text from '../../../Text/Text';
import { CountryStyle } from './Country.css';

type IProps = {

} & Partial<CustomCellRendererProps>;

const Country: React.FC<IProps> = (props) => {
    const { value } = props;

    if (!value) return null;

    return (
        <div css={CountryStyle.self}>
            <img height={20} src={`https://flagcdn.com/${value?.toLowerCase()}.svg`} alt={value} />
            <Text>{value}</Text>
        </div>
    )
}

export default Country;