/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const PSMenuItemStyle = {
  self: css`
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px 15px;

    &.Mui-selected {
      background-color: var(--color-transparent);
    }
  `,
  text: css`
    display: flex;
    align-items: center;
    gap: 10px;
  `,
};
