/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { TextField } from '@mui/material';
import { css } from '@emotion/react';
import { Text } from '../../ui-kit';
import { Controller, useForm } from 'react-hook-form';

type IProps = {
    onFormStateChange: (isValid: boolean) => void;
    setTextCacheMap: React.Dispatch<React.SetStateAction<Map<string, string>>>;
    setSubmitForm: Function;
    promptResponseId: string;
    textType: 'response' | 'prompt';
    rowIndex: number;
};

type TInputs = {
    reason: string;
}

const ViewHiddenText: React.FC<IProps> = (props) => {
    const { onFormStateChange, setTextCacheMap: setPromptMap, setSubmitForm, promptResponseId, textType } = props;

    const { control, handleSubmit, formState } = useForm<TInputs>({
        defaultValues: {
            reason: ''
        }
    })

    useEffect(() => {
        const submit = async (data: TInputs) => {
            const text = await fetchHiddenText(data.reason);
            setPromptMap(prev => {
                return new Map(prev.set(`${promptResponseId}-${textType}`, text));
            })
        }

        setSubmitForm(() => handleSubmit(submit))
    }, [])

    useEffect(() => {
        onFormStateChange(formState.isValid);
    }, [formState.isValid, onFormStateChange]);

    const fetchHiddenText = async (reason: string) => {
        const response = await fetch(`/api/be/get-log-text?promptResponseId=${promptResponseId}&textType=${textType}&reason=${reason}`);

        if (!response.ok) {
            throw new Error('Error fetching text');
        }

        const logText = await response.text();
        return logText;
    }

    return (
        <React.Fragment>
            <Text color="black-70">Let the admin know why you wish to view the chat</Text>
            <Controller
                name="reason"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                    <TextField
                        css={css`
                            margin-top: 20px;
                        `}
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        placeholder='Type your reason here...'
                        {...field}
                    />
                )}
            />
        </React.Fragment>
    )
}

export default ViewHiddenText;