/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const RiskyCardStyle = {
  self: (riskColor?: string) => css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    background: var(--color-${riskColor}-5);
    aspect-ratio: 1 / 1.3333;
    height: 220px;
    padding: 20px;
    scroll-snap-align: center;
    user-select: none;

    &.loading {
      background: var(--color-black-5);
    }

    :not(.loading) {
      &:hover {
        cursor: pointer;
        background: var(--color-${riskColor}-10);
      }
    }
  `,

  chipsContainer: css`
    min-height: 24px;
  `,
};
