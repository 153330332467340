/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const RiskyUsersAndGroupsStyle = {
  self: css`
    height: fit-content;
    max-height: 390px;;
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
};
