/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionFormProps, TUrlsDetectorProtection } from '../Common';
import { UrlsDetectorStyle } from './UrlsDetectorForm.css';
import { Controller, useFormContext } from 'react-hook-form';
import { Text, ItemListInput, PSFormControlLabel } from '../../../../ui-kit';
import { Radio, RadioGroup } from '@mui/material';

const UrlsDetectorForm: React.FC<IProtectionFormProps<TUrlsDetectorProtection>> = (props) => {
    const { control, formName, protection } = props;
    const { setValue } = useFormContext();

    const isValidDomain = (domain: string) => {
        return /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,10}$/.test(domain);
    }

    const handleModeChange = (newValue: boolean) => {
        if (newValue) {
            setValue(`${formName}.allowed_domains`, protection.denied_domains, { shouldDirty: true, shouldTouch: true });
            setValue(`${formName}.denied_domains`, [], { shouldDirty: true, shouldTouch: true });
        } else {
            setValue(`${formName}.denied_domains`, protection.allowed_domains, { shouldDirty: true, shouldTouch: true });
            setValue(`${formName}.allowed_domains`, [], { shouldDirty: true, shouldTouch: true });
        }
    }

    return (
        <div css={UrlsDetectorStyle.self}>
            <Controller
                name={`${formName}.only_allowed`}
                defaultValue={protection.only_allowed}
                control={control}
                render={({ field }) => (
                    <div>
                        <Text>Action</Text>
                        <RadioGroup
                            {...field}
                            row
                            value={field.value ? 1 : 0}
                            onChange={(event) => {
                                const value = Number(event.target.value) === 1;
                                field.onChange(value);
                                handleModeChange(value);
                            }}
                        >
                            <PSFormControlLabel
                                value={0}
                                control={<Radio size='small' />}
                                label="Allow all URLs except from the following domains"
                            />
                            <PSFormControlLabel
                                value={1}
                                control={<Radio size='small' />}
                                label="Block all URLs except from the following domains"
                            />
                        </RadioGroup>
                    </div>
                )}
            />

            {protection.only_allowed && (
                <Controller
                    name={`${formName}.allowed_domains`}
                    defaultValue={protection.allowed_domains}
                    control={control}
                    render={({ field }) => (
                        <ItemListInput
                            {...field}
                            editable={false}
                            label='Allowed domains'
                            placeholder='Enter allowed domain'
                            validate={isValidDomain}
                            invalidMessage='Invalid domain'
                        />
                    )}
                />
            )}

            {!protection.only_allowed && (
                <Controller
                    name={`${formName}.denied_domains`}
                    defaultValue={protection.denied_domains}
                    control={control}
                    render={({ field }) => (
                        <ItemListInput
                            {...field}
                            editable={false}
                            label='Denied domains'
                            placeholder='Enter denied domain'
                            validate={isValidDomain}
                            invalidMessage='Invalid domain'
                        />
                    )}
                />
            )}
        </div>
    )
}

export default UrlsDetectorForm;