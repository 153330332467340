export const isPathMatching = (
  currentPath: string,
  menuPath: string
): boolean => {
  const currentSegments = currentPath.split("/");
  const menuSegments = menuPath.split("/");

  let i = 1;
  for (i; i < currentSegments.length && i < menuSegments.length; i++) {
    if (menuSegments[i].startsWith(':')) {
      continue;
    }
    if (currentSegments[i] !== menuSegments[i]) {
      return false;
    }
  }

  return true;
};
