import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from "@mui/material"

type PopupMessageProps = {
    open: boolean;
    title: string;
    text: string;
    handlePopupMessageClose: () => void;
}

export const PopupMessage = ({ open, title, text, handlePopupMessageClose }: PopupMessageProps) => {
    return (
        <Dialog
            open={open}
            onClose={handlePopupMessageClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handlePopupMessageClose} color='inherit'>Close</Button>
            </DialogActions>
        </Dialog>
    )
}