/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import RegexForm from './RegexForm';

const RegexProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode } = props;

    return (
        <ProtectionWrapper
            label="Regex"
            description="Prevent undesired text patterns in prompts or responses"
            formName="Regex"
            Component={RegexForm}
            control={control}
            getValues={getValues}
            isAdvancedMode={isAdvancedMode}
            summary={`The RegEx protection mechanism prevents specific undesired text patterns from appearing in your application's prompts or responses.

This feature allows you to define undesirable text patterns that should not be present in your application. For instance, if your company uses a unique identifier for customer accounts, you can set up the RegEx protection to redact or block any prompt/response that contains these IDs. This is achieved by adding the specific RegEx pattern below.`}
        />
    )
}

export default RegexProtection;