import log_icon from './icons/log_icon.png';

const getLogsButton = (iframeDocument: Document, buttonId: string) => {
    const button = iframeDocument.createElement(buttonId);
  
    button.id = buttonId;
    button.innerHTML = `<img src=${log_icon} width="20" height="17">`;
    button.style.marginTop = '4px';
    button.style.width = '25px';
    button.style.height = '20px';
    return button;
}

const setLogsHelpBubble = (iframeDocument: Document, buttonRect: DOMRect, helpBubble: Element) => {
    helpBubble.textContent = 'Expose log text';
    (helpBubble as any).style.position = 'absolute';
    (helpBubble as any).style.display = 'none';
    (helpBubble as any).style.backgroundColor = '#293847';
    (helpBubble as any).style.color = '#fff';
    (helpBubble as any).style.padding = '13px 13px';
    (helpBubble as any).style.height = '40px';
    (helpBubble as any).style.borderRadius = '4px';
    (helpBubble as any).style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.1)';
    (helpBubble as any).style.fontSize = '14px';
    (helpBubble as any).style.display = 'block';
    (helpBubble as any).style.left = `${buttonRect.left - 15 + window.scrollX}px`;
    (helpBubble as any).style.bottom = `${window.innerHeight - buttonRect.top + 20 + window.scrollY}px`;
  
    const triangle = document.createElement('div');
    triangle.style.position = 'absolute';
    triangle.style.top = '100%';
    triangle.style.left = '20%';
    triangle.style.borderStyle = 'solid';
    triangle.style.borderColor = '#001f3f transparent transparent transparent';
    triangle.style.borderWidth = '7px';
    triangle.style.transform = 'translateX(-50%)';
    helpBubble.appendChild(triangle);
    iframeDocument.body.appendChild(helpBubble);
}

const handleLogsPopup = (iframeDocument: Document, rowIndex: number) => {

    const getLogText = (info: logInfo, reason: string) => {
        const getResponse = async() => {
            try {
                const response = await fetch(`/api/be/get-log-text?promptResponseId=${info.promptResponseId}&textType=${info.textType}&reason=${reason}`);
                const logText = await response.text();
                return logText;
            } catch(error) {
                console.error('getLogText: Error fetching data:', error)
            }
        }

        return getResponse();
    };

    type logInfo = {
        promptResponseId: string;
        textType: string;
    }
    
    const clearDocumentInfoAndGetInfo = (): logInfo | null => {
        let promptResponseId: string | null | undefined;
        let textType: string | null | undefined;
    
        const tableDocViewRows = iframeDocument.querySelectorAll('tr[data-test-subj*="tableDocViewRow"]');
        tableDocViewRows.forEach((row) => {
            const dataTestSubjValue = row.getAttribute('data-test-subj');
            if (dataTestSubjValue === 'tableDocViewRow-Prompt Response ID') {
                promptResponseId = iframeDocument.querySelector('div[data-test-subj="tableDocViewRow-Prompt Response ID-value"]')?.textContent;
            } else if (dataTestSubjValue === 'tableDocViewRow-Text Type') {
                textType = iframeDocument.querySelector('div[data-test-subj="tableDocViewRow-Text Type-value"]')?.textContent;
            }
            const parentTable = row.parentNode;
            parentTable?.removeChild(row);
        });
    
        if (promptResponseId === undefined || promptResponseId === null ||
            textType === undefined || textType === null) {
                console.log("Error getting info");
                return null;
        }
    
        return { promptResponseId, textType };
    }
  
    const removeDocumentHeaders = () => {
        let parent;

        const title = iframeDocument.querySelector('div[class="euiFlyoutHeader"]');
        if (title !== null) {
            parent = title.parentNode;
            parent?.removeChild(title);
        }

        const tabs = iframeDocument.querySelector('div[class="euiFlyoutBody__overflow"]');
        if (tabs !== null) {
            parent = tabs.parentNode;
            parent?.removeChild(tabs);
        }

        return parent;
    }
  
    const displayInputField = (popupElement: ParentNode | undefined | null, ProvideReasonElement: Element, info: logInfo) => {
        let inputElement = iframeDocument.createElement('input');
        inputElement.type = 'text';
        inputElement.style.width = '60%';
        inputElement.style.marginTop = '35px';
        inputElement.style.marginLeft = '20px';
        popupElement?.appendChild(inputElement);

        let submitButton = iframeDocument.createElement('button');
        submitButton.innerHTML = 'Submit';
        submitButton.style.marginTop = '20px';
        submitButton.style.marginLeft = '10px';
        submitButton.style.backgroundColor = 'rgba(200, 200, 200, 0.5)';
        submitButton.style.border = '0.5px solid gray';
        submitButton.style.paddingTop = '2px';
        submitButton.style.paddingBottom = '2px';
        submitButton.style.paddingLeft = '5px';
        submitButton.style.paddingRight = '5px';
        submitButton.style.borderRadius = '4%';
        submitButton.addEventListener('click', function() {
            popupElement?.removeChild(ProvideReasonElement);
            popupElement?.removeChild(inputElement);
            popupElement?.removeChild(submitButton);

            if (inputElement.value && inputElement.value.trim() !== '') {
                getLogText(info, inputElement.value).then((logText) => {
                    if (logText !== undefined) {
                        displayText(popupElement, 'The log text requested is:', '20px');
                        displayText(popupElement, logText, '16px');
                    }
                });
            } else {
                displayText(popupElement, 'Invalid input', '20px');
                displayText(popupElement, 'You must provide a reason for exposing the log text', '16px');
            }
        });
  
      popupElement?.appendChild(submitButton);
    }
  
    const displayText = (popupElement: ParentNode | undefined | null, text: string, fontSize: string) => {
        let textElement = iframeDocument.createElement('p');

        textElement.innerHTML = text;
        textElement.style.marginTop = '35px';
        textElement.style.marginLeft = '20px';
        textElement.style.fontSize = fontSize
        popupElement?.appendChild(textElement);

        return textElement;
    }
  
    const documentOpenButton = iframeDocument.querySelector(`button[data-test-subj="docTableExpandToggleColumn-${rowIndex}"]`);
    const documentCloseButton = iframeDocument.querySelector(`button[data-test-subj="euiFlyoutCloseButton"]`);
    (documentOpenButton as any).click();
  
    const info = clearDocumentInfoAndGetInfo();
    if (info === null) {
        (documentCloseButton as any).click();
        console.log("Error getting info to query the log text");
    } else {
        const popupElement = removeDocumentHeaders();
        const provideReasonElement = displayText(popupElement, 'Provide a reason for exposing the log text', '20px');
        displayInputField(popupElement, provideReasonElement, info); 
    }
}
  
  
export const handleLogsExtraction = (iframeDocument: Document, pagesNumber: number, pageIndex: number, rowPageIndex: number, rowIndex: number, cell: Element) => {
    const buttonId = `logs_button_${rowPageIndex}_${pageIndex}`;

    for (let i = 1; i <= pagesNumber; i++) {
        if (i !== pageIndex) {
            const oldButton = iframeDocument.getElementById(`logs_button_${rowPageIndex}_${i}`);
            if (oldButton) {
                cell.removeChild(oldButton);
            }
        }
    }

    if (!iframeDocument.getElementById(buttonId)) {
        const button = getLogsButton(iframeDocument, buttonId);
        const helpBubble = iframeDocument.createElement('bubble');
        (cell as any).appendChild(button);

        button.addEventListener('mouseover', function() {
        const buttonRect = button.getBoundingClientRect();
        setLogsHelpBubble(iframeDocument, buttonRect, helpBubble);
        });

        button.addEventListener('mouseout', function () {
        helpBubble.style.display = 'none';
        });

        button.addEventListener('click', function (){
            handleLogsPopup(iframeDocument, rowIndex);
        });
    }
}