import { useEffect, useState } from 'react';
import { IntegrationItem } from '../IntegrationComponents/IntegrationItem';
import { Box } from '@mui/material';
import { graphql, graphqlEndpoint } from "../../../gql";
import { useQuery } from "@tanstack/react-query";
import { request } from "graphql-request";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EditButtons } from '../../../components/EditButtons';
import { StatusRadio } from '../IntegrationComponents/StatusRadio';
import { PopupMessage } from '../../../components/PopupMessage';
import { TextInput } from '../IntegrationComponents/TextInput';
import { OverrideEntraIntegrationInput } from '../../../gql/generated/graphql';

const HIDDEN_TEXT = '*****';

const queryEntraIntegration = graphql(`
    query EntraIntegration {
        entraIntegration {
            id
            tenantId
            clientId
            machineToMachineClientId
            machineToMachineClientSecretExists
        }
    }
`);

const mutationOverrideEntraIntegration = graphql(`
    mutation OverrideEntraIntegration($tenantId: String, $isEnabled: Boolean, $clientId: String, $machineToMachineClientId: String, $machineToMachineClientSecret: String) {
        overrideEntraIntegration(
            input: {
                tenantId: $tenantId
                isEnabled: $isEnabled,
                clientId: $clientId,
                machineToMachineClientId: $machineToMachineClientId,
                machineToMachineClientSecret: $machineToMachineClientSecret
            }
        ) {
            id
        }
    }
`);

type IntegrationEntraCardProps = {
    inputIsEnabled: boolean
};


export const IntegrationMsEntraIdCard = ({ inputIsEnabled }: IntegrationEntraCardProps) => {    
    const { data: entraIntegration } = useQuery(
        {
            queryKey: ["EntraIntegration"],
            queryFn: async () => {
                const { entraIntegration } = await request(graphqlEndpoint, queryEntraIntegration);
                return entraIntegration;
            },
        }
    )

    useEffect(() => {
        if (entraIntegration !== undefined) {
            setTenantIdToDisplay(entraIntegration.tenantId);
            setClientIdToDisplay(entraIntegration.clientId);
            setMachineToMachineClientIdToDisplay(entraIntegration.machineToMachineClientId);
            setMachineToMachineClientSecretToDisplay(entraIntegration?.machineToMachineClientSecretExists ? HIDDEN_TEXT : null);
        }
    }, [entraIntegration]);

    const queryClient = useQueryClient();
    const [editMode, setEditMode] = useState<boolean>(false);
    const [isEnabledToDisplay, setIsEnabledToDisplay] = useState<boolean>(inputIsEnabled);
    const [tenantIdToDisplay, setTenantIdToDisplay] = useState<string | undefined | null>();
    const [clientIdToDisplay, setClientIdToDisplay] = useState<string | undefined | null>();
    const [machineToMachineClientIdToDisplay, setMachineToMachineClientIdToDisplay] = useState<string | undefined | null>();
    const [machineToMachineClientSecretToDisplay, setMachineToMachineClientSecretToDisplay] = useState<string | undefined | null>();
    const [openSavePopup, setOpenSavePopup] = useState<boolean>(false);
    const [popupText, setPopupText] = useState<string>('');
    const [popupTitle, setPopupTitle] = useState<string>('');

    const overrideEntraIntegration = useMutation({
        mutationFn: async (variables: any) => {
            await request(graphqlEndpoint, mutationOverrideEntraIntegration, variables);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["entraIntegration"] });
            queryClient.invalidateQueries({ queryKey: ["integration"] });

            setMachineToMachineClientSecretToDisplay(entraIntegration?.machineToMachineClientSecretExists ? HIDDEN_TEXT : null);
            setPopupText('Your changes have been successfully saved');
            setPopupTitle('Entra Integration Updated');
            setOpenSavePopup(true);
            setEditMode(false);
        },
        onError: (error) => {
          const errorMessage = 'Failed to update Entra Integration';
          setPopupText(errorMessage);
          setPopupTitle('Entra Integration Update Failed');
          setOpenSavePopup(true);
        }
    });

    const checkTextValidity = (value: string | undefined | null, varName: string, required: boolean): boolean => {
        if ((value !== null && value !== undefined && value.trim() === '') ||
            (required === true && (value === null || value === undefined))
        ) {
            setPopupText(`${varName} can't be empty`);
            setPopupTitle('Entra Integration Update Failed');
            setOpenSavePopup(true);
            return false;
        }
        return true;
    }

    const handleSave = () => {  
        const isEnabled = (isEnabledToDisplay === true || (isEnabledToDisplay === null && inputIsEnabled === true));
        let overrideData: OverrideEntraIntegrationInput = { 
            isEnabled: isEnabledToDisplay
        }

        if (isEnabled) {
            if (!checkTextValidity(tenantIdToDisplay, 'Tenant ID', true) ||
                !checkTextValidity(clientIdToDisplay, 'Client ID', true) ||
                !checkTextValidity(machineToMachineClientIdToDisplay, 'Machine to Machine Client ID', false) ||
                !checkTextValidity(machineToMachineClientSecretToDisplay, 'Machine to Machine Client Secret', false)
            ) {
                return;
            }
            overrideData.tenantId = tenantIdToDisplay;
            overrideData.clientId = clientIdToDisplay;
            overrideData.machineToMachineClientId = machineToMachineClientIdToDisplay;
            if (machineToMachineClientSecretToDisplay && machineToMachineClientSecretToDisplay !== HIDDEN_TEXT) {
                overrideData.machineToMachineClientSecret = machineToMachineClientSecretToDisplay;
            }
        }

        overrideEntraIntegration.mutate(overrideData);
    };

    const handleCancel = () => {
        setIsEnabledToDisplay(inputIsEnabled);
        setTenantIdToDisplay(entraIntegration?.tenantId);
        setClientIdToDisplay(entraIntegration?.clientId);
        setMachineToMachineClientIdToDisplay(entraIntegration?.machineToMachineClientId);
        setMachineToMachineClientSecretToDisplay(entraIntegration?.machineToMachineClientSecretExists ? HIDDEN_TEXT : null);
        setEditMode(false);
    };

    const handleEmptyValues = (value: string | undefined | null) => {
        if (value === '') {
            return null;
        }
        return value;
    }

    const handleIntegrationChange = (
        isEnabled: boolean,
        tenantId: string | null | undefined,
        clientId: string | null | undefined,
        machineToMachineClientId: string | null | undefined,
        machineToMachineClientSecret: string | null | undefined
    ) => {
        tenantId = handleEmptyValues(tenantId);
        clientId = handleEmptyValues(clientId);
        machineToMachineClientId = handleEmptyValues(machineToMachineClientId);
        machineToMachineClientSecret = handleEmptyValues(machineToMachineClientSecret);

        if (isEnabled !== isEnabledToDisplay) {
            setIsEnabledToDisplay(isEnabled);
        }
        if (tenantId !== tenantIdToDisplay) {
            setTenantIdToDisplay(tenantId);
        }
        if (clientId !== clientIdToDisplay) {
            setClientIdToDisplay(clientId);
        }
        if (machineToMachineClientId !== machineToMachineClientIdToDisplay) {
            setMachineToMachineClientIdToDisplay(machineToMachineClientId);
        }
        if (machineToMachineClientSecret !== machineToMachineClientSecretToDisplay) {
            setMachineToMachineClientSecretToDisplay(machineToMachineClientSecret);
        }

        if (isEnabled !== inputIsEnabled ||
            (tenantId !== entraIntegration?.tenantId && !(tenantId === null && entraIntegration?.tenantId === undefined)) ||
            (clientId !== entraIntegration?.clientId && !(clientId === null && entraIntegration?.clientId === undefined)) ||
            (machineToMachineClientId !== entraIntegration?.machineToMachineClientId && !(machineToMachineClientId === null && entraIntegration?.machineToMachineClientId === undefined)) ||
            (machineToMachineClientSecret !== HIDDEN_TEXT && !(machineToMachineClientSecret === null && entraIntegration?.machineToMachineClientSecretExists === undefined))
        ) {
            setEditMode(true);
        } else {
            setEditMode(false);
        }
    }

    const handleSavePopupClose = () => {
        setOpenSavePopup(false);
      };

    if (!editMode) {
        if (isEnabledToDisplay !== inputIsEnabled) {
            setIsEnabledToDisplay(inputIsEnabled);
        }
    }

    const handleEnableOnChange = (value: boolean) => {
        setIsEnabledToDisplay(value);
        handleIntegrationChange(value, tenantIdToDisplay, clientIdToDisplay, machineToMachineClientIdToDisplay, machineToMachineClientSecretToDisplay);
    };

    const handleTenantIdOnChange = (value: string) => {
        setTenantIdToDisplay(value);
        handleIntegrationChange(isEnabledToDisplay, value, clientIdToDisplay, machineToMachineClientIdToDisplay, machineToMachineClientSecretToDisplay);
    };

    const handleClientIdOnChange = (value: string) => {
        setClientIdToDisplay(value);
        handleIntegrationChange(isEnabledToDisplay, tenantIdToDisplay, value, machineToMachineClientIdToDisplay, machineToMachineClientSecretToDisplay);
    };

    const handleMachineToMachineClientIdOnChange = (value: string) => {
        setMachineToMachineClientIdToDisplay(value);
        handleIntegrationChange(isEnabledToDisplay, tenantIdToDisplay, clientIdToDisplay, value, machineToMachineClientSecretToDisplay);
    };

    const handleMachineToMachineClientSecretOnChange = (value: string) => {
        setMachineToMachineClientSecretToDisplay(value);
        handleIntegrationChange(isEnabledToDisplay, tenantIdToDisplay, clientIdToDisplay, machineToMachineClientIdToDisplay, value);
    };

    return (
        <div>
            <IntegrationItem>
                <Box>
                    <StatusRadio value={isEnabledToDisplay} handleOnChange={handleEnableOnChange} text='Status'/>
                    <TextInput value={tenantIdToDisplay} title='Tenant ID' help='Tenant ID (Directory ID) in Azure entra' multiline={false} handleOnChange={handleTenantIdOnChange} disable={isEnabledToDisplay === false} />
                    <TextInput value={clientIdToDisplay} title='Client ID' help='Client Id of Prompt Security OpenID Connect Entra app' multiline={false} handleOnChange={handleClientIdOnChange} disable={isEnabledToDisplay === false} />
                    <TextInput value={machineToMachineClientIdToDisplay} title='Machine to Machine Client ID' help='Client Id of Prompt Security API Services Entra app' multiline={false} handleOnChange={handleMachineToMachineClientIdOnChange} disable={isEnabledToDisplay === false} />
                    <TextInput value={machineToMachineClientSecretToDisplay} title='Machine to Machine Client Secret' help='Client Secret of Prompt Security API Services Entra app' multiline={false} handleOnChange={handleMachineToMachineClientSecretOnChange} disable={isEnabledToDisplay === false} />
                </Box>
            </IntegrationItem>
            <EditButtons sx={{ mt: 6, ml: 3.5, marginBottom: 1}} editMode={editMode} handleSaveClick={handleSave} handleCancelClick={handleCancel} />
            <PopupMessage title={popupTitle} text={popupText} open={openSavePopup} handlePopupMessageClose={handleSavePopupClose}/>
        </div>
    )
};