/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PSTextDiffStyle = {
    regularText: css`
        color: var(--color-black);
    `,
    removedText: (isActive: boolean) => css`
      font-weight: bold;
      color: var(--color-red);
      background: var(--color-red-10);
      margin-right: ${isActive ? "5px" : "0"};
    `,
    addedText: css`
      font-weight: bold;
      color: var(--color-green);
      background: var(--color-green-10);
    `,
}