import { User } from '@frontegg/redux-store/auth/interfaces';
import { getCurrentTenant, TTenantDetails } from '../routes/ManageTenants/tenants';
declare var pendo: any;

export const pendoInitialize = (user: User) => {
  const script = document.createElement("script");
  script.async = true;
  script.src =
    "https://cdn.pendo.io/agent/static/213cf86a-d7f4-4af3-702f-5aaa6541b33d/pendo.js";
  document.body.appendChild(script);
  const tenant: TTenantDetails | null = getCurrentTenant();

  script.onload = () => {
    const nameParts = user.name.split(" ");
    const firstName = nameParts.length ? nameParts[0] : '';
    const lastName = nameParts.length > 1 ? nameParts[1] : '';
    pendo.initialize({
      visitor: {
        id: user.id,
        email: user.email,
        firstName: firstName,
        lastName: lastName,
        role: user.roles.map((x) => x.name).join(", "),
        // productDevelopers: PROPERTY_VALUE,
        // productEmployees: PROPERTY_VALUE,
        // productHomegrownApplications: PROPERTY_VALUE, //TODO - need to add this
      },

      account: { // TODO - we need to add logic that if the account is MSSP the tenant should only be the MSSP tenant. also check if the user ID changes between tenants
        id: tenant?.id,
        accountName: tenant?.name
      },
    });
  };
};
