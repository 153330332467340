/** @jsxImportSource @emotion/react */
import React from 'react';
import { Skeleton } from '@mui/material';
import { PSGenAIMenuCardStyle } from './PSGenAIMenuCard.css';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';
import clsx from 'clsx';

type IProps = {
    applicationName?: string;
    isAiComponent?: boolean;
    isActive?: boolean;
    isLoading?: boolean;
    isCustom?: boolean;
    imageUrl?: string;
    type?: 'default' | 'modified';
    isUserOrGroupEdited?: boolean;
    onClick?: () => void;
};

const PSGenAIMenuCard: React.FC<IProps> = (props) => {
    const { applicationName, isAiComponent, isLoading, isCustom, imageUrl, isActive, type, isUserOrGroupEdited, onClick } = props;

    if (isLoading) return (
        <Skeleton variant="rectangular" sx={{ borderRadius: '15px', minHeight: '70px' }} />
    )

    return (
        <button onClick={onClick} css={PSGenAIMenuCardStyle.self} className={clsx({ 'active': isActive })}>
            <div>
                {!isCustom && <img src={imageUrl || 'https://via.placeholder.com/40'} width={40} height={40} alt="App Icon" onError={(e) => { e.currentTarget.src = 'https://via.placeholder.com/40' }} />}
                {isCustom && <Icon iconName='PSCustomApplicationIcon' iconSize={40} />}
            </div>
            <div css={PSGenAIMenuCardStyle.textContainer}>
                <Text ellipsis variant='bold'>{applicationName}</Text>
                {isAiComponent && <Text textCss={PSGenAIMenuCardStyle.labelText} variant='small'>AI Component</Text>}
            </div>
            <div css={PSGenAIMenuCardStyle.typeContainer}>
                {type === 'default' && <Text variant='small' color='black-70'>Default</Text>}
                {type === 'modified' && <Text variant='small' color='black-70'>Modified</Text>}
                {isUserOrGroupEdited && <Icon iconName='GroupsRounded' color='black-70' iconSize={20} />}
            </div>
        </button>
    )
}

export default PSGenAIMenuCard;