/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const circleStyle = (size: number | string = 10) => {
  const cssSize = typeof size === "number" ? size + "px" : size;
  return css`
    width: ${cssSize};
    height: ${cssSize};
    min-width: ${cssSize};
    min-height: ${cssSize};
    max-width: ${cssSize};
    max-height: ${cssSize};
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    display: grid;
    place-items: center;
  `;
};

export const CircleStyle = {
  self: (size: number | string, color: string) => css`
    ${circleStyle(size)};
    background: ${color};
  `,
};
