import { Grid, FormLabel, FormControl, FormControlLabel, Radio, RadioGroup, Tooltip } from "@mui/material";

export type optionDisplay = {
    text: string;
    helpText: string;
}

type BooleanOptionsRadioProps = {
    value: boolean;
    title: string;
    handleOnChange: (value: boolean) => void;
    optionDisplayText: (option: boolean) => optionDisplay;
  };
  
export const BooleanOptionsRadio = ({ value, title, handleOnChange, optionDisplayText }: BooleanOptionsRadioProps) => {
    const {text: trueText, helpText: trueHelpText} = optionDisplayText(true);
    const {text: falseText, helpText: falseHelpText} = optionDisplayText(false);

    return (
        <Grid container alignItems="center">
            <Grid item xs={3}>
                <FormLabel component="legend">{<span style={{ color: 'black' }}>{title}</span>}</FormLabel>
            </Grid>
            <Grid item xs sx={{ mx: 14, width: '100%' }}>
                <FormControl>
                    <RadioGroup
                        row
                        value={value}
                    >
                    <Tooltip key='true' title={trueHelpText} placement="top-start">
                        <FormControlLabel
                            key='true'
                            value={true}
                            control={<Radio size='small' />}
                            label={<span>{trueText}</span>}
                            onChange={() => handleOnChange(true)}
                        />
                    </Tooltip>
                    <Tooltip key='false' title={falseHelpText} placement="top-start">
                        <FormControlLabel
                            key='false'
                            value={false}
                            control={<Radio size='small' />}
                            label={<span>{falseText}</span>}
                            onChange={() => handleOnChange(false)}
                        />
                    </Tooltip>
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    )
}