/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const ExtensionPageStyle = {
  self: css`
    --min-column-size: 400px;
    --column-count: 2;
    --gap: 20px;

    --breakpoint: calc(
      var(--min-column-size) * var(--column-count) +
        (var(--gap) * (var(--column-count) - 1))
    );
    --column-size: calc((100% / var(--column-count)) - var(--gap));

    display: grid;
    gap: var(--gap);

    grid-template-columns: repeat(
      auto-fit,
      minmax(
        min(max(var(--column-size), (100% - var(--breakpoint)) * -999), 100%),
        1fr
      )
    );
  `,
};
