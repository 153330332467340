/** @jsxImportSource @emotion/react */
import { FormControlLabel, FormControlLabelProps } from '@mui/material';
import styled from '@emotion/styled';
import { TextStyle } from '../Text/Text.css';

const PSFormControlLabel = styled(({ ...props }: FormControlLabelProps) => (
    <FormControlLabel {...props} />
))`
    gap: 5px;
    
    & .MuiFormControlLabel-label {
        ${TextStyle.text};
        color: var(--color-black-70);
    }
`;

export default PSFormControlLabel;