import { ReactComponent as PSAccountsIcon } from './ps-accounts.svg';
import { ReactComponent as PSAccountsDashboardIcon } from './ps-accounts_dashboard.svg';
import { ReactComponent as PSActivityMonitorIcon } from './ps-activity_monitor.svg';
import { ReactComponent as PSAddIcon } from './ps-add.svg';
import { ReactComponent as PSAppNotIcon } from './ps-app_not.svg';
import { ReactComponent as PSArrowDownIcon } from './ps-arrow_down.svg';
import { ReactComponent as PSArrowLeftIcon } from './ps-arrow_left.svg';
import { ReactComponent as PSArrowRightIcon } from './ps-arrow_right.svg';
import { ReactComponent as PSArrowUpIcon } from './ps-arrow_up.svg';
import { ReactComponent as PSAuditIcon } from './ps-audit.svg';
import { ReactComponent as PSBlockIcon } from './ps-block.svg';
import { ReactComponent as PSBuildIcon } from './ps-build.svg';
import { ReactComponent as PSBypassIcon } from './ps-bypass.svg';
import { ReactComponent as PSCalendarIcon } from './ps-calendar.svg';
import { ReactComponent as PSChatgptIcon } from './ps-chatgpt.svg';
import { ReactComponent as PSCheckmarkIcon } from './ps-checkmark.svg';
import { ReactComponent as PSChevronDownIcon } from './ps-chevron_down.svg';
import { ReactComponent as PSChevronLeftIcon } from './ps-chevron_left.svg';
import { ReactComponent as PSChevronLeftLastIcon } from './ps-chevron_left_last.svg';
import { ReactComponent as PSChevronRightIcon } from './ps-chevron_right.svg';
import { ReactComponent as PSChevronRightLastIcon } from './ps-chevron_right_last.svg';
import { ReactComponent as PSChevronUpIcon } from './ps-chevron_up.svg';
import { ReactComponent as PSCollapseIcon } from './ps-collapse.svg';
import { ReactComponent as PSCopyIcon } from './ps-copy.svg';
import { ReactComponent as PSCriticalIcon } from './ps-critical.svg';
import { ReactComponent as PSDashboardIcon } from './ps-dashboard.svg';
import { ReactComponent as PSDeleteIcon } from './ps-delete.svg';
import { ReactComponent as PSDevIcon } from './ps-developers.svg';
import { ReactComponent as PSDoneIcon } from './ps-done.svg';
import { ReactComponent as PSDoneAllIcon } from './ps-done_all.svg';
import { ReactComponent as PSDownloadIcon } from './ps-download.svg';
import { ReactComponent as PSEditIcon } from './ps-edit.svg';
import { ReactComponent as PSEditSquareIcon } from './ps-edit_square.svg';
import { ReactComponent as PSEducateIcon } from './ps-educate.svg';
import { ReactComponent as PSEmployeesIcon } from './ps-employees.svg';
import { ReactComponent as PSExpandIcon } from './ps-expand.svg';
import { ReactComponent as PSFilterIcon } from './ps-filter.svg';
import { ReactComponent as PSHelpIcon } from './ps-help.svg';
import { ReactComponent as PSHomegrownIcon } from './ps-homegrown.svg';
import { ReactComponent as PSInfoIcon } from './ps-info.svg';
import { ReactComponent as PSInspectIcon } from './ps-inspect.svg';
import { ReactComponent as PSIntegrationsIcon } from './ps-integrations.svg';
import { ReactComponent as PSLearningIcon } from './ps-learning.svg';
import { ReactComponent as PSLogIcon } from './ps-log.svg';
import { ReactComponent as PSLogoutIcon } from './ps-logout.svg';
import { ReactComponent as PSMoreMenuIcon } from './ps-more_menu.svg';
import { ReactComponent as PSPreferencesIcon } from './ps-preferences.svg';
import { ReactComponent as PSRefreshIcon } from './ps-refresh.svg';
import { ReactComponent as PSRemoveIcon } from './ps-remove.svg';
import { ReactComponent as PSReplyAllLeftIcon } from './ps-reply_all_left.svg';
import { ReactComponent as PSReplyAllRightIcon } from './ps-reply_all_right.svg';
import { ReactComponent as PSSearchIcon } from './ps-search.svg';
import { ReactComponent as PSSecurityApproachIcon } from './ps-security_approach.svg';
import { ReactComponent as PSShadowAiIcon } from './ps-shadow_ai.svg';
import { ReactComponent as PSThumbDownIcon } from './ps-thumb_down.svg';
import { ReactComponent as PSThumbUpIcon } from './ps-thumb_up.svg';
import { ReactComponent as PSUploadIcon } from './ps-upload.svg';
import { ReactComponent as PSUploadCloudIcon } from './ps-upload_cloud.svg';
import { ReactComponent as PSUserIcon } from './ps-user.svg';
import { ReactComponent as PSUserCircleIcon } from './ps-user_circle.svg';
import { ReactComponent as PSUsersIcon } from './ps-users.svg';
import { ReactComponent as PSSettingsIcon } from './ps-settings.svg';
import { ReactComponent as PSCustomApplicationIcon } from './ps-custom_application.svg';

export const PSIcons = {
    PSAccountsIcon,
    PSAccountsDashboardIcon,
    PSActivityMonitorIcon,
    PSAddIcon,
    PSAppNotIcon,
    PSArrowDownIcon,
    PSArrowLeftIcon,
    PSArrowRightIcon,
    PSArrowUpIcon,
    PSAuditIcon,
    PSBlockIcon,
    PSBuildIcon,
    PSBypassIcon,
    PSCalendarIcon,
    PSChatgptIcon,
    PSCheckmarkIcon,
    PSChevronDownIcon,
    PSChevronLeftIcon,
    PSChevronLeftLastIcon,
    PSChevronRightIcon,
    PSChevronRightLastIcon,
    PSChevronUpIcon,
    PSCollapseIcon,
    PSCopyIcon,
    PSCriticalIcon,
    PSDashboardIcon,
    PSDeleteIcon,
    PSDevIcon,
    PSDoneIcon,
    PSDoneAllIcon,
    PSDownloadIcon,
    PSEditIcon,
    PSEditSquareIcon,
    PSEducateIcon,
    PSEmployeesIcon,
    PSExpandIcon,
    PSFilterIcon,
    PSHelpIcon,
    PSHomegrownIcon,
    PSInfoIcon,
    PSInspectIcon,
    PSIntegrationsIcon,
    PSLearningIcon,
    PSLogIcon,
    PSLogoutIcon,
    PSMoreMenuIcon,
    PSPreferencesIcon,
    PSRefreshIcon,
    PSRemoveIcon,
    PSReplyAllLeftIcon,
    PSReplyAllRightIcon,
    PSSearchIcon,
    PSSecurityApproachIcon,
    PSShadowAiIcon,
    PSThumbDownIcon,
    PSThumbUpIcon,
    PSUploadIcon,
    PSUploadCloudIcon,
    PSUserIcon,
    PSUserCircleIcon,
    PSUsersIcon,
    PSSettingsIcon,
    PSCustomApplicationIcon,
} as const;