/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import ModeratorForm from './ModeratorForm';

const ModeratorProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode } = props;

    return (
        <ProtectionWrapper
            control={control}
            label="Moderator"
            getValues={getValues}
            description="Block unsafe or harmful content"
            formName="Moderator"
            Component={ModeratorForm}
            isAdvancedMode={isAdvancedMode}
            summary={`The moderator is an LLM-Powered protection designed to prevent the inclusion of harmful or offensive content in your prompts or responses. It acts as a safety filter, ensuring that unwanted content is not allowed to pass through.`}
        />
    )
}

export default ModeratorProtection;