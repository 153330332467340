/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { TRoles } from './ActivityMonitorConversation';

export const ActivityMonitorConversationStyle = {
    self: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
    `,
    conversationKey: css`
        margin-bottom: 10px;
    `,
    section: (role: TRoles) => css`
        display: flex;
        gap: 10px;
        flex-direction: ${role === 'assistant' ? 'row-reverse' : 'row'};
    `,
    role: css`
        align-self: flex-end;
    `,
    messagesContainer: (role: TRoles) => css`
        display: flex;
        flex-direction: column;
        gap: 3px;
        max-width: 70%;
        & > :first-child {
            ${role === 'assistant' && `border-top-right-radius: 30px;`};
            ${role === 'user' && `border-top-left-radius: 30px;`};
        }

        & > :last-child {
            ${role === 'assistant' && `border-bottom-right-radius: 30px;`};
            ${role === 'user' && `border-bottom-left-radius: 30px;`};
        }
    `,
    messageBubble: (role: TRoles) => css`
        padding: 15px; 
        border-radius: 30px;
        background: ${role === 'assistant' ? 'var(--color-black-25)' : 'var(--color-blue-25)'};
        ${role === 'assistant' && `border-bottom-right-radius: 0; border-top-right-radius: 0;`};
        ${role === 'user' && `border-bottom-left-radius: 0; border-top-left-radius: 0;`};
    `,
    conversationContainer: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
    `
}