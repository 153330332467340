/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const PSScrollableStyle = {
  self: css`
    position: relative;
  `,
  scrollableGradient: css`
    position: absolute;
    top: 0;
    width: 120px;
    height: 100%;
    transition: opacity 0.3s;

    cursor: pointer;

    display: flex;
    align-items: center;

    & .icon {
      opacity: 0;
      transition: opacity 0.2s;
      margin-inline: 20px;
    }
  `,
  scrollableGradientLeft: css`
    justify-content: flex-start;
    left: 0;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0) 3.8%,
      #fff 100%
    );

    :hover {
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.25) 3.8%,
        #fff 100%
      );

      & .icon {
        opacity: 1;
      }
    }
  `,
  scrollableGradientRight: css`
    justify-content: flex-end;
    right: 0;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 3.8%,
      #fff 100%
    );

    :hover {
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.25) 3.8%,
        #fff 100%
      );

      & .icon {
        opacity: 1;
      }
    }
  `,
  scrollContainer: (overflow: string) => css`
    display: flex;
    align-items: center;
    gap: 15px;
    overflow: ${overflow};
  `,
};
