/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Tab, TabProps, Skeleton } from "@mui/material";
import { TextStyle } from "../Text/Text.css";
import Text from "../Text/Text";
import { css } from "@emotion/react";

const PSTab = styled(({ isLoading, number, ...props }: TabProps & { isLoading?: boolean, number?: number }) => (
    <>
        {isLoading && <Skeleton style={{ flexGrow: 1 }} className="loading" variant="text" width={100} height={30} />}
        {!isLoading &&
            <Tab disableRipple {...props} label={
                <div css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
            `}>
                    {number !== undefined && <Text className="number-text" variant="bold" color="black-50">{number}</Text>}
                    <div>{props.label}</div>
                </div>
            } />
        }
    </>
))`
    padding-top: 20px;
    padding-bottom: 20px;
    text-transform: none;
    flex-grow: 1;
    opacity: 1;
    ${TextStyle.text}
    color: var(--color-black-70);
    &:hover {
        color: var(--color-black-70);
        .number-text {
            color: var(--color-black-70);
        }
    };
    &:active {
        color: var(--color-black);
        .number-text {
            color: var(--color-black);
        }
    }
    &.Mui-selected {
        ${TextStyle.bold};
        color: var(--color-black);
        .number-text {
            color: var(--color-black);
        }
    }
`;

export default PSTab;