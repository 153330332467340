type DataItem = {
  entity?: string;
  entity_type?: string;
  score?: string;
  categories?: string[];
  sanitized_entity?: string;
};

type DataObject = { [key: string]: DataItem[] | any };

export const extractEntityTypesToArray = (
  data: DataObject,
  keys: string[] = []
): string[] => {
  let entityTypes: Set<string> = new Set();
  if(data === null || data === undefined) return [];

  keys.forEach((key) => {
    const items = data[key];
    if (Array.isArray(items)) {
      items.forEach((item) => {
        if (item.entity_type) {
          entityTypes.add(item.entity_type);
        }
      });
    }
  });

  return [...entityTypes];
};
