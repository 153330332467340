import { css } from '@emotion/react';

export const RoundedOutlinedButtonStyle = {
    self: css`
        border: 1px solid var(--color-blue);
        border-radius: 60px;
        padding: 10px 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        cursor: pointer;
        max-width: 100%;
        
        color: var(--color-blue);

        background: transparent;

        & .icon {
            width: 20px;
            height: 20px;
            fill: var(--color-blue);
            transition: none;
        }

        &:disabled {
            border: 1px solid var(--color-black-50);
            color: var(--color-black-70);
            & .icon { fill: var(--color-black-70); }
            cursor: default;
        }

        :not(:disabled) {
            &:hover {
                border: 1px solid var(--color-blue-dark);
                color: var(--color-blue-dark);
                & .icon { fill: var(--color-blue-dark); }
            }
        
            &:active, &.active {
                border: 1px solid var(--color-blue-light);
                color: var(--color-blue-light);
                & .icon { fill: var(--color-blue-light); }
            }

            &:focus-visible {
                outline: 1px solid var(--color-blue);
            }
        }
    `
}