/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import LanguageDetectorForm from './LanguageDetectorForm';

const LanguageDetectorProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues } = props;

    return (
        <ProtectionWrapper
            label="Language Detector"
            description="Classify and control languages of prompts and responses"
            formName="Language Detector"
            Component={LanguageDetectorForm}
            control={control}
            getValues={getValues}
            summary={`The language detector provides both detection and control over which languages appear in either the prompt or the response of your genAI application . This achieves two things:

1. It allows you to set the range of your application's content. If you're catering to a specific audience that speaks a certain language, or dealing with technical restrictions that only support a particular language (e.g., the model works best with English), you can control this by adding languages to the "allowed languages" field. This restricts the languages your application accepts and blocks all others. Conversely, if you want to prohibit certain languages from your application, add them to the "denied languages" field.
2. It facilitates easy, out-of-the-box application of language detection on your prompts and/or responses, giving you insight into which languages are used in interaction with your custom AI application.`}
        />
    )
}

export default LanguageDetectorProtection;