import { Autocomplete, Button, IconButton, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { request } from "graphql-request";
import { graphql, graphqlEndpoint } from "../../../gql";
import { GenAiApplicationVerificationStatus, RuleContentKeys, RuleContentOperators } from "../../../gql/generated/graphql";
import { useQuery } from "@tanstack/react-query";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

type SingleRuleContentProps = {
    content: RuleContent;
    onChange: (content: any) => void;
    validContentKeys: string[];
    selectedOrganizationGenAiApplications: Map<string, string>;
}

export type RuleContentSingle = {
    key: RuleContentKeys;
    operator: RuleContentOperators;
    value: string[];
}

export type RuleContent = RuleContentSingle[];

type SingleContentProps = {
    contentKey: RuleContentSingle["key"];
    onContentKeyChange: (event: SelectChangeEvent) => void;
    contentOperator: RuleContentSingle["operator"];
    onContentOperatorChange: (event: SelectChangeEvent) => void;
    contentValue: RuleContentSingle["value"]
    onContentValueChange: (event: any, value: string[]) => void;
    validContentKeys: string[];
    onContentAddCondition: (event: any) => void;
    onContentDeleteCondition: (event: any) => void;
    contentIndex: number;
    contentLength: number;
    selectedOrganizationGenAiApplications: Map<string, string>;
}

const querySuggestRuleContentValuesOrganizationGenAiApplications = graphql(`
    query SuggestRuleContentValuesOrganizationGenAiApplications($contains: String) {
        suggestRuleContentValuesOrganizationGenAiApplications(contains: $contains) {
            id
            name
            domain
        }
    }
`);

const querySuggestRuleContentValuesOrganizationString = graphql(`
    query SuggestRuleContentValuesString($key: RuleContentKeys!, $contains: String) {
        suggestRuleContentValuesString(key: $key, contains: $contains)
    }
`);

const SingleContent = ({ contentKey, onContentKeyChange, contentOperator, onContentOperatorChange, contentValue, onContentValueChange, validContentKeys, onContentAddCondition, onContentDeleteCondition, contentIndex, contentLength, selectedOrganizationGenAiApplications }: SingleContentProps) => {
    const [inputValue, setInputValue] = useState('');
    const [localApplicationOptions, setLocalApplicationOptions] = useState<{ id: string, name: string, domain: string}[]>([]);
    const [localStringOptions, setLocalStringOptions] = useState<string[]>([]);
    const [chosenOptions, setChosenOptions] = useState(new Map<string, string>());
    const [localOptionsIs, setLocalOptionsIs] = useState(contentValue[0] || '');

    const { data: suggestRuleContentValuesOrganizationGenAiApplications } = useQuery(
        {
            queryKey: ["querySuggestRuleContentValuesOrganizationGenAiApplications", inputValue],
            queryFn: async () => {
                const { suggestRuleContentValuesOrganizationGenAiApplications } = await request(graphqlEndpoint, querySuggestRuleContentValuesOrganizationGenAiApplications, { contains: inputValue });
                return suggestRuleContentValuesOrganizationGenAiApplications
            }
        }
    );

    useEffect(() => {
        if (contentKey === RuleContentKeys.GenAiApplication) {
            const filteredOptions = new Map<string, string>();
            contentValue.forEach((value) => {
                if (selectedOrganizationGenAiApplications.has(value)) {
                    filteredOptions.set(value, selectedOrganizationGenAiApplications.get(value) as string);
                } else if (chosenOptions.has(value)) {
                    filteredOptions.set(value, chosenOptions.get(value) as string);
                }
            });
            setChosenOptions(filteredOptions);
        }
    }, [selectedOrganizationGenAiApplications, contentValue, contentKey, chosenOptions]);
    
    useEffect(() => {
        if (suggestRuleContentValuesOrganizationGenAiApplications !== undefined) {
            setLocalApplicationOptions(suggestRuleContentValuesOrganizationGenAiApplications);
        }
    }, [suggestRuleContentValuesOrganizationGenAiApplications, contentKey])

    const { data: suggestRuleContentValues } = useQuery(
        {
            queryKey: ["querySuggestRuleContentValues", contentKey, inputValue],
            queryFn: async () => {
                const { suggestRuleContentValuesString } = await request(graphqlEndpoint, querySuggestRuleContentValuesOrganizationString, { key: contentKey, contains: inputValue });
                return suggestRuleContentValuesString
            }
        }
    );

    useEffect(() => {
        if (suggestRuleContentValues !== undefined) {
            setLocalStringOptions(suggestRuleContentValues);
        }
    }, [suggestRuleContentValues])

    const onLocalContentKeyChange = (event: SelectChangeEvent) => {
        onContentKeyChange(event);
        setLocalApplicationOptions([]);
        setChosenOptions(new Map<string, string>());
    }

    const onLocalContentOperatorChange = (event: SelectChangeEvent) => {
        onContentOperatorChange(event);
        setChosenOptions(new Map<string, string>());
    }

    const onLocalIsValueChange = (event: any, value: string) => {
        setLocalOptionsIs(value);
        onContentValueChange(event, [value]);
    }

    const operatorToText = (operator: RuleContentOperators) => {
        switch (operator) {
            case RuleContentOperators.IsOne:
                return 'Is one of the following';
            case RuleContentOperators.Is:
                return 'Is';
            default:
                return operator;
        }
    }

    const genAiApplicationVerificationStatusToText = (genAiApplicationVerificationStatus: GenAiApplicationVerificationStatus) => {
        switch (genAiApplicationVerificationStatus) {
            case GenAiApplicationVerificationStatus.NonVerifiedGenAiApplication:
                return 'Non Verified GenAI Application';
            case GenAiApplicationVerificationStatus.VerifiedGenAiApplication:
                return 'Verified GenAI Application';
            default:
                return genAiApplicationVerificationStatus;
        }
    }

    const keyToText = (key: RuleContentKeys) => {
        switch (key) {
            case RuleContentKeys.UserEmail:
                return 'User Email';
            case RuleContentKeys.UserGroups:
                return 'User Groups';
            case RuleContentKeys.GenAiApplication:
                case RuleContentKeys.GenAiApplicationVerificationStatus:
                    return 'GenAI Application';
            default:
                return key;
        }
    }

    const validOperators = (key: RuleContentKeys): RuleContentOperators[] => {
        switch (key) {
            case RuleContentKeys.UserEmail:
                return [RuleContentOperators.IsOne];
            case RuleContentKeys.UserGroups:
                return [RuleContentOperators.IsOne];
            case RuleContentKeys.GenAiApplication:
                return [RuleContentOperators.IsOne, RuleContentOperators.Is];
            default:
                return [];
        }
    }

    const validVerificationStatus = (key: RuleContentKeys): GenAiApplicationVerificationStatus[] => {
        switch (key) {
            case RuleContentKeys.GenAiApplication:
                return [GenAiApplicationVerificationStatus.VerifiedGenAiApplication, GenAiApplicationVerificationStatus.NonVerifiedGenAiApplication];
            default:
                return [];
        }
    }

    return (
        <div style={{ display: 'flex', flexGrow: 1, width: '100%', alignItems: 'center', gap: '15px', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', flexGrow: 1, width: 'min(100%, 200px)', alignItems: 'center', gap: '15px' }}>
                <Select
                    value={contentKey}
                    renderValue={(value) => keyToText(value as RuleContentKeys)}
                    onChange={onLocalContentKeyChange}
                    size='small'
                    fullWidth
                    >
                        {Object.values(RuleContentKeys)
                            .filter(value => validContentKeys.includes(value))
                            .map((value, j) =>
                                <MenuItem key={j} value={value}>{keyToText(value)}</MenuItem>
                        )}
                </Select>
            </div>
            <div style={{ display: 'flex', flexGrow: 1, width: 'min(100%, 200px)', alignItems: 'center', gap: '15px' }}>
                <Select
                    value={contentOperator}
                    onChange={onLocalContentOperatorChange}
                    size='small'
                    fullWidth
                >
                    {Object.values(RuleContentOperators).filter(value => validOperators(contentKey).includes(value)).map((value, j) =>
                        <MenuItem key={j} value={value}>{operatorToText(value)}</MenuItem>
                    )}
                </Select>
            </div>
            <div style={{ display: 'flex', flexGrow: 5, width: 'min(100%, 200px)', alignItems: 'center', gap: '15px' }}>
                {(contentKey === RuleContentKeys.GenAiApplication) && contentOperator !== RuleContentOperators.Is && <Autocomplete
                    limitTags={7}
                    multiple
                    freeSolo
                    value={contentValue.map(valueId => chosenOptions.get(valueId)).filter(value => value !== undefined)}
                    onChange={(event, newValue) => {
                        const filteredNewValue = newValue.map(option => {
                            if (typeof option === 'string') {
                                for (let [id, name] of chosenOptions.entries()) {
                                    if (name === option) {
                                        return { id, name, domain: '' };
                                    }
                                }
                            }
                            return option;
                        }).filter((option): option is { id: string; name: string; domain: string; } => 
                            typeof option !== 'string'
                        );

                        const ids = new Set();
                        const uniqueNewValue = filteredNewValue.filter(option => {
                            if (ids.has(option.id)) {
                                return false;
                            } else {
                                ids.add(option.id);
                                return true;
                            }
                        });
                        onContentValueChange(event, uniqueNewValue.map(option => option.id));
                        const newChosenOptions = new Map<string, string>();
                        uniqueNewValue.forEach(option => {
                            if (!selectedOrganizationGenAiApplications.has(option.id)) {
                                selectedOrganizationGenAiApplications.set(option.id, option.name);
                            }
                            newChosenOptions.set(option.id, option.name);
                        });
                        setChosenOptions(newChosenOptions);
                    }}
                    filterOptions={(x) => x}
                    options={localApplicationOptions}
                    getOptionLabel={(option) => option ? (typeof option === 'string' ? option : option.name) : ''}
                    renderOption={(props, option: string | { id: string; name: string; domain: string; } | undefined) => 
                        option ? (typeof option === 'string' ? <li {...props}>{option}</li> : (
                            <li {...props}>
                                <div>
                                    <div style={{ fontSize: '1em', paddingLeft: '5px' }}>{option.name}</div>
                                    <div style={{ fontSize: '0.75em', paddingLeft: '5px', color: 'gray' }}>{option.domain}</div>
                                </div>
                            </li>
                        )) : null
                    }
                    size="small"
                    fullWidth
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            margin="none"
                            fullWidth
                        />
                    )}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                />}
                {(contentKey === RuleContentKeys.GenAiApplication) && contentOperator === RuleContentOperators.Is && <Select
                    value={localOptionsIs}
                    onChange={(event) => { onLocalIsValueChange(event, event.target.value) }}
                    size='small'
                    fullWidth
                >
                    {Object.values(GenAiApplicationVerificationStatus).filter(status => validVerificationStatus(contentKey).includes(status)).map((value, j) =>
                        <MenuItem key={j} value={value}>{genAiApplicationVerificationStatusToText(value)}</MenuItem>
                    )}
                </Select>
                }
                {(contentKey !== RuleContentKeys.GenAiApplication) && <Autocomplete
                    limitTags={7}
                    multiple
                    freeSolo
                    value={contentValue}
                    onChange={onContentValueChange}
                    filterOptions={(x) => x}
                    options={localStringOptions}
                    getOptionLabel={(option) => option}
                    size="small"
                    fullWidth
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            margin="none"
                        />
                    )}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                />
                }
            </div>
            <Button onClick={onContentAddCondition} disabled={contentIndex !== contentLength - 1}>AND</Button>
            <IconButton size='small' onClick={onContentDeleteCondition} aria-label="delete" disabled={contentLength === 1} style={{ borderRadius: '0' }}>
                <DeleteIcon />
            </IconButton>
        </div>
    )
}


export const SingleRuleContentBrowserExtension = ({ content, onChange, validContentKeys, selectedOrganizationGenAiApplications }: SingleRuleContentProps) => {
    const onValueChange = (index: number) => (event: any, value: string[]) => {
        const sortedValue = [...value].sort();
        const changedContent = content.map((x, i) => i === index ? {
            ...x,
            value: sortedValue
        } : x);
        onChange(changedContent);
    };

    const onAddCondition = () => (event: any) => {
        const changedContent: object[] = [...content];
        changedContent.push({
            key: '',
            operator: '',
            value: []
        });
        onChange(changedContent);
    };

    const onDeleteCondition = (index: number) => (event: any) => {
        const changedContent: object[] = [...content];
        changedContent.splice(index, 1);
        onChange(changedContent);
    };

    const onChangeKey = (index: number) => (event: any) => {
        const changedContent = content.map((x, i) => i === index ? {
            ...x,
            key: event.target.value,
            operator: '',
            value: []
        } : x);
        onChange(changedContent);
    }

    const onChangeOperator = (index: number) => (event: any) => {
        const changedContent = content.map((x, i) => i === index ? {
            ...x,
            operator: event.target.value,
            value: []
        } : x);
        onChange(changedContent);
    }

    return (
        <>
            {
                content.map((x, i) =>
                    <SingleContent key={i}
                        contentKey={ x.key === RuleContentKeys.GenAiApplicationVerificationStatus ? RuleContentKeys.GenAiApplication : x.key }
                        contentOperator={x.operator}
                        contentValue={x.value}
                        onContentKeyChange={onChangeKey(i)}
                        onContentOperatorChange={onChangeOperator(i)}
                        onContentValueChange={onValueChange(i)}
                        validContentKeys={validContentKeys}
                        onContentAddCondition={onAddCondition()}
                        onContentDeleteCondition={onDeleteCondition(i)}
                        contentIndex={i}
                        contentLength={content.length}
                        selectedOrganizationGenAiApplications={selectedOrganizationGenAiApplications}
                    />
                )
            }
        </>
    )
}
