import { useState } from 'react';
import { ConfigurationItem } from '../ConfigurationComponents/ConfigurationItem';
import { TypeRadio, typeDisplay } from '../ConfigurationComponents/TypeRadio';
import { Box } from '@mui/material';
import { TextRedactionModes } from '../../../gql/generated/graphql';

type ConfigurationItemTextRedactionProps = {
    inputRedactionMode: string;
    editMode: boolean;
    handleConfigurationChange: (mode: string) => void;
};

export const ConfigurationItemTextRedaction = ({ inputRedactionMode, editMode, handleConfigurationChange }: ConfigurationItemTextRedactionProps) => {
    const [redactionMode, setRedactionMode] = useState<string>(inputRedactionMode);

    if (redactionMode !== inputRedactionMode && !editMode) {
        setRedactionMode(inputRedactionMode);
    }

    const handleChange = (mode: string | number) => {
        setRedactionMode(mode as string);
        handleConfigurationChange(mode as string);
    };

    const displayModeText = (type: string | number): typeDisplay => {
        if (type === TextRedactionModes.DisplayAll) {
            return {text: 'Show All Texts',  helpText: 'All prompt and response texts are visible by default for all users'};
        } else {
            return {text: 'Hide and Expose Upon Request', helpText: 'Texts are hidden by default, with option for admins to reveal them after providing a justified reason, subject to audit'};
        }
    }

    return (
        <ConfigurationItem 
            configurationTitle='Viewing permissions'
            configurationHelpText='Configurable setting for managing visibility of prompts and responses in the portal. Changing the settings to “Hide and Expose Upon Request” will require a Portal user to indicate for every prompt/response why they would like to see the sensitive content. This action, including the reasoning entered, will be recorded in the audit log.'
        >
            <Box>
                <TypeRadio value={redactionMode} title={null} help={null} handleOnChange={handleChange} types={Object.values(TextRedactionModes)} typeDisplayText={displayModeText}/>
            </Box>
        </ConfigurationItem>
    )
};