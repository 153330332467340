/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PromptInjectionEngineFormStyle = {
    self: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
    `,
    insideFormContainer: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        background: var(--color-black-5);
        padding: 20px;
        border-radius: 10px;
    `,
}