/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const GenAiApplicationStyle = {
  self: css`
    display: flex;
    align-items: center;
    gap: 10px;
  `,
};
