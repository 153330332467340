/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const RiskStyle = {
  riskContainer: css`
    display: flex;
    align-items: center;
    gap: 6px;
  `,
};
