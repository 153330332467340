import {  Grid, Tooltip, FormLabel, Typography, Paper, Button } from "@mui/material";
import { PopupMessage } from "../../../components/PopupMessage";
import { useState } from "react";

type FileUploadProps = {
    value: string;
    title: string;
    help: string;
    disabled: boolean;
    handleOnChange: (value: string) => void;
};  
  
export const FileUpload = ({ value, title, help, disabled, handleOnChange }: FileUploadProps) => {
    const [openMessagePopup, setOpenPopupMessage] = useState<boolean>(false);
    const [popupText, setPopupText] = useState<string>('');
    const [popupTitle, setPopupTitle] = useState<string>('');

    const handlePopupMessageClose = () => {
        setOpenPopupMessage(false);
    }

    const isImageFile = (file: File) => {
        const acceptedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        return acceptedTypes.includes(file.type);
    };

    const isValidSizeFile = (file: File) => {
        if (file.size > 1 * (1024 * 1024)) {
            return false;
        }
        return true;
    };


    const uploadFile = (file: File | undefined) => {    
        if (file) {
            const isImage = isImageFile(file);
            const isValidSize = isValidSizeFile(file);
            if (isImage && isValidSize) {
                const reader = new FileReader();
            
                reader.onloadend = () => {
                    handleOnChange(reader.result as string);
                };
            
                reader.readAsDataURL(file);
            } else {
                setPopupTitle('Uploading File Failed');
                if (!isImage) {
                    setPopupText(`The file is not in a valid format. The supported formats are 'jpg', 'jpeg', 'png'`);
                } else {
                    setPopupText(`File size exceeds 1MB (${(file.size / (1024 * 1024)).toFixed(2)}MB). Please choose a smaller size.`);
                }
                setOpenPopupMessage(true);
            }
        }
    }
    
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        uploadFile(file);
    };
    
    const handleDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
    };
    
    const handleDrop = (event: React.DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
        const file = event.dataTransfer.files?.[0];
        uploadFile(file);
    };

    const handleClearImage = () => {
        handleOnChange('');
    };

    const textColor = disabled? "grey":  "black";
    
    return (
        <Grid container alignItems="center" sx={{ my: 2 }}>
            <Grid item xs={3}>
                <Tooltip key={title} title={help} placement='top-start'>
                    <FormLabel component="legend"  sx={{ paddingTop: 1 }}>{<span style={{ color: 'black' }}>{title}</span>}</FormLabel>
                </Tooltip>
            </Grid>
            <Grid item container xs={7} sx={{ mx: 14, marginRight: 0 }}>
                <Grid item xs={9} sx={{ marginRight: 0 }}>
                    <Paper elevation={1} sx={{ width: '90%', marginRight: 0 }}>
                        <label htmlFor={`image-upload-${title}`} onDragOver={handleDragOver} onDrop={handleDrop}>
                            {value ? (
                            <img
                                src={typeof value === 'string' ? value : ''}
                                style={{ maxWidth: '100%', maxHeight: '40px' }}
                                alt=''
                            />
                            ) : (
                                <Typography sx={{ mx: 8 }} color={textColor}>Drop or click to upload an image</Typography>
                            )}
                        </label>
                    </Paper>
                </Grid>
                <Grid item xs sx={{ marginRight: 0 }}>
                    <Button onClick={handleClearImage} disabled={!value} size="small">
                        Clear Image
                    </Button>
                </Grid>
                <input
                    key={title}
                    type="file"
                    id={`image-upload-${title}`}
                    accept=".png, .jpg, .jpeg"
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                    disabled={disabled}
                />
                
            </Grid>
            <PopupMessage open={openMessagePopup} title={popupTitle} text={popupText} handlePopupMessageClose={handlePopupMessageClose} />
        </Grid>
    );
}