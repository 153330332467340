/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const RoundedButtonStyle = {
    self: css`
        border: none;
        border-radius: 60px;
        padding: 10px 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        
        color: var(--color-white);

        background: var(--color-red);

        &:disabled {
            background-color: var(--color-black-50);
            color: var(--color-black-70);
            cursor: default;
        }

        &:not(:disabled) {
            &:hover {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), var(--color-red);
                box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.10);
            }
        
            &:active {
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), var(--color-red);
                box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.10);
            }
        }
    `
}