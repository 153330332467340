/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ColorThemeKeys } from "../../styles";

export const PSImageBoxStyle = {
  self: (paddingSizePx: number, size: number | string, borderColor: ColorThemeKeys) => css`
    border-radius: 13px;
    padding: ${paddingSizePx}px;
    ${size !== undefined ? `height: ${size}px;` : ""}
    aspect-ratio: 1/1;
    object: fit;
    background: var(--color-white);
    border: 1px solid var(--color-${borderColor});
    user-select: none;
    pointer-events: none;
  `,
  selfIcon: (paddingSizePx: number, size: number | string, borderColor: ColorThemeKeys) => css`
    ${PSImageBoxStyle.self(paddingSizePx, size, borderColor)}
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};
