/** @jsxImportSource @emotion/react */
import { Radio, RadioGroup, Slider, ToggleButton, ToggleButtonGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Text, ItemListInput, PSAutocomplete, PSFormControlLabel } from '../../../../ui-kit';
import { TOPICS_DETECTOR_TOPICS, IProtectionFormProps, TTopicsDetectorProtection } from '../Common';
import { TopicsDetectorFormStyle } from './TopicsDetectorForm.css';

const TopicsDetectorForm: React.FC<IProtectionFormProps<TTopicsDetectorProtection>> = (props) => {
    const { control, formName, protection, isAdvancedMode } = props;

    const { setValue } = useFormContext();

    const allTopics = useMemo(() => [...protection.Topics, ...protection.custom_topics], [protection.Topics, protection.custom_topics]);

    return (
        <div css={TopicsDetectorFormStyle.self}>
            <Controller
                name={`${formName}.Topics`}
                control={control}
                defaultValue={protection.Topics}
                render={({ field }) => (
                    <PSAutocomplete
                        {...field}
                        multiple
                        includeSelectAll
                        limitTags={-1}
                        optionsObject={TOPICS_DETECTOR_TOPICS}
                        outsideOnChange={field.onChange}
                        textFieldLabel='Monitor only Topics'
                    />
                )}
            />

            <Controller
                name={`${formName}.custom_topics`}
                control={control}
                defaultValue={protection.custom_topics}
                render={({ field }) => (
                    <ItemListInput
                        {...field}
                        editable={false}
                        label='Custom Topics'
                        placeholder='Enter custom topics'
                    />
                )}
            />

            <Controller
                name={`${formName}.filter_type`}
                defaultValue={protection.filter_type}
                control={control}
                render={({ field }) => (
                    <div>
                        <Text>Action</Text>
                        <RadioGroup
                            {...field}
                            row
                            value={field.value}
                        >
                            <PSFormControlLabel value={'Allowed'} control={<Radio size='small' />} label="Allowed" />
                            <PSFormControlLabel value={'Blocked'} control={<Radio size='small' />} label="Blocked" />
                        </RadioGroup>
                    </div>
                )}
            />

            {allTopics.length > 0 && (
                <TableContainer component="div">
                    <Table>
                        <TableHead>
                            <TableRow style={{ height: 'auto' }}>
                                <TableCell style={{ padding: '10px 15px' }}>
                                    <Text variant='smallBold' color='black-50'>TOPIC</Text>
                                </TableCell>
                                <TableCell align="right" style={{ padding: '10px 15px' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allTopics.map((topic) => (
                                <TableRow key={topic} style={{ height: 'auto' }}>
                                    <TableCell component="th" scope="row" style={{ padding: '10px 15px' }}>
                                        <Text>{topic}</Text>
                                    </TableCell>
                                    <TableCell align="right" style={{ padding: '10px 15px' }}>
                                        <ToggleButtonGroup
                                            color="primary"
                                            size='small'
                                            exclusive
                                            value={protection.filter.includes(topic || '') ? 'filter' : 'monitor'}
                                            onChange={(e, value = '') => {
                                                if (value === '') return;

                                                if (value === 'monitor') {
                                                    setValue(`${formName}.filter`, protection.filter.filter((t) => t !== topic), { shouldDirty: true, shouldTouch: true });
                                                } else {
                                                    setValue(`${formName}.filter`, [...protection.filter, topic], { shouldDirty: true, shouldTouch: true });
                                                }
                                            }}
                                        >
                                            <ToggleButton value="monitor">
                                                <Text variant='small'>Monitor Only</Text>
                                            </ToggleButton>
                                            <ToggleButton value="filter">
                                                <Text variant='small'>{protection.filter_type === 'Allowed' ? 'Allowed' : 'Blocked'}</Text>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {isAdvancedMode && <Controller
                name={`${formName}.threshold`}
                control={control}
                defaultValue={protection.threshold}
                render={({ field }) => (
                    <div>
                        <Text>Threshold</Text>
                        <Slider
                            {...field}
                            min={0}
                            max={1}
                            step={0.05}
                            valueLabelDisplay="auto"
                            marks={[
                                { value: 0, label: '0' },
                                { value: 0.25, label: '0.25' },
                                { value: 0.5, label: '0.5' },
                                { value: 0.75, label: '0.75' },
                                { value: 1, label: '1' },
                            ]}
                        />
                    </div>
                )}
            />}
        </div>
    )
}

export default TopicsDetectorForm;