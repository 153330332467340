import { Box } from '@mui/material';
import { ConfigurationViewer } from './ConfigurationViewer';

export const ManageConfiguration = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', flex: 1 }}>
            <ConfigurationViewer />
        </Box>
    )

}