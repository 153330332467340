/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import CodeDetectorForm from './CodeDetectorForm';

const CodeDetectorProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode } = props;

    return (
        <ProtectionWrapper
            label="Code Detector"
            description="Block code snippets in your prompts or responses"
            formName="Code Detector"
            Component={CodeDetectorForm}
            control={control}
            getValues={getValues}
            isAdvancedMode={isAdvancedMode}
            summary={`The Code Detector can alert, block, or ignore specific programming languages in prompts or responses. By default, it alerts and logs any detected language but doesn't block them. You can block languages by moving them to the "Block" column, or ignore them to prevent alerts or logs.`}
        />
    )
}

export default CodeDetectorProtection;