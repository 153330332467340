import { css } from "@emotion/react";
import { TFilledButtonVariantType } from "./PSButton";

export const FilledButtonStyle = (variantType: TFilledButtonVariantType) => {
  let baseColor = 'blue';

  switch (variantType) {
    case 'critical':
      baseColor = 'red';
      break;
    case 'info':
      baseColor = 'blue';
      break;
  }

  return {
    self: css`
    border-radius: 40px;
    border: none;
    padding: 10px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    max-width: 100%;

    color: var(--color-white);
    background: var(--color-${baseColor});

    & .icon {
      width: 20px;
      height: 20px;
      transition: none;
      fill: var(--color-white);
    }

    &:disabled {
      background: var(--color-black-50);
      color: var(--color-black-70);
      & .icon {
        fill: var(--color-black-70);
      }
      cursor: default;
    }

    :not(:disabled) {
      &:hover {
        background: var(--color-${baseColor}-dark);
      }

      &:active,
      &.active {
        background: var(--color-${baseColor}-light);
      }
    }
  `,
  }
};
