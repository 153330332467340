/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { ResponsiveLine, LineSvgProps } from '@nivo/line'
import { css, SerializedStyles } from '@emotion/react';
import { LineChartStyle } from './LineChart.css';
import NoData from '../../NoData/NoData';
import { Chip } from '@mui/material';
import Circle from '../../Circle/Circle';

export type DataItem = {
    id: string;
    label?: string;
    color?: string;
    data: Array<{ x: string; y: number }>;
}

type IProps = {
    data: Array<DataItem>;
    isLoading?: boolean;
    minWidth: number | string;
    height: number | string;
    containerCss?: SerializedStyles;
    customColors?: string[];
    maxLines?: number;
} & LineSvgProps;

const generateLoadingData = (numItems: number): Array<DataItem> => {
    return Array(numItems).fill(undefined).map((_, index) => ({
        id: index.toString(),
        label: 'Loading',
        color: 'var(--color-black-25)',
        data: Array(10).fill(undefined).map((_, index) => ({
            x: index.toString(),
            y: Math.floor(Math.random() * 100) + 10,
        }))
    }));
}

const LineChart: React.FC<IProps> = (props) => {
    const { data, minWidth = 0, height = 0, isLoading, containerCss, customColors, maxLines, ...lineChartProps } = props;
    const [loadingData, setLoadingData] = React.useState<Array<DataItem>>(generateLoadingData(5));
    const [othersData, setOthersData] = React.useState<Array<DataItem> | null>(null);
    const [restData, setRestData] = React.useState<Array<DataItem>>([]);
    const [dataToShow, setDataToShow] = React.useState<Array<DataItem>>(data);

    useEffect(() => {
        setDataToShow(data);
    }, [data]);

    useEffect(() => {
        if (!data) return;
        if (!maxLines) return;
        if (data.length - 1 <= maxLines) return;
        const sortedData = data.sort((a, b) => {
            const bCount = b.data.reduce((acc, curr) => acc + curr.y, 0);
            const aCount = a.data.reduce((acc, curr) => acc + curr.y, 0);
            return bCount - aCount;
        });
        const slicedData = sortedData.slice(0, maxLines);
        const restData = sortedData.slice(maxLines);
        const othersData = {
            id: '_others_',
            label: 'Others',
            color: 'var(--color-black-25)',
            data: restData.reduce((acc, item) => {
                return item.data.map((x, index) => ({
                    x: x.x,
                    y: (acc[index]?.y || 0) + x.y,
                }))
            }, [] as { x: string, y: number }[])
        }

        setOthersData(restData);
        setRestData(slicedData);
        setDataToShow([...slicedData, othersData]);
    }, [data, maxLines])

    useEffect(() => {
        if (!customColors) return;
        setDataToShow((prevData) => prevData.map((item, index) => ({
            ...item,
            color: item.id === '_others_' ? item.color : customColors[index % customColors.length],
        })));
    }, [data])

    const blockPieInteractiveProps = {
        isInteractive: false,
        enablePointLabel: false,
        enablePoints: false,
    };

    if (isLoading) {
        return (
            <div css={[LineChartStyle.lineChart(minWidth, height), containerCss]}>
                <ResponsiveLine
                    {...lineChartProps}
                    {...blockPieInteractiveProps}

                    xFormat={undefined}
                    yFormat={undefined}

                    xScale={{
                        type: 'point',
                    }}

                    axisBottom={{
                        "tickSize": 0,
                        "tickPadding": 5,
                        "tickRotation": 0,
                        format: () => ''
                    }}

                    axisLeft={{
                        "tickSize": 0,
                        "tickPadding": 5,
                        "tickRotation": 0,
                        format: () => ''
                    }}


                    data={loadingData}
                />
            </div>
        );
    }

    if (dataToShow.length === 0) {
        return (
            <div>
                <NoData />
            </div>
        )
    }

    return (
        <div css={css`display: flex; gap: 10px 40px; align-items: center; flex-wrap: wrap; height: 100%;`}>
            <div css={[LineChartStyle.lineChart(minWidth, height), containerCss]}>
                <ResponsiveLine
                    {...lineChartProps}
                    data={dataToShow}
                />
            </div>
            <div css={css`display: flex; gap: 10px; flex-wrap: wrap; width: max(200px, 100%);`}>
                {dataToShow.map(x => (
                    <Chip
                        clickable={x.id === '_others_'}
                        onClick={() => {
                            if (x.id === '_others_') {
                                // setIsOthersVisible(true);
                            }
                        }}
                        key={x.id}
                        variant='outlined'
                        label={x.id === '_others_' ? `Others (+${othersData?.length})` : x.id}
                        icon={
                            <Circle
                                size={9}
                                nativeColor={x.color}
                                circleCss={css`margin-left: 10px;`}
                            />
                        }
                    />
                ))}
            </div>
        </div>
    )
}

export default LineChart;