/** @jsxImportSource @emotion/react */
import React from 'react';
import { TextField } from '@mui/material';
import { PSDialog, Text } from '../../../ui-kit';
import { css } from '@emotion/react';

type IProps = {
    open: boolean;
    onClose: () => void;
    onRename: (name: string) => Promise<any>;
    currentName: string;
    connectorTypeName: string;
};

const ManageConnectorTopBarRenameDialog: React.FC<IProps> = (props) => {
    const { open, onClose, onRename, currentName, connectorTypeName } = props;

    const [renameValue, setRenameValue] = React.useState(currentName);

    const onAction = async () => {
        await onRename(renameValue);
    }

    return (
        <PSDialog
            open={open}
            onClose={onClose}
            title={`Rename ${connectorTypeName} Connector`}
            action={onAction}
            actionButtonText='Rename'
            isActionDisabled={renameValue.trim() === '' || renameValue === currentName}
        >
            <div css={css`display: flex; flex-direction: column; gap: 20px;`}>
                <Text>Enter the new name for the {connectorTypeName.toLowerCase()} connector.</Text>
                <TextField
                    value={renameValue}
                    onChange={(e) => setRenameValue(e.target.value)}
                    autoFocus
                    variant='outlined'
                    size='small'
                    label='Name'
                    fullWidth
                    placeholder='Enter the name of the connector'
                />
            </div>
        </PSDialog>
    )
}

export default ManageConnectorTopBarRenameDialog;