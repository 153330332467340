import { handleLogsExtraction } from "./LogsExtraction/LogsExtraction";
import { handleTrueClassification } from "./ClassificationReport/TrueClassification";
import { handleFalseClassification } from "./ClassificationReport/FalseClassification";
import { TextRedactionModes } from "../gql/generated/graphql";

const getRowPageIndex = (iframeDocument: Document, rowIndex: number): number => {
    const paginationButton = iframeDocument.querySelector('button[data-test-subj*="tablePaginationPopoverButton"]');
    const paginationInfo = paginationButton?.querySelector('span[class="euiButtonEmpty__text"]')?.textContent;
    
    if (paginationInfo) {
        const match = paginationInfo.match(/\d+/);
        if (match) {
            const rowsPerPage = parseInt(match[0], 10);
            return rowIndex % rowsPerPage;
        }
    }
    return rowIndex;       
}

export const handleCustomButtons = (iframeDocument: Document, dashboardType: string, textRedactionMode: string) => {

    const getPageIndex = (iframeDocument: Document): [number, number] => {
        const pageInfo = iframeDocument.querySelector('p[id*="Page"]')?.textContent;

        if (pageInfo) {
            const match = pageInfo.match(/Page (\d+) of (\d+)/);
            if (match) {
                return [parseInt(match[1], 10), parseInt(match[2], 10)];
            }
        }
        return [1, 1];
    }


    const displayLogsExtraction = textRedactionMode !== TextRedactionModes.DisplayAll && (dashboardType === 'ps-logs' || dashboardType === 'ps-alerts');
    const displayAlertsReport = dashboardType === 'ps-alerts';
    const displayLogsReport = dashboardType === 'ps-logs';
    let numberOfButtons = 1;  // the review document button (default)
    
    if (displayLogsExtraction === true) {
        numberOfButtons += 1;
    }
    if (displayAlertsReport === true) {
        numberOfButtons += 2;
    }
    if (displayLogsReport === true) {
        numberOfButtons += 2;
    }

    if (numberOfButtons === 1) {
        return;
    }

    let marginMovement = 0;
    if  (numberOfButtons === 2) {
        marginMovement = 20;
    } else if (numberOfButtons === 3) {
        marginMovement = 50;
    } else if (numberOfButtons === 4) {
        marginMovement = 80;
    }

    const firstColumnHeader = iframeDocument.querySelectorAll('div[data-test-subj="dataGridHeaderCell-inspectCollapseColumn"]');
    firstColumnHeader.forEach((header) => {
        (header as any).style.width = `${30 * numberOfButtons}px`;
    });

    const cells = iframeDocument.querySelectorAll('div[data-test-subj="dataGridRowCell"]');
    cells.forEach((cell) => {
        if (cell.getAttribute('class')?.includes('--firstColumn')) {
            const cellDataFocus = cell.querySelector('div[data-focus-lock-disabled="disabled"]');
            (cellDataFocus as any).style.width = '30px';
            const cellInfo = cell.querySelector('p[class="euiScreenReaderOnly"]')?.textContent;
            if (cellInfo) {
                const regex = /Row:\s(\d+)/;
                let match = regex.exec(cellInfo);
                if (match) {
                    const rowIndexString = match[1];
                    const rowIndex = parseInt(rowIndexString, 10) - 1;

                    const rowPageIndex = getRowPageIndex(iframeDocument, rowIndex);
                    const [pageIndex, pagesNumber] = getPageIndex(iframeDocument);
                    
                    (cell as any).style.width = `${30 * numberOfButtons}px`;

                    if (displayAlertsReport) {
                        handleFalseClassification(iframeDocument, pagesNumber, pageIndex, rowPageIndex, rowIndex, cell);
                        handleTrueClassification(iframeDocument, pagesNumber, pageIndex, rowPageIndex, rowIndex, cell);
                    }

                    if (displayLogsReport) {
                        handleFalseClassification(iframeDocument, pagesNumber, pageIndex, rowPageIndex, rowIndex, cell,);
                        handleTrueClassification(iframeDocument, pagesNumber, pageIndex, rowPageIndex, rowIndex, cell);
                    }

                    if (displayLogsExtraction) {
                        handleLogsExtraction(iframeDocument, pagesNumber, pageIndex, rowPageIndex, rowIndex, cell);
                    }
                }
            }
    } else {
        (cell as any).style.marginLeft = `${(cell as any).style.marginLeft + marginMovement}px`;
    }
    });
}