/** @jsxImportSource @emotion/react */
import React from 'react';
import { AlertsByViolation, GenAiAppsInUsed, LLMByUsage, RiskyUsersAndGroups } from '../Widgets';
import { ExtensionPageStyle } from './ExtensionPage.css';

type IProps = {

};

const ExtensionPage: React.FC<IProps> = (props) => {


    const { } = props;

    return (
        <div css={ExtensionPageStyle.self}>
            <GenAiAppsInUsed />
            <LLMByUsage />
            <AlertsByViolation />
            <RiskyUsersAndGroups />
        </div>
    )
}

export default ExtensionPage;