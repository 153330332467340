/** @jsxImportSource @emotion/react */
import React, { useLayoutEffect } from 'react';
import Select, { SelectProps } from '@mui/material/Select';
import { KeyboardArrowRight } from '@mui/icons-material';
import { PSSelectStyle } from './PSSelect.css';
import PSMenuItem from '../PSMenuItem/PSMenuItem';
import Text from '../Text/Text';
import { PopoverOrigin } from '@mui/material';
import * as CSS from 'csstype';

type TOption = {
    value: string | number;
    label: string;
}

type PSSelectProps = {
    options?: TOption[];
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    paperMargin?: CSS.Property.Margin;
    description?: string;
} & SelectProps;

const PSSelect: React.FC<PSSelectProps> = (props) => {
    const {
        options = [],
        value,
        anchorOrigin = {
            vertical: 'bottom',
            horizontal: 'right',
        },
        transformOrigin = {
            vertical: 'bottom',
            horizontal: 'left',
        },
        paperMargin = '0 0 0 20px',
        description,
        ...selectProps
    } = props;

    const selectRef = React.useRef<HTMLDivElement>(null);

    const [selectWidthPx, setSelectWidthPx] = React.useState<number>(0);
    const [isOpened, setIsOpened] = React.useState<boolean>(false);

    useLayoutEffect(() => {
        if (!description) return;

        const select = selectRef.current;
        if (!select) return;
        const observer = new ResizeObserver(() => {
            const selectWidth = select.offsetWidth;
            setSelectWidthPx(selectWidth);
        });
        observer.observe(select);

        if (isOpened) {
            observer.disconnect();
        }
        return () => {
            observer.disconnect();
        }
    }, [description, isOpened])


    return (
        <Select
            {...selectProps}
            value={value}
            css={PSSelectStyle.self}
            ref={selectRef}
            IconComponent={KeyboardArrowRight}
            onOpen={() => setIsOpened(true)}
            onClose={() => setIsOpened(false)}
            MenuProps={{
                anchorOrigin,
                transformOrigin,
                slotProps: {
                    paper: {
                        style: {
                            backgroundColor: 'var(--color-white)',
                            borderRadius: '10px',
                            boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.15)',
                            margin: paperMargin
                        },
                        sx: {
                            '& .MuiList-root': {
                                padding: '0',
                            }
                        }
                    },
                }
            }}
        >
            {options.map(option => (
                <PSMenuItem
                    key={option.value}
                    value={option.value}
                >
                    {option.label}
                </PSMenuItem>
            ))}
            {description && <div css={PSSelectStyle.description(selectWidthPx)}>
                <Text inline variant='small' color='black-70' >
                    {description}
                </Text>
            </div>}
        </Select>
    );
}

export default PSSelect;