/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import SecretsForm from './SecretsForm';

const SecretsProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, connectorType } = props;

    return (
        <ProtectionWrapper
            label="Secrets"
            description="Prevent exposure of secrets, e.g. API keys and cloud tokens"
            formName="Secrets"
            Component={SecretsForm}
            control={control}
            getValues={getValues}
            summary={`This “Secrets” protection is designed to prevent sensitive information, such as GitHub tokens, slack access keys, and AWS keys, from appearing in your prompts or responses. This is especially critical for developers, who are routinely handling such secrets, and are at higher risk of exposing them to genAI coding assistants. You may choose to either redact these secrets or block the prompt/response entirely. Prompt Security currently supports a growing list of over 100 different types of secrets.`}
            excludePromptOrResponse={connectorType === 'developers' ? 'response' : undefined}
        />
    )
}

export default SecretsProtection;