/** @jsxImportSource @emotion/react */
import React from 'react';
import { PSLoadingScreenStyle } from './PSLoadingScreen.css';

type IProps = {

};

const PSLoadingScreen: React.FC<IProps> = (props) => {
    const { } = props;

    return (
        <div css={PSLoadingScreenStyle.self}>
            <div>Loading...</div>
        </div>
    )
}

export default PSLoadingScreen;