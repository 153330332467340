

import { Box } from '@mui/material';
import { ApplicationsViewer } from './ApplicationsViewer';
import { ApplicationAdd } from './ApplicationAdd';
import { useState } from 'react';
import { PopupMessage } from '../../components/PopupMessage';

export const ManageApplications = () => {
    const [openMessagePopup, setOpenPopupMessage] = useState<boolean>(false);
    const [popupText, setPopupText] = useState<string>('');
    const [popupTitle, setPopupTitle] = useState<string>('');

    const handlePopupMessageOpen = (title: string, text: string) => {
        setPopupTitle(title);
        setPopupText(text);
        setOpenPopupMessage(true);
    }

    const handlePopupMessageClose = () => {
        setOpenPopupMessage(false);
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <ApplicationsViewer handlePopupMessage={handlePopupMessageOpen} />
            <ApplicationAdd handlePopupMessage={handlePopupMessageOpen} />
            <PopupMessage open={openMessagePopup} title={popupTitle} text={popupText} handlePopupMessageClose={handlePopupMessageClose} />
        </Box>
    )

}