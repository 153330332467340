/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const RightDrawerStyle = {
  drawerContainer: (drawerWidth: number) => css`
    position: relative;
    width: ${drawerWidth}px;
    transition: width 100ms ease-in-out !important;
    flex-shrink: 0;
    & .MuiDrawer-paper {
      width: ${drawerWidth}px;
      transition: width 100ms ease-in-out !important;
    }
  `,
  toggleDrawerButton: (isDrawerOpen: boolean) => css`
    position: absolute;
    left: ${isDrawerOpen ? '0' : '0'};
    top: 80px;
    z-index: 1201;
    border-radius: 50%;
    transform: translateX(-50%);
    transition: left 100ms ease-in-out;

    background-color: var(--color-blue);

    &:hover {
      background-color: var(--color-blue-dark);
    }

    &:active {
      background-color: var(--color-blue-light);
    }
    `,
  drawerContentMinimizedState: css`
      display: none;
    `
}