/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps } from '../Common/protections_types';
import ProtectionWrapper from '../Common/ProtectionWrapper/ProtectionWrapper';
import PromptInjectionEngineForm from './PromptInjectionEngineForm';

const PromptInjectionEngineProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode } = props;

    return (
        <ProtectionWrapper
            control={control}
            label="Prompt Injection Engine"
            getValues={getValues}
            description="Block malicious prompt injection attempts"
            formName="Prompt Injection Engine"
            Component={isAdvancedMode ? PromptInjectionEngineForm : undefined}
            isAdvancedMode={isAdvancedMode}
            summary={`Prompt Security's primary defense against Prompt Injection attempts is the PI Engine. This engine utilizes a LLM to analyze user prompts, evaluate them, and determine if they represent an attempt at prompt injection. If such an attempt is identified, it is promptly blocked. While we also support more conventional methods, such as comparing the user prompt with established prompt injection templates, the PI Engine excels at detecting new and innovative methods of prompt injection.`}
        />
    )
}

export default PromptInjectionEngineProtection;