/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import UrlsDetectorForm from './UrlsDetectorForm';

const UrlsDetectorProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues } = props;

    return (
        <ProtectionWrapper
            control={control}
            label="URLs Detector"
            getValues={getValues}
            description="Block unwanted URLs in prompts and responses"
            formName="URLs Detector"
            Component={UrlsDetectorForm}
            summary={`The URL detector prevents undesired or malicious URLs from making it into your prompt or response. For example, you may decide to block a domain like evilcorp.com,  as they are a competitor of yours, or you may decide to block employees from sending internal sensitive URLs (e.g. salaries.HR.Acme.com) to any third party LLM provider.`}
        />
    )
}

export default UrlsDetectorProtection;