/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import TopicsDetectorForm from './TopicsDetectorForm';

const TopicsDetectorProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode } = props;

    return (
        <ProtectionWrapper
            control={control}
            label="Topics Detector"
            getValues={getValues}
            description="Classify and control conversation topics in prompts and responses"
            formName="Topics Detector"
            Component={TopicsDetectorForm}
            isAdvancedMode={isAdvancedMode}
            summary={`The Topic Detector Protection offers AI-powered classification of topics within prompts or responses. This lets you understand the subject matter in your AI application more accurately and define which topics are permitted and which are not.`}
        />
    )
}

export default TopicsDetectorProtection;



