/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PSGenAIAddCardStyle = {
    self: css`
        border-radius: 15px;
        border: 1px solid var(--color-black-25);
        background: var(--color-black-5);
        padding: 15px;
        cursor: pointer;
        user-select: none;

        display: flex;
        align-items: center;
        gap: 10px;

        &:not(.disabled) {
            &:hover {
            border-color: var(--color-black);
            }

            &.isSelected {
                border-color: var(--color-blue);
                background: var(--color-white);
            }
        }

        &:focus-visible {
            outline: 2px solid var(--color-blue-light);
            outline-offset: -2px;
        }

        &.disabled {
            cursor: default;
            pointer-events: none;
            border-color: var(--color-black-50);
            color: var(--color-black-50);
            img {
                filter: grayscale(100%);
            }
        }
  `,
    textContainer: css`
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;
    `,
    labelText: css`
        background: var(--color-blue-25);
        padding-inline: 10px;
        border-radius: 15px;
    `,
    iconContainer: css`
        margin-left: auto;
    `
}