/** @jsxImportSource @emotion/react */
import React from 'react';
import { PSAutocomplete } from '../../../../ui-kit';
import { objectWithoutListOfValues } from '../../../../utils';
import { IProtectionFormProps, TLanguageDetectorProtection, LANGUAGES } from '../Common';
import { Controller } from 'react-hook-form';
import { LanguageDetectorFormStyle } from './LanguageDetectorForm.css';


const LanguageDetectorForm: React.FC<IProtectionFormProps<TLanguageDetectorProtection>> = (props) => {
    const { formName, protection, control } = props;

    const allowedLanguages = protection.allowed;
    const deniedLanguages = protection.denied;

    return (
        <div css={LanguageDetectorFormStyle.self}>
            <Controller
                name={`${formName}.allowed`}
                control={control}
                defaultValue={protection.allowed}
                render={({ field }) => (
                    <PSAutocomplete
                        {...field}
                        multiple
                        includeSelectAll
                        optionsObject={objectWithoutListOfValues(LANGUAGES, deniedLanguages)}
                        textFieldLabel='Allowed Languages'
                        outsideOnChange={field.onChange}
                    />
                )}
            />

            <Controller
                name={`${formName}.denied`}
                control={control}
                defaultValue={protection.denied}
                render={({ field }) => (
                    <PSAutocomplete
                        {...field}
                        multiple
                        includeSelectAll
                        optionsObject={objectWithoutListOfValues(LANGUAGES, allowedLanguages)}
                        textFieldLabel='Denied Languages'
                        outsideOnChange={field.onChange}
                    />
                )}
            />
        </div>
    )
}

export default LanguageDetectorForm;

