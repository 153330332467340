/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const RiskBarStyle = {
  self: css`
    flex: 1;
    position: relative;
  `,
  bar: css`
    width: 100%;
    height: 2px;
    border-radius: 12px;
    background: var(--color-black-25);
  `,
  barThumb: (percentage: number, color: string) => css`
    position: absolute;
    background: ${color};
    height: 8px;
    width: 30px;
    border-radius: 12px;
    left: ${percentage}%;
    top: 50%;
    transform: translate(-${percentage}%, -50%);
  `,
};
