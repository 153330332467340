/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const LLMByUsageStyle = {
  self: css``,
  errorContainer: css`
    display: grid;
    place-items: center;
    height: 100%;
  `,
  pieChartContainer: css`
    margin: 0 auto;
    margin-top: 45px;
  `,
};
