/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { PSNavigationBuilder, Icon } from '../ui-kit';
import { NavLink, useLocation } from 'react-router-dom';
import { NavigationMenuStyle } from './NavigationMenu.css';
import { PSIcon, PSLogo } from '../assets/images';
import clsx from 'clsx';
import NavigationMenuUser from './NavigationMenuUser';
import { TNavigationMenu } from '../ui-kit/PSNavigationBuilder/PSNavigationBuilder';
type IProps = {
    isCollapsed: boolean;
    handleToggleCollapse: (isCollapse?: boolean) => void
};

const handleResourceCenterClick = () => {
    if (process.env.NODE_ENV === 'development') return null;
    //@ts-ignore
    pendo?.showGuideById('TJz76U_l3gYGIq55_Sgh0RPmUEY')
}

const navigationMenuConfig: TNavigationMenu = [
    {
        type: 'category',
        name: 'Dashboard',
        collapseIcon: 'PSDashboardIcon',
        items: [
            { type: 'item', icon: 'PSHomegrownIcon', label: 'Homegrown Apps', path: '/appsec-dashboard' },
            { type: 'item', icon: 'PSEmployeesIcon', label: 'Employees', path: '/extension' },
            { type: 'item', icon: 'PSDevIcon', label: 'Developers', path: '/code-assistant' },
        ]
    },
    {
        type: 'section',
        items: [
            { type: 'item', icon: 'PSActivityMonitorIcon', label: 'Activity Monitor', path: '/activity-monitor', isSelectedPaths: ['/'] },
            { type: 'item', icon: 'PSAuditIcon', label: 'Audit', path: '/audit' },
        ]
    },
    {
        type: 'category',
        name: 'Manage',
        collapseIcon: 'PSBuildIcon',
        items: [
            { type: 'item', icon: 'PSHomegrownIcon', label: 'Homegrown Apps', path: '/homegrown-applications/manage/:connectorName' },
            { type: 'item', icon: 'PSEmployeesIcon', label: 'Employees', path: '/employees/manage/:connectorName' },
            { type: 'item', icon: 'PSDevIcon', label: 'Developers', path: '/developers/manage/:connectorName' },
            { type: 'item', icon: 'PSIntegrationsIcon', label: 'Integrations', path: '/manage/integrations' },
            { type: 'item', icon: 'PSSettingsIcon', label: 'Configuration', path: '/manage/configuration' },
        ]
    },
    { type: 'item', icon: 'PSLearningIcon', label: 'Resource Center', onClick: handleResourceCenterClick }
]

const NavigationMenu: React.FC<IProps> = (props) => {
    const { isCollapsed, handleToggleCollapse } = props;
    const [showCollapseButton, setShowCollapseButton] = useState(false);
    const location = useLocation();
    const navigationRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            if (navigationRef.current) {
                const rect = navigationRef.current.getBoundingClientRect();
                const isNearNavigation = e.clientX <= rect.right + 50;
                setShowCollapseButton(isNearNavigation);
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <React.Fragment>
            <div
                css={NavigationMenuStyle.collapseButton(showCollapseButton, isCollapsed)}
                className={clsx({ 'collapsed': isCollapsed })}
                onClick={() => handleToggleCollapse()}
            >
                <Icon color='black-50' iconName={isCollapsed ? 'PSChevronRightIcon' : 'PSChevronLeftIcon'} />
            </div>
            <div css={NavigationMenuStyle.self} className={clsx({ 'collapsed': isCollapsed })} ref={navigationRef}>
                <NavLink to={{ pathname: '/', search: location.search }} css={NavigationMenuStyle.promptLogoContainer}>
                    {isCollapsed ?
                        <PSIcon style={{ margin: '0 auto' }} width={28} height={28} />
                        : <PSLogo style={{ marginLeft: 'var(--spacing-lg)' }} height={28} />
                    }
                </NavLink>

                <div css={NavigationMenuStyle.navigation}>
                    <PSNavigationBuilder
                        currentPath={location.pathname}
                        isCollapsed={isCollapsed}
                        navigation={navigationMenuConfig}
                    />
                </div>

                <div css={NavigationMenuStyle.userMenuContainer}>
                    <NavigationMenuUser isCollapsed={isCollapsed} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default NavigationMenu;