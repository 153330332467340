import * as _ from "lodash";

export const differenceBetweenObjects = (
  object1: Record<string, any>,
  object2: Record<string, any>
): Record<string, any> => {
  const diff = Object.keys(object2).reduce((diff, key) => {
    if (object1[key] === object2[key]) return diff;
    return {
      ...diff,
      [key]: object2[key],
    };
  }, {});
  return diff;
};

export function deepDifferenceBetweenObjects(
  object: Record<string, any>,
  base: Record<string, any>
): Record<string, any> {
  function changes(object: Record<string, any>, base: Record<string, any>) {
    return _.transform(
      object,
      function (result: Record<string, any>, value, key) {
        if (!_.isEqual(value, base[key])) {
          result[key] =
            _.isObject(value) && _.isObject(base[key])
              ? changes(value, base[key])
              : value;
        }
      }
    );
  }
  return changes(object, base);
}

export const objectWithoutListOfValues = <T extends Record<string, any>>(obj: T, listOfValues: Array<string | undefined>) => Object.entries(obj).reduce((acc, [key, value]) => {
  if (!listOfValues.includes(key)) {
    return {
      ...acc,
      [key]: value
    }
  }
  return acc;
}, {});

type DeepPartial<T> = T extends (infer U)[]
  ? DeepPartial<U>[]
  : T extends object
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : T;

export function deepMergeUniqueProperties<T extends object, U extends object>(
  baseObject: T,
  additionalObject: U
): T & DeepPartial<U> {
  const result = Array.isArray(baseObject) ? [...baseObject] : { ...baseObject };

  for (const key in additionalObject) {
    if (Object.prototype.hasOwnProperty.call(additionalObject, key)) {
      if (!(key in baseObject)) {
        // If the key doesn't exist in baseObject, we add it from additionalObject
        (result as any)[key] = additionalObject[key];
      } else if (
        typeof (baseObject as any)[key] === 'object' &&
        typeof additionalObject[key] === 'object'
      ) {
        if (Array.isArray((baseObject as any)[key])) {
          // If it's an array in baseObject, we keep it as is
          (result as any)[key] = (baseObject as any)[key];
        } else {
          // If it's an object, we recursively merge
          (result as any)[key] = deepMergeUniqueProperties(
            (baseObject as any)[key],
            additionalObject[key] as object
          );
        }
      }
      // If the key exists in baseObject and is not an object, we keep the baseObject value
    }
  }

  return result as T & DeepPartial<U>;
}