/** @jsxImportSource @emotion/react */
import React from 'react';
import { Text } from '../../../../../ui-kit';
import { css } from '@emotion/react';

type IProps = {};

const Kandji: React.FC<IProps> = (props) => {
    const { } = props;

    return (
        <div css={css`display: flex; flex-direction: column; gap: 15px;`}>
            <Text>The goal is to install the above mobileconfig configuration profile on the mac devices of your employees</Text>
            <ol css={css`list-style: number; margin-left: 30px; display: flex; flex-direction: column; gap: 10px;`}>
                <li><Text>In Kandji, click <b>Library</b> in the left-hand navigation pane.</Text></li>
                <li><Text>Click <b>Configuration Profiles</b>.</Text></li>
                <li><Text>Click the <b>New Profile</b> button and select <b>Upload a Profile</b>.</Text></li>
                <li><Text>Upload the configuration profile (<b>.mobileconfig</b>).</Text></li>
                <li><Text>Under <b>General</b> settings, configure the profile's name, description, and distribution method.</Text></li>
                <li><Text>Click the <b>Assignments</b> tab to specify which devices or device groups should receive the profile.</Text></li>
                <li><Text>Click <b>Save & Apply</b>.</Text></li>
            </ol>
        </div>
    )
}

export default Kandji;