/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const PSDialogStyle = {
  self: css``,
  title: css`
    margin-bottom: 30px;
  `,
  errorMessage: css`
    margin-top: 10px;
    margin-bottom: 10px;
  `,
  dialogActions: css`
    margin-top: 20px !important;
  `,
  fullContentWidth: css`
    margin-right: -30px;
    margin-left: -40px;
  `,
};