/** @jsxImportSource @emotion/react */
import { CustomCellRendererProps } from 'ag-grid-react';
import React, { useMemo } from 'react';
import Circle from '../../../Circle/Circle';
import Tooltip from '../../../Tooltip/Tooltip';
import Text from '../../../Text/Text';
import { IsValidStyle } from './IsValid.css';
import { TooltipProps } from '@mui/material';


type IProps = {
    iconOnly?: boolean;
} & Partial<CustomCellRendererProps>;

const IsValid: React.FC<IProps> = (props) => {
    const { value, iconOnly = true } = props;

    const tooltipProps: Partial<TooltipProps> = useMemo(() => ({
        placement: 'right',
        disableInteractive: true,
        disableFocusListener: !iconOnly,
        disableHoverListener: !iconOnly,
        disableTouchListener: !iconOnly
    }), [iconOnly]);

    switch (value) {
        case true:
            return <Tooltip {...tooltipProps} title="Valid">
                <div css={IsValidStyle.self}>
                    <Circle size={12} color='green' />
                    {!iconOnly && <Text>Valid</Text>}
                </div>
            </Tooltip>
        case false:
            return <Tooltip {...tooltipProps} title="Invalid">
                <div css={IsValidStyle.self}>
                    <Circle size={12} color='red' />
                    {!iconOnly && <Text>Invalid</Text>}
                </div>
            </Tooltip>
        default:
            return null;
    }
}

export default IsValid;