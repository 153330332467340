/** @jsxImportSource @emotion/react */
import React from 'react';
import { TextField } from '@mui/material';
import { PSDialog, Text } from '../../../ui-kit';
import { css } from '@emotion/react';

type IProps = {
    open: boolean;
    onClose: () => void;
    onCreate: (name: string) => Promise<any>;
    connectorTypeName: string;
};

const ManageConnectorTopBarCreateDialog: React.FC<IProps> = (props) => {
    const { open, onClose, onCreate, connectorTypeName } = props;

    const [name, setName] = React.useState('');

    const onAction = async () => {
        await onCreate(name);
    }

    return (
        <PSDialog
            open={open}
            onClose={onClose}
            title={`Create ${connectorTypeName} Connector`}
            action={onAction}
            actionButtonText='Create'
            isActionDisabled={name.trim() === ''}
        >
            <div css={css`display: flex; flex-direction: column; gap: 20px;`}>
                <Text>Enter name for the {connectorTypeName.toLowerCase()} connector.</Text>
                <TextField
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                    variant='outlined'
                    size='small'
                    label='Name'
                    fullWidth
                    placeholder='Enter the name of the connector'
                />
            </div>
        </PSDialog>
    )
}

export default ManageConnectorTopBarCreateDialog;