/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const ChipsStyle = {
  self: css`
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
  `,

  firstChipsContainer: (flexWrap: boolean) => css`
    display: flex;
    align-items: center;
    gap: 4px;
    overflow: hidden;
    flex-wrap: ${flexWrap ? "wrap" : "nowrap"};
  `,

  chip: css`
    min-width: 35px;
  `,
  chipsToHide: css`
    border: 1px solid var(--color-blue);
  `,

  moreChipsContainer: css`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
    padding: 10px;
  `,

  popover: css`
    margin: -10px;
  `,
};
