enum TimeUnit {
  Week = "week",
  Day = "day",
  Hour = "hour",
  Minute = "minute",
  Second = "second",
  Millisecond = "millisecond",
}

const timeUnits = [
  { unit: TimeUnit.Week, ms: 604800000 },
  { unit: TimeUnit.Day, ms: 86400000 },
  { unit: TimeUnit.Hour, ms: 3600000 },
  { unit: TimeUnit.Minute, ms: 60000 },
  { unit: TimeUnit.Second, ms: 1000 },
];

export function findSuitableUnit(
  milliseconds: number
): { unit: TimeUnit; ms: number } | undefined {
  return timeUnits.find((unit) => milliseconds >= unit.ms);
}

export function formatTimeValue(value: number, unit: TimeUnit, decimal: number = 2): string {
  const roundedValue = value.toFixed(decimal);
  const pluralSuffix = value > 1 ? "s" : "";
  return `${roundedValue} ${unit}${pluralSuffix}`;
}

export function millisecondToReadable(milliseconds: number): string {
  const suitableUnit = findSuitableUnit(milliseconds);

  if (suitableUnit) {
    const { unit, ms } = suitableUnit;
    const value = milliseconds / ms;
    return formatTimeValue(value, unit);
  }

  return formatTimeValue(milliseconds, TimeUnit.Millisecond, 0);
}
