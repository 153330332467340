/** @jsxImportSource @emotion/react */
import { Checkbox, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useState } from 'react';
import { MacOsIcon, PSAgentOutputImage, SupportedPlatformTable, WindowsIcon } from '../../../assets/images';
import { useConnectorContext } from '../../../contexts';
import { Icon, PSBox, PSButton, PSCopyText, PSFormControlLabel, Text } from '../../../ui-kit';
import CodeMirror from '@uiw/react-codemirror';
import { DevelopersDeploymentsStyle } from './DevelopersDeployments.css';
import { css } from '@emotion/react';
import { langs } from '@uiw/codemirror-extensions-langs';
import { censorStringEnd } from '../../../utils';

type OSInfo = {
    label: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

type OperatingSystem = 'mac' | 'windows'

const OPERATING_SYSTEMS: Record<OperatingSystem, OSInfo> = {
    mac: { label: 'Mac', icon: MacOsIcon },
    windows: { label: 'Windows', icon: WindowsIcon },
};;

type IProps = {};

const origin = window.location.origin;

const DevelopersDeployments: React.FC<IProps> = (props) => {
    const { } = props;

    const [selectedOS, setSelectedOS] = useState<OperatingSystem>('mac');

    const { connector } = useConnectorContext();
    const [showApiKey, setShowApiKey] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    if (!connector) return null

    const connectorIdCensored = censorStringEnd(connector.id, 12);
    const apiKey = showApiKey ? connector.id : connectorIdCensored;

    const handleCopyText = () => {
        const copyText = `curl ${origin}/api/protect-native-apps/get_apps -H "app-id: ${connector.id}"`
        navigator.clipboard.writeText(copyText);
        setIsCopied(true);
    }

    return (
        <div css={DevelopersDeploymentsStyle.self}>
            <div>
                <Text variant='header2'>Deployment instructions</Text>
                <Text variant='small' color='black-70'>How to deploy the Prompt Security Agent for Developers</Text>
            </div>

            <PSBox padding={30}>
                <div css={DevelopersDeploymentsStyle.title}>
                    <Text variant='header2'>Agent</Text>
                    <div css={css`display: flex; align-items: center; gap: 5px; margin-top: 5px;`}>
                        <Icon iconName='InfoOutlined' />
                        <Text>To receive the agent deployment files, please reach out to us directly or via email.</Text>
                    </div>

                    <Text css={css`margin-top: 20px;`} variant='bold'>Checking Environment Compatibility Before Deploying the Agent</Text>
                    <Text>Ahead of deploying the Agent, it is essential to ensure that your environment can communicate and access the relevant Prompt Security region domain. Follow the steps below to verify this.</Text>
                </div>

                <div css={DevelopersDeploymentsStyle.apiKey}>
                    <PSFormControlLabel label='Display API Key:' control={
                        <Checkbox
                            checked={showApiKey}
                            onChange={(e) => setShowApiKey(e.target.checked)}
                            color='primary'
                        />
                    } />
                    <PSCopyText label={apiKey} text={connector.id} />
                </div>

                <div css={DevelopersDeploymentsStyle.sectionContainer}>
                    <ToggleButtonGroup
                        value={selectedOS}
                        onChange={(_, os) => os && setSelectedOS(os)}
                        exclusive
                        css={DevelopersDeploymentsStyle.sectionSelectContainer}
                    >
                        {Object.entries(OPERATING_SYSTEMS).map(([value, os]) => (
                            <ToggleButton key={value} value={value} css={DevelopersDeploymentsStyle.toggleButton}>
                                <ToggleButtonIcon Icon={os.icon} label={os.label} />
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </div>

                <div css={css`display: flex; flex-direction: column; gap: 20px;`}>
                    <ol css={css`list-style: decimal; margin-left: 30px; display: flex; flex-direction: column; gap: 10px;`}>
                        <li><Text>{selectedOS === 'mac' ? 'Open Terminal.' : 'Open CMD (Windows+R → CMD).'}</Text></li>
                        <li><Text>Run the following command:</Text></li>
                    </ol>
                    <div css={DevelopersDeploymentsStyle.codeViewerContainer}>
                        <CodeMirror
                            readOnly
                            theme={'dark'}
                            extensions={[langs.shell()]}
                            value={`curl ${origin}/api/protect-native-apps/get_apps -H "app-id: ${apiKey}"`}
                        />
                        <div css={DevelopersDeploymentsStyle.copyButton}>
                            <PSButton variant='square' onClick={handleCopyText}>{isCopied ? 'Copied' : 'Copy'}</PSButton>
                        </div>
                    </div>
                    <Text><b>Output</b>: The output should match the screenshot provided below.</Text>
                    <img src={PSAgentOutputImage} alt="agent-output" css={css`width: max(50%, 1000px);`} />
                </div>
            </PSBox>

            <PSBox padding={30}>
                <Text css={css`margin-bottom: 10px;`} variant='bold'>Supported Platforms</Text>
                <SupportedPlatformTable css={css`width: min(100%, 850px);`} />
            </PSBox>
        </div>
    )
}

export default DevelopersDeployments;

type ToggleButtonIconProps = {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    label: string;
}

export const ToggleButtonIcon: React.FC<ToggleButtonIconProps> = ({ Icon, label }) => (
    <div css={css`overflow: hidden; gap: 5px; display: flex; flex-direction: column; align-items: center;`}>
        <div css={DevelopersDeploymentsStyle.iconContainer}>
            <Icon width={50} height={50} />
        </div>
        <Text variant='small'>{label}</Text>
    </div>
);