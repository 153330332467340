import React from 'react';
import { Box, Button, Card, Grid } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragHandleIcon from '@mui/icons-material/DragHandle';


export const DraggableWrapper = ({ id, children, isNew }: any) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const sx = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const cardStyle = { padding: '20px 10px 20px 10px', border: '1px solid var(--color-black-50)', boxShadow: 'var(--outer-elevation-3)', borderRadius: '20px', marginBottom: '30px', marginRight: '20px' };
    const isNewCardStyle = { padding: '20px 10px 20px 10px', border: 2, borderColor: 'var(--color-blue)', boxShadow: 'var(--outer-elevation-3)', borderRadius: '20px', marginBottom: '30px', marginRight: '20px' };

    return (
        <Box ref={setNodeRef} sx={sx}>
            <Card sx={isNew ? isNewCardStyle : cardStyle}>
                <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Button {...attributes} {...listeners} disableRipple disableFocusRipple disableTouchRipple disableElevation>
                        <DragHandleIcon />
                    </Button>
                    {children}
                </Grid>
            </Card>
        </Box>
    )
}