/** @jsxImportSource @emotion/react */
import React from 'react';
import { Text } from '../../../../../ui-kit/'
import { css } from '@emotion/react';

type IProps = {

};

const Safari: React.FC<IProps> = (props) => {
    const { } = props;

    return (
        <div css={css`display: flex; flex-direction: column; gap: 15px;`}>
            <Text>The distribution of the safari extension consists of two parts. These parts can be completed in any order.</Text>
            <ol css={css`list-style: decimal; margin-left: 30px; display: flex; flex-direction: column; gap: 10px;`}>
                <li>
                    <Text variant='bold'>Distribute the extension from Apple's AppStore:</Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>Go to Mac apps section.</Text></li>
                        <li><Text>Search and add the <b>Prompt Security GenAI Shield</b> app.</Text></li>
                        <li><Text>Set the app distribution method to install automatically.</Text></li>
                        <li><Text>Set the app update settings to automatically update.</Text></li>
                        <li><Text>Assign the app to devices/users.</Text></li>
                    </ul>
                </li>
                <li>
                    <Text variant='bold'>Configure the extension by running the downloaded script:</Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>Create a policy that will run it.</Text></li>
                        <li><Text>Set the trigger to run at login, once per user.</Text></li>
                        <li><Text>Note that the script should run with full disk access (FDE).</Text></li>
                    </ul>
                </li>
            </ol>
        </div>
    )
}

export default Safari;