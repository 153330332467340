/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import JailbreakDetectorForm from './JailbreakDetectorForm';

const JailbreakDetectorProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode } = props;

    return (
        <ProtectionWrapper
            label="Jailbreak Detector"
            description="Prevent malicious attempts to jailbreak your application"
            formName="Jailbreak Detector"
            Component={isAdvancedMode ? JailbreakDetectorForm : undefined}
            control={control}
            getValues={getValues}
            isAdvancedMode={isAdvancedMode}
            summary={`The "Jailbreak Detector" is an AI-driven protection designed to detect potential jailbreak attacks effectively. It is particularly efficient against “zero-day” jailbreak attempts and new, previously unseen, or non-standard attack techniques. While it offers high accuracy in detection with low false positives (FP), it's important to note that it has a relatively higher latency, approximately in the 500ms range.`}
        />
    )
}

export default JailbreakDetectorProtection;