/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { TextStyle } from "../Text/Text.css";

export const PSMuiDateRangePickerStyle = {
  self: css`
    & .MuiInputAdornment-root,
    & .MuiInputBase-root,
    input {
      &:hover {
        .icon {
          fill: var(--color-black);
        }
      }
      cursor: pointer;
      ${TextStyle.bold};
    }
  `,
};
