import { graphql } from '../../gql';

export const updatePolicyById = graphql(`
    mutation UpdatePolicyById($input: UpdatePolicyInput!) {
    updatePolicy(input: $input) {
            content
            id
            name
            policyType
        }
    }
`);

export const getPolicyByType = graphql(`
    query GetPolicy($policyTypes: [String!], $policyId: ID) {
        policies(policyTypes: $policyTypes, id: $policyId) {
            content
            name
            id
            policyType
        }
        applications(applicationTypes: "SHADOW") {
            id
        }
    }
`);

export const getConnectorsByType = graphql(`
    query GetConnectors($connectorTypes: [String!]) {
        applications(applicationTypes: $connectorTypes) {
            name
            id
        },
    }
`);

export const getConnectorByName = graphql(`
    query GetConnectorByName($name: String!) {
        applications(name: $name) {
            applicationType
            id
            policyIds
            name
        }
    }
`);

export const getPolicyById = graphql(`
    query GetPolicyById($policyId: ID!) {
        policies(id: $policyId) {
            content
            name
            id
            policyType
        }
        applications(applicationTypes: "SHADOW") {
            id
        }
    }
`);