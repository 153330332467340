/** @jsxImportSource @emotion/react */
import React from 'react';
import { Text } from '../../../../../ui-kit';
import { css } from '@emotion/react';

type IProps = {};

const Jamf: React.FC<IProps> = (props) => {
    const { } = props;

    return (
        <div css={css`display: flex; flex-direction: column; gap: 15px;`}>
            <Text>The goal is to install the above mobileconfig configuration profile on the mac devices of your employees</Text>
            <div css={css`display: flex; gap: 5px;`}>
                <Text>Please follow the instructions in the section</Text>
                <a href='https://learn.jamf.com/bundle/jamf-pro-documentation-current/page/Computer_Configuration_Profiles.html' target='_blank' rel="noreferrer"><Text onClick={() => { }}>Uploading a Computer Configuration Profile.</Text></a>
            </div>
            <ol css={css`list-style: number; margin-left: 30px; display: flex; flex-direction: column; gap: 10px;`}>
                <li><Text>In Jamf Pro, click <b>Computers</b> in the sidebar.</Text></li>
                <li><Text>Click <b>Configuration Profiles</b> in the sidebar.</Text></li>
                <li><Text>Click <b>Upload</b> and upload the configuration profile (<b>.mobileconfig</b>).</Text></li>
                <li><Text>Use the <b>General</b> payload to change or configure basic settings for the profile, including a distribution method.</Text></li>
                <li><Text>Use the rest of the payloads to configure or edit settings as needed.</Text></li>
                <li><Text>Click the <b>Scope</b> tab and configure the scope of the profile.</Text></li>
                <li><Text>(Optional) If you chose to distribute the profile in <b>Self Service</b>, click the <b>Self Service</b> tab to configure Self Service settings for the profile.</Text></li>
                <li><Text>Click <b>Save</b>.</Text></li>
            </ol>
        </div>
    )
}

export default Jamf;