/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const Error404Style = {
  self: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;
    margin-inline: auto;
    width: min(100%, 600px);
  `,
  goHomeButton: css`
    margin-top: 20px;
  `,
};
