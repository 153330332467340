/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const ItemListInputStyle = {
    self: css`
        display: flex;
        flex-direction: column;
        gap: 10px;
    `,
    addInputContainer: css`
        display: flex;
        align-items: center;
        gap: 10px;
    `,
    singleItem: css`
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 25px;
    `,
}