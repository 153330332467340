import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

type ConfigurationSubItemProps = {
  configurationTitle: string;
  children: JSX.Element;
};

export const ConfigurationSubItem = ({ configurationTitle, children }: ConfigurationSubItemProps) => {

  return (
    <Box sx={{ my: 2 }}>
      <Box sx={{ my: 2, mx: 0.5, borderRadius: 2, mb: 5 }}>
        <Typography gutterBottom variant='body1' fontWeight="bold">
          {configurationTitle}
        </Typography>
      </Box>
      <Box sx={{ my: -3, mx: 3, mb: 1 }}>
        <Grid container alignItems="center">
          <Grid item xs sx={{ positionLeft: '50px' }}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}