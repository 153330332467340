import { useEffect, useState } from 'react';
import { IntegrationItem } from '../IntegrationComponents/IntegrationItem';
import { Box } from '@mui/material';
import { graphql, graphqlEndpoint } from "../../../gql";
import { useQuery } from "@tanstack/react-query";
import { request } from "graphql-request";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { OverrideSlackIntegrationInput } from '../../../gql/generated/graphql';
import { EditButtons } from '../../../components/EditButtons';
import { StatusRadio } from '../IntegrationComponents/StatusRadio';
import { PopupMessage } from '../../../components/PopupMessage';
import { TextInput } from '../IntegrationComponents/TextInput';


const querySlackIntegration = graphql(`
    query slackIntegration {
        slackIntegration {
            id
            webhook
        }
    }
`);

const mutationOverrideSlackIntegration = graphql(`
    mutation OverrideSlackIntegration($webhook: String, $isEnabled: Boolean) {
        overrideSlackIntegration(
            input: {
                webhook: $webhook
                isEnabled: $isEnabled
            }
        ) {
            id
        }
    }
`);

type IntegrationSlackCardProps = {
    inputIsEnabled: boolean
};


export const IntegrationSlackCard = ({ inputIsEnabled }: IntegrationSlackCardProps) => {    
    const { data: slackIntegration } = useQuery(
        {
            queryKey: ["slackIntegration"],
            queryFn: async () => {
                const { slackIntegration } = await request(graphqlEndpoint, querySlackIntegration);
                return slackIntegration
            },
        }
    )

    useEffect(() => {
        if (slackIntegration !== undefined) {
            setWebhookToDisplay(slackIntegration.webhook);
        }
    }, [slackIntegration]);

    const queryClient = useQueryClient();
    const [editMode, setEditMode] = useState<boolean>(false);
    const [isEnabledToDisplay, setIsEnabledToDisplay] = useState<boolean>(inputIsEnabled);
    const [webhookToDisplay, setWebhookToDisplay] = useState<string | undefined | null>();
    const [openSavePopup, setOpenSavePopup] = useState<boolean>(false);
    const [popupText, setPopupText] = useState<string>('');
    const [popupTitle, setPopupTitle] = useState<string>('');

    const overrideSlackIntegration = useMutation({
        mutationFn: async (variables: any) => {
            await request(graphqlEndpoint, mutationOverrideSlackIntegration, variables);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["slackIntegration"] });
            queryClient.invalidateQueries({ queryKey: ["integration"] });

            setPopupText('Your changes have been successfully saved');
            setPopupTitle('Slack Logs Integration Updated');
            setOpenSavePopup(true);
            setEditMode(false);
        },
        onError: (error) => {
          const errorMessage = 'Failed to update Slack Integration';
          setPopupText(errorMessage);
          setPopupTitle('Slack Logs Integration Update Failed');
          setOpenSavePopup(true);
        }
    });

    const checkTextValidity = (value: string | undefined | null, varName: string, required: boolean): boolean => {
        if ((value !== null && value !== undefined && value.trim() === '') ||
            (required === true && (value === null || value === undefined))
        ) {
            setPopupText(`${varName} can't be empty`);
            setPopupTitle('Slack Logs Integration Update Failed');
            setOpenSavePopup(true);
            return false;
        }
        return true;
    }

    const handleSave = () => {  
        const isEnabled = (isEnabledToDisplay === true || (isEnabledToDisplay === null && inputIsEnabled === true));
        let overrideData: OverrideSlackIntegrationInput = { 
            isEnabled: isEnabledToDisplay
        }

        if (isEnabled) {
            if (!checkTextValidity(webhookToDisplay, 'Webhook', true)
            ) {
                return;
            }
            overrideData.webhook = webhookToDisplay;
        }
        overrideSlackIntegration.mutate(overrideData);
        setIsEnabledToDisplay(inputIsEnabled);
        setWebhookToDisplay(slackIntegration?.webhook);
    };

    const handleCancel = () => {
        setIsEnabledToDisplay(inputIsEnabled);
        setWebhookToDisplay(slackIntegration?.webhook);
        setEditMode(false);
    };

    const handleEmptyValues = (value: string | undefined | null) => {
        if (value === '') {
            return null;
        }
        return value;
    }

    const handleIntegrationChange = (
        isEnabled: boolean,
        webhook: string | null | undefined,
    ) => {
        webhook = handleEmptyValues(webhook);

        if (isEnabled !== isEnabledToDisplay) {
            setIsEnabledToDisplay(isEnabled);
        }
        if (webhook !== webhookToDisplay) {
            setWebhookToDisplay(webhook);
        }

        if (isEnabled !== inputIsEnabled ||
            (webhook !== slackIntegration?.webhook && !(webhook === null && slackIntegration?.webhook === undefined))
        ) {
            setEditMode(true);
        } else {
            setEditMode(false);
        }
    }

    const handleSavePopupClose = () => {
        setOpenSavePopup(false);
      };

    if (!editMode) {
        if (isEnabledToDisplay !== inputIsEnabled) {
            setIsEnabledToDisplay(inputIsEnabled);
        }
    }

    const handleEnableOnChange = (value: boolean) => {
        setIsEnabledToDisplay(value);
        handleIntegrationChange(value, webhookToDisplay);
    };

    const handleWebhookOnChange = (value: string) => {
        setWebhookToDisplay(value);
        handleIntegrationChange(isEnabledToDisplay, value);
    };

    return (
        <div>
            <IntegrationItem>
                <Box>
                    <StatusRadio value={isEnabledToDisplay} handleOnChange={handleEnableOnChange} text='Status'/>
                    <TextInput value={webhookToDisplay} title='Webhook' help='Slack channel webhook' handleOnChange={handleWebhookOnChange} disable={isEnabledToDisplay === false} multiline={false} />
                </Box>
            </IntegrationItem>
            <EditButtons sx={{ mt: 6, ml: 3.5, marginBottom: 1}} editMode={editMode} handleSaveClick={handleSave} handleCancelClick={handleCancel} />
            <PopupMessage title={popupTitle} text={popupText} open={openSavePopup} handlePopupMessageClose={handleSavePopupClose}/>
        </div>
    )
};