/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const ActivityMonitorStyle = {
  self: css`
    .MuiDrawer-paper {
      width: max(50%, min(600px, 80%));
      background: var(--color-background);
    }
  `,
  container: css`
    padding: 30px;
    padding-top: 0;
  `,
  title: css`
    margin-bottom: 40px;
  `,
  tabDataContainer: css`
    margin-top: 20px;
  `,
};
