/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { PSFormControlLabel, Text } from '../../ui-kit';
import { Radio, RadioGroup, Switch } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ApplicationTypes } from '../../gql/generated/graphql';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { graphql, graphqlEndpoint } from '../../gql';
import { request } from 'graphql-request';

const mutateFalseGenAiDetection = graphql(`
    mutation FalseGenAiDetection($domain: String!) {
        reportFalseGenAiDetection(input: {domain: $domain}) {
            action
        }
    }`
);

type TClassificationAction = 'True' | 'False';
type TClassificationValid = 'positive' | 'negative';
type TClassification = `${TClassificationAction}-${TClassificationValid}`;
type TClassificationType = 'Code Detector' | 'Secrets' | 'Sensitive Data' | 'Toxicity' | 'Prompt Injection Classifier' | '' | null;
export type TTextType = 'response' | 'prompt';

export type TClassificationBody = {
    classification: TClassification;
    classificationType: TClassificationType;
    textType: TTextType;
    promptResponseId: string;
    flipClassification: boolean;
}

type TInputs = {
    reportType: 'log' | 'gen-ai' | '';
    violationType: TClassificationType;
    flipClassification: boolean;
}

type IProps = {
    onFormStateChange: (isValid: boolean) => void;
    setSubmitForm: (...args: any) => void;
    textType: TTextType;
    appName: string;
    promptResponseId: string;
    applicationType: ApplicationTypes
    isValid: boolean
    refetchTableData: () => void;
};

const FalseClassification: React.FC<IProps> = (props) => {
    const { appName, textType, promptResponseId, applicationType, isValid, onFormStateChange, setSubmitForm, refetchTableData } = props;

    const { control, handleSubmit, watch, formState, getValues } = useForm<TInputs>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            flipClassification: false,
            violationType: '',
            reportType: ''
        }
    });

    const queryClient = useQueryClient();

    const reportType = watch("reportType");

    useEffect(() => {
        const submit = async (data: TInputs) => {
            if (data.reportType === 'log') {
                await fetchReportClassification();
            } else {
                await mutateGenAiFalseClassification(appName);
            }
        }

        setSubmitForm(() => handleSubmit(submit));
    }, [])

    useEffect(() => {
        onFormStateChange(formState.isValid);
    }, [formState.isValid, onFormStateChange]);


    const fetchReportClassification = async () => {

        const body: TClassificationBody = {
            classification: `False-${isValid ? 'positive' : 'negative'}`,
            classificationType: getValues('violationType') || null,
            flipClassification: getValues('flipClassification'),
            textType,
            promptResponseId
        };

        const request = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }

        const response = await fetch('/api/be/report-classification', request);
        if (!response.ok) {
            throw new Error('Error reporting classification.');
        }

        toast.success(`The classification was successfully reported.`);
        refetchTableData();
    }

    const { mutateAsync: mutateGenAiFalseClassification } = useMutation({
        mutationFn: async (domain: string) => {
            await request(graphqlEndpoint, mutateFalseGenAiDetection, {domain});
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['extensionConfiguration'] });
            queryClient.invalidateQueries({ queryKey: ['configuration'] });
            toast.success(`"${appName}" successfully reported as false GenAI Application`);
        },
        onError: () => {
            throw new Error('Error reporting GenAI Application.');
        }
    })

    return (
        <React.Fragment>
            <Text color='black'>Choose the type of false classification you want to report</Text>
            <Controller
                name="reportType"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                    <RadioGroup
                        {...field} css={css`
                        margin-top: 10px;
                        margin-left: 10px;
                    `}>
                        <PSFormControlLabel value="log" control={<Radio size='small' />} label="Log False Classification" />
                        {applicationType === ApplicationTypes.Extension && <PSFormControlLabel value="gen-ai" control={<Radio size='small' />} label="GenAI Application False Detection" />}
                    </RadioGroup>
                )}
            />
            {reportType === 'log' && isValid &&
                <>
                    <Text textCss={css`margin-top: 30px;`} color='black'>Have you found a violation in this interaction? If so, select which one</Text>
                    <Controller
                        name="violationType"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <RadioGroup {...field} css={css`
                        margin-top: 10px;
                        margin-left: 10px;
                    `}>
                                <PSFormControlLabel value="Code Detector" control={<Radio size='small' />} label="Code" />
                                <PSFormControlLabel value="Secrets" control={<Radio size='small' />} label="Secrets" />
                                <PSFormControlLabel value="Sensitive Data" control={<Radio size='small' />} label="Sensitive Data" />
                                <PSFormControlLabel value="Toxicity" control={<Radio size='small' />} label="Toxicity" />
                                {applicationType !== ApplicationTypes.Extension && <PSFormControlLabel value="Prompt Injection Classifier" control={<Radio size='small' />} label="Prompt Injection" />}
                            </RadioGroup>
                        )}
                    />
                </>
            }
            {reportType === 'log' && (
                <div css={css`margin-top: 10px; display: flex; align-items: center; gap: 10px;`}>
                    <Text color='black'>Do you want to change the classification to {isValid ? 'alert' : 'log'}?</Text>
                    <Controller
                        name="flipClassification"
                        control={control}
                        render={({ field }) => <Switch {...field} />}
                    />
                </div>
            )}
            {reportType === 'gen-ai' && (
                <Text textCss={css`margin-top: 15px;`} color='black'>Report false detection of the application
                    <span css={css`display: inline-block; font-weight: bold; margin: 0 10px; padding: 10px; border-radius: 10px; background: var(--color-black-10); `}>{appName}</span>
                    as a GenAI Application</Text>
            )}
        </React.Fragment>
    );
};

export default FalseClassification;