/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const CountryStyle = {
  self: css`
    display: flex;
    align-items: center;
    gap: 5px;
  `,
};
