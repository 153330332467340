/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionFormProps, TPromptHardeningProtection } from '../Common';
import { PromptHardeningFormStyle } from './PromptHardeningForm.css';
import { Controller } from 'react-hook-form';
import { PSFormControlLabel } from '../../../../ui-kit';
import { Switch } from '@mui/material';

const PromptHardeningForm: React.FC<IProtectionFormProps<TPromptHardeningProtection>> = (props) => {
    const { control, formName, protection } = props;

    return (
        <div css={PromptHardeningFormStyle.self}>
            <Controller
                name={`${formName}.seq_enclosure`}
                defaultValue={protection.seq_enclosure}
                control={control}
                render={({ field }) => (
                    <PSFormControlLabel
                        {...field}
                        checked={field.value}
                        label="Sequence Enclosure"
                        control={<Switch />}
                    />
                )}
            />

            <Controller
                name={`${formName}.xml_tagging`}
                defaultValue={protection.xml_tagging}
                control={control}
                render={({ field }) => (
                    <PSFormControlLabel
                        {...field}
                        checked={field.value}
                        label="XML Tagging"
                        control={<Switch />}
                    />
                )}
            />
        </div>
    )
}

export default PromptHardeningForm;