/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const NavigationTopBarStyle = {
  self: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    background: var(--color-white);
    border-bottom: 1px solid var(--color-black-10);
    box-shadow: var(--outer-elevation-1);
    padding: 10px 20px;
    min-height: 60px;
    max-height: 60px;
    z-index: 1;
  `,
};