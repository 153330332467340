import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

type IntegrationItemProps = {
  children: JSX.Element;
};

export const IntegrationItem = ({ children }: IntegrationItemProps) => {

  return (
    <Box sx={{ my: -1 }}>
      <Divider variant="middle" sx={{ mb: 3 }} />
      <Box sx={{ my: 1, mx: 2 }}>
        <Grid container alignItems="center">
          <Grid item xs sx={{ positionLeft: '50px' }}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}