/** @jsxImportSource @emotion/react */
import React from 'react';
import { PSCopyText, Text } from '../../../../../ui-kit/'
import { css } from '@emotion/react';

type IProps = {

};

const Talon: React.FC<IProps> = (props) => {
    const { } = props;

    return (
        <ol css={css`list-style: number; margin-left: 30px; display: flex; flex-direction: column; gap: 10px;`}>
            <li><Text>Go to the Talon Portal.</Text></li>
            <li><Text>Go to <b>{`Rules > Browser customization > Edit Rule`}</b> and click on <b>Extension Force Install</b>.</Text></li>
            <li>
                <Text css={css`display: flex; align-items: center; flex-wrap: wrap; gap: 5px;`}>
                    Paste the Extension ID <PSCopyText text='iidnankcocecmgpcafggbgbmkbcldmno' toastText='Extenstion ID Copied to clipboard' />
                    into the <b>Extension ID</b> field.
                </Text>
            </li>
            <li><Text>Select <b>Chrome Web Store</b> as the store option.</Text></li>
            <li><Text>If desired, toggle <b>Pin to toolbar</b>.</Text></li>
            <li><Text>Under <b>Custom configuration</b>, input the content of the downloaded extension policy file.</Text></li>
            <li><Text>Click <b>Add</b> to finalize adding the extension.</Text></li>
        </ol>
    )
}

export default Talon;