import { IntegrationItem } from '../IntegrationComponents/IntegrationItem';
import { Box, Typography } from '@mui/material';

export const IntegrationMsTeamsCard = () => {    
    return (
        <div>
            <IntegrationItem>
                <Box>
                    <Typography>Coming Soon</Typography>
                </Box>
            </IntegrationItem>
        </div>
    )
};