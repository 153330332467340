import { useEffect, useState } from 'react';
import { IntegrationItem } from '../IntegrationComponents/IntegrationItem';
import { Box } from '@mui/material';
import { graphql, graphqlEndpoint } from "../../../gql";
import { useQuery } from "@tanstack/react-query";
import { request } from "graphql-request";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LogsTypes, OverrideOpensearchIntegrationInput } from '../../../gql/generated/graphql';
import { EditButtons } from '../../../components/EditButtons';
import { StatusRadio } from '../IntegrationComponents/StatusRadio';
import { PopupMessage } from '../../../components/PopupMessage';
import { TypeRadio, typeDisplay } from '../IntegrationComponents/TypeRadio';
import { IntInput } from '../IntegrationComponents/IntInput';


const queryOpensearchIntegration = graphql(`
    query opensearchIntegration {
        opensearchIntegration {
            id
            dataRetentionPeriod
            logsType
        }
    }
`);

const mutationOverrideOpensearchIntegration = graphql(`
    mutation OverrideOpensearchIntegration($dataRetentionPeriod: Int, $logsType: LogsTypes, $isEnabled: Boolean) {
        overrideOpensearchIntegration(
            input: {
                dataRetentionPeriod: $dataRetentionPeriod
                logsType: $logsType
                isEnabled: $isEnabled
            }
        ) {
            id
        }
    }
`);

type IntegrationOpensearchCardProps = {
    inputIsEnabled: boolean
};


export const IntegrationOpensearchCard = ({ inputIsEnabled }: IntegrationOpensearchCardProps) => {    
    const { data: opensearchIntegration } = useQuery(
        {
            queryKey: ["opensearchIntegration"],
            queryFn: async () => {
                const { opensearchIntegration } = await request(graphqlEndpoint, queryOpensearchIntegration);
                return opensearchIntegration
            },
        }
    )

    useEffect(() => {
        if (opensearchIntegration !== undefined) {
            setDataRetentionToDisplay(opensearchIntegration.dataRetentionPeriod);
            setInputLogsType(opensearchIntegration.logsType);
            setLogsTypeToDisplay(opensearchIntegration.logsType);
        } else {
            setInputLogsType(LogsTypes.Full);
            setLogsTypeToDisplay(LogsTypes.Full);
        }
    }, [opensearchIntegration]);

    const queryClient = useQueryClient();
    const [editMode, setEditMode] = useState<boolean>(false);
    const [isEnabledToDisplay, setIsEnabledToDisplay] = useState<boolean>(inputIsEnabled);
    const [dataRetentionPeriodToDisplay, setDataRetentionToDisplay] = useState<number | undefined | null>();
    const [logsTypeToDisplay, setLogsTypeToDisplay] = useState<LogsTypes | undefined | null>();
    const [inputLogsType, setInputLogsType] = useState<LogsTypes | undefined | null>();
    const [openSavePopup, setOpenSavePopup] = useState<boolean>(false);
    const [popupText, setPopupText] = useState<string>('');
    const [popupTitle, setPopupTitle] = useState<string>('');

    const overrideOpensearchIntegration = useMutation({
        mutationFn: async (variables: any) => {
            await request(graphqlEndpoint, mutationOverrideOpensearchIntegration, variables);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["opensearchIntegration"] });
            queryClient.invalidateQueries({ queryKey: ["integration"] });

            setPopupText('Your changes have been successfully saved');
            setPopupTitle('Opensearch Logs Integration Updated');
            setOpenSavePopup(true);
            setEditMode(false);
        },
        onError: (error) => {
          const errorMessage = 'Failed to update Opensearch Logs Integration';
          setPopupText(errorMessage);
          setPopupTitle('Opensearch Logs Integration Update Failed');
          setOpenSavePopup(true);
        }
    });

    const handleSave = () => {  
        const isEnabled = (isEnabledToDisplay === true || (isEnabledToDisplay === null && inputIsEnabled === true));
        let overrideData: OverrideOpensearchIntegrationInput = { 
            isEnabled: isEnabledToDisplay
        }

        if (isEnabled) {
            overrideData.dataRetentionPeriod = dataRetentionPeriodToDisplay;
            overrideData.logsType = logsTypeToDisplay;
        }
        overrideOpensearchIntegration.mutate(overrideData);
    };

    const handleCancel = () => {
        setIsEnabledToDisplay(inputIsEnabled);
        setDataRetentionToDisplay(opensearchIntegration?.dataRetentionPeriod);
        setLogsTypeToDisplay(opensearchIntegration?.logsType);
        setEditMode(false);
    };

    const handleIntegrationChange = (
        isEnabled: boolean,
        dataRetentionPeriod: number | null | undefined,
        logsType: LogsTypes | null | undefined,
    ) => {
        if (isEnabled !== isEnabledToDisplay) {
            setIsEnabledToDisplay(isEnabled);
        }
        if (dataRetentionPeriod !== dataRetentionPeriodToDisplay) {
            setDataRetentionToDisplay(dataRetentionPeriod);
        }
        if (logsType !== logsTypeToDisplay) {
            setLogsTypeToDisplay(logsType);
        }

        if (isEnabled !== inputIsEnabled ||
            (dataRetentionPeriod !== opensearchIntegration?.dataRetentionPeriod && !(dataRetentionPeriod === null && opensearchIntegration?.dataRetentionPeriod === undefined)) ||
            logsType !== inputLogsType
        ) {
            setEditMode(true);
        } else {
            setEditMode(false);
        }
    }

    const handleSavePopupClose = () => {
        setOpenSavePopup(false);
      };

    if (!editMode) {
        if (isEnabledToDisplay !== inputIsEnabled) {
            setIsEnabledToDisplay(inputIsEnabled);
        }
    }

    const handleEnableOnChange = (value: boolean) => {
        setIsEnabledToDisplay(value);
        handleIntegrationChange(value, dataRetentionPeriodToDisplay, logsTypeToDisplay);
    };

    const handleDataRetentionPeriodOnChange = (value: number) => {
        setDataRetentionToDisplay(value);
        handleIntegrationChange(isEnabledToDisplay, value, logsTypeToDisplay);
    };

    const handleLogsTypeOnChange = (value: string) => {
        setLogsTypeToDisplay(value as LogsTypes);
        handleIntegrationChange(isEnabledToDisplay, dataRetentionPeriodToDisplay, value as LogsTypes);
    };

    const logsTypeDisplayText = (type: string): typeDisplay => {
        if (type === LogsTypes.Full) {
            return {text: 'Send Full Log',  helpText: 'The full log will be sent to Opensearch'};
        } else {
            return {text: 'Send Only Metadata', helpText: 'Only metadata info will be sent to Opensearch'};
        }
    }

    return (
        <div>
            <IntegrationItem>
                <Box>
                    <StatusRadio value={isEnabledToDisplay} handleOnChange={handleEnableOnChange} text='Data storage in Prompt Security platform'/>
                    <IntInput value={dataRetentionPeriodToDisplay} title="Data Retention Period (days)" help={'Configurable setting for managing data retention period measured in days (0 means keep the data forever)'} handleOnChange={handleDataRetentionPeriodOnChange} disabled={isEnabledToDisplay === false} />
                    <TypeRadio value={logsTypeToDisplay} title='Logs Type' help='The type of logs that will be sent' handleOnChange={handleLogsTypeOnChange} disable={isEnabledToDisplay === false} types={Object.values(LogsTypes)} typeDisplayText={logsTypeDisplayText}/>
                </Box>
            </IntegrationItem>
            <EditButtons sx={{ mt: 6, ml: 3.5, marginBottom: 1}} editMode={editMode} handleSaveClick={handleSave} handleCancelClick={handleCancel} />
            <PopupMessage title={popupTitle} text={popupText} open={openSavePopup} handlePopupMessageClose={handleSavePopupClose}/>
        </div>
    )
};