/** @jsxImportSource @emotion/react */
import React from 'react';
import { TextField } from '@mui/material';
import { PSDialog, Text } from '../../../ui-kit';
import { css } from '@emotion/react';

type IProps = {
    open: boolean;
    onClose: () => void;
    onDelete: () => Promise<any>;
    connectorTypeName: string;
};

const ManageConnectorTopBarDeleteDialog: React.FC<IProps> = (props) => {
    const { open, onClose, onDelete, connectorTypeName } = props;

    const [validationValue, setValidationValue] = React.useState('');

    return (
        <PSDialog
            open={open}
            onClose={onClose}
            title={`Delete ${connectorTypeName} Connector`}
            action={onDelete}
            actionButtonText='Delete'
            actionButtonVariantType='critical'
            isActionDisabled={validationValue !== 'DELETE'}
        >
            <div css={css`display: flex; flex-direction: column; gap: 20px;`}>
                <Text>Are you sure you want to delete this {connectorTypeName.toLowerCase()} connector?</Text>
                <Text>Type "<b>DELETE</b>" to continue</Text>
                <TextField
                    value={validationValue}
                    onChange={(e) => setValidationValue(e.target.value)}
                    autoFocus
                    variant='outlined'
                    size='small'
                    fullWidth
                    placeholder='Enter...'
                />
            </div>
        </PSDialog>
    )
}

export default ManageConnectorTopBarDeleteDialog;