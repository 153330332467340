/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Tabs } from "@mui/material";


export const PSTabs = styled((Tabs))`
    border-bottom: 1px solid var(--color-black-25);
    .MuiTabs-indicator {
        display: none; //disabled animation

        background-color: var(--color-blue);
        height: 3px;
        background: var(--color-black);
    };

    .MuiTabs-flexContainer {
        padding: 0 10px;
    }

    .Mui-selected {
        border-bottom: 3px solid var(--color-black);
    }

    .MuiTabs-flexContainer:has(.loading) {
        & + .MuiTabs-indicator {
            display: none;
        }
        
        gap: 10px;
    }
`;

export default PSTabs;