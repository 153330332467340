import { useState } from 'react';
import { ConfigurationItem } from '../ConfigurationComponents/ConfigurationItem';
import { Box } from '@mui/material';
import { IntInput } from '../ConfigurationComponents/IntInput';

type ConfigurationItemProtectApiQuotaProps = {
    inputRemainingQuota: number;
    editMode: boolean;
    handleConfigurationChange: (mode: number) => void;
};

export const ConfigurationItemProtectApiQuota = ({ inputRemainingQuota, editMode, handleConfigurationChange }: ConfigurationItemProtectApiQuotaProps) => {
    const [remainingQuota, setRemainingQuota] = useState<number>(inputRemainingQuota);

    if (remainingQuota !== inputRemainingQuota && !editMode) {
        setRemainingQuota(inputRemainingQuota);
    }

    const handleChange = (mode: number | null) => {
        if (mode === null) {
            return;
        }
        setRemainingQuota(mode);
        handleConfigurationChange(mode);
    };

    return (
        <ConfigurationItem 
            configurationTitle='Protect API Quota'
            configurationHelpText='Configurable setting for managing the remaining quota of the API.'
        >
            <Box>
            <IntInput
                value={remainingQuota}
                title='Remaining Quota'
                help='The remaining quota for protect API, -1 is unlimited.'
                disabled={false}
                minValue={-1}
                maxValue={undefined}
                handleOnChange={handleChange}
                />
            </Box>
        </ConfigurationItem>
    )
};