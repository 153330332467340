/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const HomegrownApplicationsSDKStyle = {
    self: css`
    `,
    title: css`
        border-bottom: 1px solid var(--color-black-25);
        padding-bottom: 20px;
        margin-bottom: 20px;
    `,
    codeViewerContainer: css`
        position: relative;
    `,
    copyButton: css`
        position: absolute;
        right: 10px;
        top: 10px;
    `,
    apiKey: css`
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
    `,
}