import React, { createContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetConnectorByNameQuery } from '../gql/generated/graphql';
import { useGraphQL } from '../hooks';
import { getConnectorByName } from '../routes-new/Manage/ConnectorsCommon';

export type ConnectorContextType = {
    connector: NonNullable<GetConnectorByNameQuery['applications'][number]>;
}

export const ConnectorContext = createContext<ConnectorContextType | null>(null);

type IProps = {
    children: React.ReactNode;
};

export const ConnectorContextProvider: React.FC<IProps> = (props) => {
    const { children } = props;
    const { connectorName } = useParams();

    const connectorData = useGraphQL({
        document: getConnectorByName,
        variables: { name: connectorName || '' },
        select: (data) => data.applications[0]
    })

    if (connectorData.isLoading) return null;

    return (
        <ConnectorContext.Provider value={{ connector: connectorData.data! }}>
            {children}
        </ConnectorContext.Provider>
    )
}

export const useConnectorContext = () => {
    const context = React.useContext(ConnectorContext);
    if (!context) {
        throw new Error('useNowContext must be used within a ConnectorContextProvider');
    }
    return context;
}
