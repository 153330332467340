/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const LLMByUsageOthersModalStyle = {
  self: css``,
  dataItemsContainer: css`
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    gap: 40px 20px; 
  `,
};
