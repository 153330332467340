/** @jsxImportSource @emotion/react */
import React, { useState, useMemo } from 'react';
import { PSBox, PSButton, PSFormControlLabel, Text } from '../../../../ui-kit';
import { ArcBrowserIcon, BraveBrowserIcon, ChromeBrowserIcon, EdgeBrowserIcon, EnterpriseIcon, FirefoxBrowserIcon, IslandBrowserIcon, KandjiIcon, JamfIcon, JumpcloudIcon, MacOsIcon, MicrosoftIcon, MicrosoftIntuneIcon, MicrosoftSCCMIcon, MobileIronIcon, SafariBrowserIcon, TalonBrowserIcon, VivaldiBrowserIcon, WindowsIcon, ChromiumBrowserIcon, WhaleBrowserIcon } from '../../../../assets/images';
import { Checkbox, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { EmployeesMDMStyle } from './EmployeesMDM.css';
import { css } from '@emotion/react';
import { VendorToComponent } from './VendorsInstallationGuide';
import { generateConfig } from './generateConfig';
import { toast } from 'react-toastify';
import { Safari, Talon } from './BrowsersInstallationGuide';
import { useConnectorContext } from '../../../../contexts';

type OperatingSystem = 'mac' | 'windows' | 'enterprise';
type Browser = 'chrome' | 'edge' | 'brave' | 'vivaldi' | 'arc' | 'firefox' | 'safari' | 'talon' | 'island' | 'chromium' | 'whale';
type MDMVendor = keyof typeof VendorToComponent | 'custom';

type OSInfo = {
    label: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

type BrowserInfo = {
    label: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    operatingSystems: OperatingSystem[];
}

type MDMVendorInfo = {
    label: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const OPERATING_SYSTEMS: Record<OperatingSystem, OSInfo> = {
    mac: { label: 'Mac', icon: MacOsIcon },
    windows: { label: 'Windows', icon: WindowsIcon },
    enterprise: { label: 'Enterprise Browsers', icon: EnterpriseIcon },
};

const BROWSERS: Record<Browser, BrowserInfo> = {
    chrome: { label: 'Chrome', icon: ChromeBrowserIcon, operatingSystems: ['mac', 'windows'] },
    edge: { label: 'Edge', icon: EdgeBrowserIcon, operatingSystems: ['mac', 'windows'] },
    brave: { label: 'Brave', icon: BraveBrowserIcon, operatingSystems: ['mac', 'windows'] },
    vivaldi: { label: 'Vivaldi', icon: VivaldiBrowserIcon, operatingSystems: ['mac', 'windows'] },
    arc: { label: 'Arc', icon: ArcBrowserIcon, operatingSystems: ['mac', 'windows'] },
    firefox: { label: 'Firefox', icon: FirefoxBrowserIcon, operatingSystems: ['mac', 'windows'] },
    safari: { label: 'Safari', icon: SafariBrowserIcon, operatingSystems: ['mac'] },
    chromium: { label: 'Chromium', icon: ChromiumBrowserIcon, operatingSystems: ['mac', 'windows'] },
    whale: { label: 'Whale', icon: WhaleBrowserIcon, operatingSystems: ['mac', 'windows'] },
    talon: { label: 'Talon', icon: TalonBrowserIcon, operatingSystems: ['enterprise'] },
    island: { label: 'Island', icon: IslandBrowserIcon, operatingSystems: ['enterprise'] },
};

const MDM_VENDORS: Record<MDMVendor, MDMVendorInfo> = {
    'kandji': { label: 'Kandji', icon: KandjiIcon },
    'jamf': { label: 'Jamf', icon: JamfIcon },
    'jumpcloud-mac': { label: 'Jumpcloud', icon: JumpcloudIcon },
    'jumpcloud-windows': { label: 'Jumpcloud', icon: JumpcloudIcon },
    'mobileiron-mac': { label: 'MobileIron', icon: MobileIronIcon },
    'mobileiron-windows': { label: 'MobileIron', icon: MobileIronIcon },
    'microsoft-group-policy-management': { label: 'Microsoft Group Policy Management', icon: MicrosoftIcon },
    'microsoft-sccm': { label: 'Microsoft SCCM', icon: MicrosoftSCCMIcon },
    'microsoft-intune-windows': { label: 'Microsoft Intune', icon: MicrosoftIntuneIcon },
    'microsoft-intune-mac': { label: 'Microsoft Intune', icon: MicrosoftIntuneIcon },
    custom: { label: 'Custom', icon: MicrosoftIcon },
};

const MAC_DEFAULT_VENDORS: MDMVendor[] = ['jamf', 'kandji', 'jumpcloud-mac', 'mobileiron-mac', 'microsoft-intune-mac'];
const WINDOWS_DEFAULT_VENDORS: MDMVendor[] = ['jumpcloud-windows', 'microsoft-group-policy-management', 'microsoft-sccm', 'microsoft-intune-windows', 'mobileiron-windows'];

const MDM_MAPPING: Record<Browser, Partial<Record<OperatingSystem, MDMVendor[]>>> = {
    chrome: {
        mac: MAC_DEFAULT_VENDORS,
        windows: WINDOWS_DEFAULT_VENDORS,
    },
    edge: {
        mac: MAC_DEFAULT_VENDORS,
        windows: WINDOWS_DEFAULT_VENDORS,
    },
    brave: {
        mac: MAC_DEFAULT_VENDORS,
        windows: WINDOWS_DEFAULT_VENDORS,
    },
    vivaldi: {
        mac: MAC_DEFAULT_VENDORS,
        windows: WINDOWS_DEFAULT_VENDORS,
    },
    arc: {
        mac: MAC_DEFAULT_VENDORS,
        windows: WINDOWS_DEFAULT_VENDORS,
    },
    firefox: {
        mac: MAC_DEFAULT_VENDORS,
        windows: WINDOWS_DEFAULT_VENDORS,
    },
    safari: {
        mac: ['custom'],
    },
    chromium: {
        mac: MAC_DEFAULT_VENDORS,
        windows: WINDOWS_DEFAULT_VENDORS,
    },
    whale: {
        mac: MAC_DEFAULT_VENDORS,
        windows: WINDOWS_DEFAULT_VENDORS,
    },
    talon: {
        enterprise: ['custom'],
    },
    island: {
        enterprise: ['custom'],
    },
};

const EmployeesMDM: React.FC = () => {

    const { connector } = useConnectorContext();
    const [selectedOS, setSelectedOS] = useState<OperatingSystem>('mac');
    const [selectedBrowser, setSelectedBrowser] = useState<Browser>('chrome');
    const [selectedMDMVendor, setSelectedMDMVendor] = useState<MDMVendor>('jumpcloud-mac');

    const [customHttpHeader, setCustomHttpHeader] = useState<boolean>(false);

    const availableBrowsers = useMemo(() =>
        Object.entries(BROWSERS).filter(([_, browser]) => browser.operatingSystems.includes(selectedOS)),
        [selectedOS]);

    const availableMDMVendors = useMemo(() => {
        const vendors = MDM_MAPPING[selectedBrowser][selectedOS];
        return vendors ? vendors.map(vendor => ({ value: vendor, ...MDM_VENDORS[vendor] })) : [];
    }, [selectedOS, selectedBrowser]);

    if (!connector) return null;

    const handleOSChange = (_: React.MouseEvent<HTMLElement>, newOS: OperatingSystem | null) => {
        if (newOS) {
            setSelectedOS(newOS);
            const newBrowser = Object.keys(BROWSERS).find(browser =>
                BROWSERS[browser as Browser].operatingSystems.includes(newOS)
            ) as Browser;
            setSelectedBrowser(newBrowser);
            const newVendors = MDM_MAPPING[newBrowser][newOS];
            setSelectedMDMVendor(newVendors ? newVendors[0] : 'custom');
        }
    };

    const handleBrowserChange = (_: React.MouseEvent<HTMLElement>, newBrowser: Browser | null) => {
        if (newBrowser) {
            setSelectedBrowser(newBrowser);
            const newVendors = MDM_MAPPING[newBrowser][selectedOS];
            setSelectedMDMVendor(newVendors ? newVendors[0] : 'custom');
        }
    };

    const handleMDMVendorChange = (_: React.MouseEvent<HTMLElement>, newVendor: MDMVendor | null) => {
        if (newVendor) setSelectedMDMVendor(newVendor);
    };

    const handleConfigDownload = () => {
        const configFile = generateConfig({
            operatingSystem: selectedOS,
            browser: selectedBrowser,
            domain: window.location.hostname,
            appId: connector.id,
            addCustomHttpHeader: customHttpHeader ? 'true' : 'false',
        });

        if (!configFile) {
            toast.error('Error generating configuration file');
            return;
        }

        const blob = new Blob([configFile.content], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = configFile.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        toast.success('Configuration file downloaded successfully');
    };
    return (
        <PSBox padding={30}>
            <Text variant='header2' css={EmployeesMDMStyle.title}>MDM</Text>

            <div css={EmployeesMDMStyle.sectionContainer}>
                <ToggleButtonGroup
                    value={selectedOS}
                    onChange={handleOSChange}
                    exclusive
                    css={EmployeesMDMStyle.sectionSelectContainer}
                >
                    {Object.entries(OPERATING_SYSTEMS).map(([value, os]) => (
                        <ToggleButton key={value} value={value} css={EmployeesMDMStyle.toggleButton}>
                            <ToggleButtonIcon Icon={os.icon} label={os.label} />
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </div>

            <Text css={EmployeesMDMStyle.sectionDescription}>Select a browser and download the following file</Text>
            <div css={EmployeesMDMStyle.sectionContainer}>
                <ToggleButtonGroup
                    value={selectedBrowser}
                    onChange={handleBrowserChange}
                    exclusive
                    css={EmployeesMDMStyle.sectionSelectContainer}
                >
                    {availableBrowsers.map(([value, browser]) => (
                        <ToggleButton key={value} value={value} css={EmployeesMDMStyle.toggleButton}>
                            <ToggleButtonIcon Icon={browser.icon} label={browser.label} />
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>

                <PSFormControlLabel
                    control={
                        <Checkbox checked={customHttpHeader} onChange={() => setCustomHttpHeader(!customHttpHeader)} />
                    }
                    label='Add custom HTTP header to all requests, to detect and block incognito usage'
                />

                <PSButton variant='filled' iconName='DownloadRounded' onClick={handleConfigDownload}>
                    Download {selectedOS === 'windows' ? 'Powershell File' : `${BROWSERS[selectedBrowser].label} Configuration Profile`}
                </PSButton>
            </div>

            {availableMDMVendors.length > 1 && (
                <>
                    <Text css={EmployeesMDMStyle.sectionDescription}>Select MDM Vendor for installation guide</Text>
                    <div css={EmployeesMDMStyle.sectionContainer}>
                        <ToggleButtonGroup
                            value={selectedMDMVendor}
                            onChange={handleMDMVendorChange}
                            exclusive
                            css={EmployeesMDMStyle.sectionSelectContainer}
                        >
                            {availableMDMVendors.map((vendor) => (
                                <ToggleButton key={vendor.value} value={vendor.value} css={[EmployeesMDMStyle.toggleButton]}>
                                    <ToggleButtonIcon Icon={vendor.icon} label={vendor.label} />
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </div>
                </>
            )}

            {selectedMDMVendor !== 'custom' && VendorToComponent[selectedMDMVendor]}
            {selectedBrowser === 'island' && <Text>Island Browser installation guide coming soon...</Text>}
            {selectedBrowser === 'talon' && <Talon />}
            {selectedBrowser === 'safari' && <Safari />}
        </PSBox>
    );
};

type ToggleButtonIconProps = {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    label: string;
}

export const ToggleButtonIcon: React.FC<ToggleButtonIconProps> = ({ Icon, label }) => (
    <div css={css`overflow: hidden; gap: 5px; display: flex; flex-direction: column; align-items: center;`}>
        <div css={EmployeesMDMStyle.iconContainer}>
            <Icon width={50} height={50} />
        </div>
        <Text variant='small'>{label}</Text>
    </div>
);

export default EmployeesMDM;