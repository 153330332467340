/** @jsxImportSource @emotion/react */
import { IconButton, TextField } from '@mui/material';
import React, { useState } from 'react';
import { ItemListInputStyle } from './ItemsListInput.css';
import Icon from '../../Icon/Icon';
import Text from '../../Text/Text';

type IProps = {
    value: string[];
    onChange: (value: string[]) => void;
    placeholder?: string;
    label?: string;
    validate?: (item: string) => boolean;
    invalidMessage?: string;
    uniqueOnly?: boolean;
    editable?: boolean;
    description?: string
};

const ItemListInput: React.FC<IProps> = (props) => {
    const { value = [], label, placeholder = 'Add new item', invalidMessage = 'Invalid', uniqueOnly = true, editable = true, description, onChange, validate } = props;

    const [newItem, setNewItem] = useState('');

    const isOptionAlreadyExist = (item: string, index?: number): boolean => {
        return value.some((value, i) => value === item && i !== index);
    };

    const isValidItem = (item: string, checkEmpty = false, index?: number): boolean => {
        if (checkEmpty && item.trim() === '') {
            return false;
        }

        if (uniqueOnly && isOptionAlreadyExist(item, index)) {
            return false;
        }

        if (validate && !validate(item)) {
            return false;
        }

        return true;
    };

    const helperText = (item: string, checkEmpty = false, index?: number): string => {
        if (checkEmpty && item.trim() === '') {
            return 'Cannot be empty';
        }
        if (uniqueOnly && isOptionAlreadyExist(item, index)) {
            return 'Item already exists';
        }
        if (validate && !validate(item)) {
            return invalidMessage;
        }
        return '';
    };

    const handleAddItem = () => {
        if (newItem.trim() === '') return;
        if (!isValidItem(newItem)) return;
        if (uniqueOnly && isOptionAlreadyExist(newItem)) return;
        const updatedItems = [...value, newItem];
        onChange(updatedItems);
        setNewItem('');
    };

    const handleEditItem = (index: number, itemValue: string) => {
        const updatedItems = [...value];
        updatedItems[index] = itemValue;
        onChange(updatedItems);
    };

    const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleAddItem();
        }
    }

    const handleRemoveItem = (index: number) => {
        const updatedItems = value.filter((_, i) => i !== index);
        onChange(updatedItems);
    };

    return (
        <div css={ItemListInputStyle.self}>
            <div css={ItemListInputStyle.addInputContainer}>
                <TextField
                    fullWidth
                    label={label}
                    variant="outlined"
                    value={newItem}
                    onChange={(e) => setNewItem(e.target.value)}
                    error={newItem.trim() !== '' && !isValidItem(newItem)}
                    onKeyPress={onKeyPress}
                    placeholder={placeholder}
                    size='small'
                    helperText={newItem.trim() !== '' && helperText(newItem)}
                />
                <IconButton
                    disabled={newItem.trim() === '' || !isValidItem(newItem)}
                    onClick={handleAddItem}
                >
                    <Icon iconName='AddRounded' />
                </IconButton>
            </div>

            {description && <Text variant='small'>{description}</Text>}

            {value.map((item, index) => (
                <div key={index} css={ItemListInputStyle.singleItem}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={item}
                        onChange={(e) => handleEditItem(index, e.target.value)}
                        error={!isValidItem(item, true, index)}
                        helperText={helperText(item, true, index)}
                        disabled={!editable}
                        size='small'
                        sx={{
                            '& .MuiInputBase-root': {
                                background: 'var(--color-black-5)'
                            },
                        }}
                    />
                    <IconButton onClick={() => handleRemoveItem(index)} aria-label="delete">
                        <Icon color='black-70' iconName='DeleteRounded' />
                    </IconButton>
                </div>
            ))}
        </div>
    );
}

export default ItemListInput;