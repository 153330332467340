/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import PromptLeakDetectorForm from './PromptLeakDetectorForm';

const PromptLeakDetectorProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode } = props;

    return (
        <ProtectionWrapper
            control={control}
            label="Prompt Leak Detector"
            getValues={getValues}
            description="Prevent the exposure of your genAI application’s system prompt"
            formName="Prompt Leak Detector"
            Component={isAdvancedMode ? PromptLeakDetectorForm : undefined}
            isAdvancedMode={isAdvancedMode}
            summary={`The Prompt Leak Detector is designed to prevent the unintentional exposure of your system prompt. If your system prompt is inadvertently leaked, it could expose your genAI application and make it more susceptible to various attacks by malicious threat actors, including prompt injection and jailbreaking.

It achieves this through a two-part detection mechanism:

1. Similarity Check: If you include your system prompt in the API call, the detector runs similarity models to compare the user prompt and your system prompt. If the similarity exceeds a certain threshold, the response is blocked to prevent potential leakage of your system prompt.
2. Canary Word Check: If you've enabled the 'canary word' feature in the prompt hardening protection, the detector will check if this canary word appears in the response. If it does, the response will be blocked to ensure the canary word is not leaked.`}
        />
    )
}

export default PromptLeakDetectorProtection;