import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from "@mui/material"

type PopupMessageActionVerificationProps = {
    open: boolean;
    title: string;
    text: string;
    handlePopupMessageClose: () => void;
    handlePopupMessageYes: () => void;
}

export const PopupMessageActionVerification = ({ open, title, text, handlePopupMessageClose, handlePopupMessageYes }: PopupMessageActionVerificationProps) => {
    return (
        <Dialog maxWidth="sm" fullWidth={true}
            open={open}
            onClose={handlePopupMessageClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handlePopupMessageClose}>No</Button>
                <Button onClick={handlePopupMessageYes}>Yes</Button>
            </DialogActions>
        </Dialog>
    )
}