/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const RegexFormStyle = {
    self: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
    `,
    redact: css`
        display: flex;
        align-items: center;
        gap: 5px;
    `
}