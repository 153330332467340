/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const ActivityMonitorDetailsStyle = {
  self: css``,
  keyValueRow: css`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
    border-bottom: 1px solid var(--color-black-50);

    &:last-of-type {
      border-bottom: none;
    }
  `,
  keyMinWidth: css`
    min-width: 150px;
  `,
  objectContainer: css`
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    text-wrap: wrap;
    padding: 10px;
    border: 1px solid var(--color-black-50);
    border-radius: 5px;
    margin-block: 10px;
  `,
  hiddenText: css`
    color: var(--color-white);
    background: var(--color-black);
    padding: 8px;
    transform: rotate(3deg);
  `,
};
