/** @jsxImportSource @emotion/react */
import React from 'react';
import CodeMirror, { EditorView } from '@uiw/react-codemirror';
import { Log } from '../../../gql/generated/graphql';
import { ActivityMonitorJSONViewerStyle } from './ActivityMonitorJSONViewer.css';
import { ContainerWithLogDetails, SystemPrompt } from './ActivityMonitorContent';
import { TTextDiffMode, Text } from '../../../ui-kit';
import { langs } from '@uiw/codemirror-extensions-langs';
import CodeMirrorMerge from 'react-codemirror-merge';

type IProps = {
    code: string;
    modifiedCode?: string;
    logData: Log;
};

const ActivityMonitorCodeViewer: React.FC<IProps> = (props) => {
    const { code, modifiedCode, logData } = props;
    const { systemPrompt } = logData;


    return (
        <div css={ActivityMonitorJSONViewerStyle.self}>
            <div>
                <Text textCss={ActivityMonitorJSONViewerStyle.jsonKey} variant='monoSmall'>Code:</Text>
                {modifiedCode && <CodeDiffViewer code={code} modifiedCode={modifiedCode} logData={logData} />}
                {!modifiedCode && <CodeViewer code={code} logData={logData} />}
            </div>
            {systemPrompt && <SystemPrompt>{systemPrompt}</SystemPrompt>}
        </div>
    )
}

export default ActivityMonitorCodeViewer;

type CodeViewerProps = {
    code: string;
    logData: Log;
};
export const CodeViewer: React.FC<CodeViewerProps> = (props) => {
    const { code, logData } = props;
    return (
        <ContainerWithLogDetails logData={logData}>
            <CodeMirror value={code} readOnly theme={'dark'} extensions={[langs.cpp(), langs.csharp(), langs.tsx(), langs.python(), EditorView.lineWrapping]} />
        </ContainerWithLogDetails>
    );
};

type CodeDiffViewerProps = {
    code: string;
    modifiedCode: string;
    logData: Log;
};
export const CodeDiffViewer: React.FC<CodeDiffViewerProps> = (props) => {
    const { code, modifiedCode, logData } = props;

    const [diffMode, setDiffMode] = React.useState<TTextDiffMode>('original');

    const Original = CodeMirrorMerge.Original;
    const Modified = CodeMirrorMerge.Modified;

    return (
        <ContainerWithLogDetails logData={logData} showTextDiff handleChangeDiff={setDiffMode}>
            <CodeMirrorMerge theme={'dark'} highlightChanges={false}
                css={ActivityMonitorJSONViewerStyle.codeViewerDiff(diffMode)}
            >
                <Original value={code} readOnly extensions={[langs.cpp(), langs.csharp(), langs.tsx(), langs.python(), EditorView.lineWrapping]} />
                <Modified value={modifiedCode} readOnly extensions={[langs.cpp(), langs.csharp(), langs.tsx(), langs.python(), EditorView.lineWrapping]} />
            </CodeMirrorMerge>
        </ContainerWithLogDetails>
    );
}