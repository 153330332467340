import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { FronteggProviderProps } from "@frontegg/react";

const fetchFronteggConfig = async (): Promise<TFronteggContextOptions> => {
    const response = await fetch('/api/frontegg/get-config', {
        method: 'GET',
        headers: { "Content-Type": "application/json" }
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export const useFronteggConfig = () => {
    const { data, isLoading } = useQuery<TFronteggContextOptions>({
        queryKey: ['fronteggConfig'],
        queryFn: fetchFronteggConfig,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false
    });

    const contextOptions = useMemo(() => fronteggContextOptions(data?.baseUrl, data?.clientId), [data]);

    return { contextOptions, isLoading };
};


export type TFronteggContextOptions = {
    baseUrl: string;
    clientId: string;
}

export const fronteggContextOptions = (baseUrl?: string, clientId?: string): FronteggProviderProps['contextOptions'] => {
    return {
        baseUrl: baseUrl || 'https://dev-auth.prompt.security',
        clientId: clientId || '76ac4b2c-4f70-4b49-b63e-5d160edb2f46',
        tenantResolver: () => {
            const queryParams = new URLSearchParams(window.location.search);
            return {
                tenant: queryParams.get('organization')
            }
        },
    }
};