/** @jsxImportSource @emotion/react */
import React from 'react';
import { Text } from '../../../../../ui-kit';
import { css } from '@emotion/react';

type IProps = {};

const MicrosoftIntuneMac: React.FC<IProps> = (props) => {
    const { } = props;

    return (
        <div css={css`display: flex; flex-direction: column; gap: 15px;`}>
            <Text>The goal is to run the above powershell script as admin on the endpoint machines of your employees</Text>
            <ol css={css`list-style: decimal; margin-left: 30px; display: flex; flex-direction: column; gap: 10px;`}>
                <li>
                    <Text><b>Sign in to Microsoft Intune Admin Center with your administrator credentials</b></Text>
                </li>
                <li>
                    <Text><b>Create a macOS Custom Configuration Profile</b></Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>Navigate to {`Devices > macOS > Configuration`}</Text></li>
                        <li><Text>Click on + Create.</Text></li>
                        <li><Text>Select New Policy</Text></li>
                        <li><Text>For the profile type, select Templates and then Custom.</Text></li>
                        <li><Text>Click Create.</Text></li>
                    </ul>
                </li>
                <li>
                    <Text><b>Configure the Custom Profile:</b></Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>Enter a Name for the profile (e.g., "Deploy Chrome Extension") and click Next.</Text></li>
                        <li><Text>In the Configuration settings section, enter a name for the custom configuration profile and click the folder icon.</Text></li>
                    </ul>
                </li>
                <li>
                    <Text><b>Upload the Configuration Profile File:</b></Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>Upload the .mobileconfig file taken from the Prompt Security admin portal.</Text></li>
                        <li><Text>Click Next to save the settings.</Text></li>
                    </ul>
                </li>
                <li>
                    <Text><b>Assign the Profile to Mac Devices:</b></Text>
                    <ul css={css`list-style: disc; margin-left: 30px;`}>
                        <li><Text>Under the Assignments tab, select the groups of macOS devices or users you want to target.</Text></li>
                        <li><Text>Click Next and then Create.</Text></li>
                    </ul>
                </li>
            </ol>
        </div>
    )
}

export default MicrosoftIntuneMac;