/** @jsxImportSource @emotion/react */
import React from 'react';
import { Text } from '../../../../../ui-kit';
import { css } from '@emotion/react';

type IProps = {};

const MobileIronWindows: React.FC<IProps> = (props) => {
    const { } = props;

    return (
        <div css={css`display: flex; flex-direction: column; gap: 15px;`}>
            <Text>The goal is to run the above powershell script as admin on the endpoint machines of your employees</Text>
            <ol css={css`list-style: number; margin-left: 30px; display: flex; flex-direction: column; gap: 10px;`}>
                <li><Text><b>Log in to Ivanti Endpoint Manager</b> (formerly MobileIron).</Text></li>
                <li><Text>Click "<b>Distribution</b>" in the sidebar.</Text></li>
                <li><Text>Click "<b>New</b>" and select "<b>Package</b>".</Text></li>
                <li><Text>Name the package and upload your <b>.ps1</b> script as the package file.</Text></li>
                <li><Text>Go to "<b>Scheduled Tasks</b>" and create a new task.</Text></li>
                <li><Text>Select "<b>Software Distribution</b>" and choose your uploaded PowerShell script package.</Text></li>
                <li><Text>Assign the task to target <b>devices/groups</b>.</Text></li>
                <li><Text>Click "<b>Save</b>" and "<b>Deploy</b>".</Text></li>
            </ol>
            <Text>Your script will now be deployed and executed on the assigned Windows devices.</Text>
        </div>
    )
}

export default MobileIronWindows;