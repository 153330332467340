import { setCookie, getCookie } from '../../utils/cookies';
import { User } from '@frontegg/redux-store/auth/interfaces';


export type TTenantDetails = {
    id: string;
    name: string;
}

export type TTenantsInfo = {
    tenant: TTenantDetails | null;
    shouldDisplayTenants: boolean;
}


export const setCurrentTenant = (newTenant: TTenantDetails): void => {
    setCookie('tenant_details', newTenant);
}

export const getCurrentTenant = (): TTenantDetails | null => {
    const tennat = getCookie('tenant_details');
    if (tennat) {
        try {
            return JSON.parse(tennat) as TTenantDetails;
        } catch (e) {
            return null;
        }
    }
    return null;
}

const getTenantsDetails = async (user: User, getSingleTenant: boolean = true): Promise<TTenantDetails[] | null> => {

    const activeTenantId = getCurrentTenant()?.id || user.tenantId; 
    const request = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            activeTenantId: activeTenantId,
            getSingleTenant: getSingleTenant
        })
    };
    const response = await fetch('/api/tenants/get-tenants', request);
    if (response.status === 401) {
        return [];
    }
    const responseJson = await response.json();
    return responseJson;
}

export const getTenant = async (user: User): Promise<TTenantDetails | null> => {
    const tenants = await getEnvTenants(user);
    const currentTenant = getCurrentTenant();
    for (const tenant of tenants) {
        if (currentTenant && tenant.id === currentTenant.id) {
            return tenant;
        }
    }
    return tenants?.[0];
}

export const getEnvTenants = async (user: User): Promise<TTenantDetails[]> => {
    const tenants = await getTenantsDetails(user, false);
    if (!tenants) {
        return [];
    }
    return tenants;
}

export const getTenantToDisplay = async (user: User): Promise<TTenantsInfo> => {
    let tenant: TTenantDetails | null = null;

    const tenants: TTenantDetails[] = await getEnvTenants(user);
    const shouldDisplayTenants: boolean = tenants.length > 1;

    if (tenants.length > 0) {
        tenant = tenants[0];
    }

    const cookieTenant = getCurrentTenant();
    if (cookieTenant && tenants.map(t => t.id).includes(cookieTenant.id)) {
        return { tenant: cookieTenant, shouldDisplayTenants };
    }
    return { tenant, shouldDisplayTenants };
}
