/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';

const SanitizerModelProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode } = props;

    if (!isAdvancedMode) return null;

    return (
        <ProtectionWrapper
            label="Sanitizer Model"
            description="Sanitizes the user's input."
            formName="Sanitizer Model"
            control={control}
            getValues={getValues}
        />
    )
}

export default SanitizerModelProtection;